"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _order = require("@/api/order");
var _construction = require("@/api/construction");
var _orderFormConfig = require("@/views/components/OrderForm/orderFormConfig");
var _PhotoAndVideoUpload = _interopRequireDefault(require("@/views/components/PhotoAndVideoUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SCMManagerOption",
  data: function data() {
    return {
      replyTypeList: [{
        label: "管理员处置",
        value: 7000
      }, {
        label: "延期",
        value: 0
      }, {
        label: "退单",
        value: 90000
      }],
      replyType: 7000,
      replyContent: "",
      returnCauseList: _orderFormConfig.BackAndRejectCause.CON_reject,
      returnCauseValue: "",
      replyCategroyDateTime: "",
      fileList: {
        Pics: [],
        Atts: []
      },
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now();
        }
      }
    };
  },
  components: {
    PhotoAndVideoUpload: _PhotoAndVideoUpload.default
  },
  inject: ["$openMainLoading", "$closeMainLoading", "updateDetailsPage", "orderBasicData"],
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      // if (this.replyType == 90000 && !this.returnCauseValue) {
      //   //退单
      //   this.$message.warning("请选择拒绝退单原因");
      //   return false;
      // }
      if (this.replyType == 0 && !this.replyCategroyDateTime) {
        this.$message.warning("请选择延期日期");
        return false;
      }
      if (!this.replyContent) {
        this.$message.warning("请输入描述信息");
        return false;
      }
      var postData = {
        NextOrderStatus: this.replyType,
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderCode,
          // ReplyReasonName: this.replyType == 90000 ? this.returnCauseValue : "",
          ReplyContent: this.replyContent,
          ReplyCategoryName: this.getReplyCategoryName(),
          ReplyCategroyDateTime: this.replyType == 0 ? this.replyCategroyDateTime : ""
        },
        attachmentList: [].concat((0, _toConsumableArray2.default)(this.fileList.Pics), (0, _toConsumableArray2.default)(this.fileList.Atts))
      };
      this.$openMainLoading();
      var request = this.replyType == 0 ? _construction.SubmitConRepairDelayDateTime : _order.SubmitOrderReplyOption;
      request(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: "提交成功",
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    },
    getReplyCategoryName: function getReplyCategoryName() {
      var _this2 = this;
      var label = "";
      this.replyTypeList.some(function (item) {
        if (item.value == _this2.replyType) {
          label = item.label;
          return true;
        }
      });
      return label;
    }
  }
};
exports.default = _default;