"use strict";

var _interopRequireWildcard = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var API = _interopRequireWildcard(require("@/api/message"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Message",
  data: function data() {
    return {
      StartAndEnd: null,
      searchForm: {
        Start: "",
        End: "",
        Status: null,
        PageIndex: 1,
        PageSize: 10,
        OrderBy: "",
        OrderType: "" //asc,desc
      },

      tableData: [],
      tableDataTotal: 0,
      multipleSelection: [],
      statusList: [{
        label: "全部",
        value: null
      }, {
        label: "未读",
        value: 0
      }, {
        label: "已读",
        value: 1
      }],
      departmentOrderRoutes: [{
        path: "/rx/order",
        roleCode: ["adidasrole001"],
        departmentCode: "adidasDP004"
      }, {
        path: "/ehs/order",
        roleCode: ["adidasrole011"],
        departmentCode: "adidasDP002"
      }, {
        path: "/rxsupply/order",
        roleCode: ["adidasrole003"],
        departmentCode: "adidasDP004"
      }, {
        path: "/pp/order/event",
        roleCode: ["adidasrole012", "adidasrole011"],
        departmentCode: "adidasDP003"
      }, {
        path: "/pp/order/event",
        roleCode: ["adidasrole012"],
        departmentCode: "adidasDP002"
      }, {
        path: "/pp/order/service",
        roleCode: ["adidasrole012", "adidasrole006"],
        departmentCode: "adidasDP003"
      }, {
        path: "/con/order",
        roleCode: ["adidasrole010"],
        departmentCode: "adidasDP001"
      }, {
        path: "/consupply/order",
        roleCode: ["adidasrole013"],
        departmentCode: "adidasDP001"
      }, {
        path: "/ppsupply/order",
        roleCode: ["adidasrole016"],
        departmentCode: "adidasDP003"
      }, {
        path: "/mkt/order",
        roleCode: ["adidasrole007", "adidasrole008", "adidasrole014", "adidasrole015", "adidasrole006"],
        departmentCode: "adidasDP005"
      }, {
        path: "/mktsupply/order",
        roleCode: ["adidasrole018"],
        departmentCode: "adidasDP005"
      }, {
        path: "/scm/order",
        roleCode: ["adidasrole019"],
        departmentCode: "adidasDP006"
      }

      // 1 adidasDP001 工程维修
      // 2 adidasDP002 健康安全
      // 3 adidasDP003 利润保护
      // 4 adidasDP004 零售卓越
      // 5 adidasDP005 零售市场
      // 6 adidasDP006 供应链
      // 7 adidasDP007 CRM

      // 1 Rx管理员 adidasrole001
      // 2 门店人员 adidasrole002
      // 3 RX供应商 adidasrole003
      // 4 DOM adidasrole004
      // 5 ROM adidasrole005
      // 6 协调员 adidasrole006
      // 7 RM-SP管理员 adidasrole007
      // 8 RM-ORI adidasrole008
      // 9 SCM管理员 adidasrole009 --废弃
      // 10 CON管理员 adidasrole010
      // 11 EHS管理员 adidasrole011
      // 12 PP管理员 adidasrole012
      // 13 维修供应商 adidasrole013
      // 14 RM-NEO管理员 adidasrole014
      // 15 RM-KIDS管理员 adidasrole015
      // 16 PP维修供应商 adidasrole016
      // 17 ISC管理员 adidasrole017
      // 18 ISC供应商 adidasrole018
      // 19 SCM管理员 adidasrole019
      ]
    };
  },

  inject: ["$openMainLoading", "$closeMainLoading", "$getUnreadMessageCount"],
  methods: {
    handleSearch: function handleSearch() {
      this.searchForm.PageIndex = 1;
      this.getDataList();
    },
    getDataList: function getDataList() {
      var _this = this;
      this.$openMainLoading();
      var postData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), {}, {
        Start: this.StartAndEnd ? this.StartAndEnd[0] : "",
        End: this.StartAndEnd ? this.StartAndEnd[1] : ""
      });
      API.GetMessageList(postData).then(function (res) {
        _this.$closeMainLoading();
        if (res.code == 0) {
          _this.tableData = res.dataList;
          _this.tableDataTotal = res.Total;
        }
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchForm.PageIndex = val;
      this.getDataList();
    },
    handleSortChange: function handleSortChange(data) {
      var column = data.column,
        prop = data.prop,
        order = data.order;
      !order && (prop = "");
      order = order == "ascending" ? "asc" : order == "descending" ? "desc" : "";
      this.searchForm.OrderBy = prop;
      this.searchForm.OrderType = order;
      this.handleSearch();
    },
    formatterDate: function formatterDate(row, column) {
      return row[column.property] ? (0, _moment.default)(row[column.property]).format("yyyy-MM-DD HH:mm:ss") : "";
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleRead: function handleRead() {
      var _this2 = this;
      if (this.multipleSelection && this.multipleSelection.length) {
        var MessageIDs = this.multipleSelection.map(function (item) {
          return item.MessageID;
        });
        this.$openMainLoading();
        this.MarkUnreadMessage(MessageIDs).then(function (res) {
          _this2.$closeMainLoading();
          _this2.$message.success("标记为已读成功");
          _this2.handleSearch();
          _this2.$getUnreadMessageCount();
        }).catch(function (err) {
          _this2.$closeMainLoading();
        });
      }
    },
    MarkUnreadMessage: function MarkUnreadMessage(MessageIDs) {
      return API.MarkUnreadMessage({
        MessageIDs: MessageIDs
      });
    },
    handleDelete: function handleDelete() {
      var _this3 = this;
      if (this.multipleSelection && this.multipleSelection.length) {
        var MessageIDs = this.multipleSelection.map(function (item) {
          return item.MessageID;
        });
        this.$openMainLoading();
        API.DeleteMessage({
          MessageIDs: MessageIDs
        }).then(function (res) {
          _this3.$closeMainLoading();
          _this3.$message.success("删除成功");
          _this3.handleSearch();
          _this3.$getUnreadMessageCount();
        }).catch(function (err) {
          _this3.$closeMainLoading();
        });
      }
    },
    handleChangeDate: function handleChangeDate() {
      console.log(this.StartAndEnd);
      if (this.StartAndEnd) {
        this.searchForm.Start = "";
        this.searchForm.End = "";
      } else if (this.StartAndEnd && this.StartAndEnd.length) {
        this.searchForm.Start = this.StartAndEnd[0];
        this.searchForm.End = this.StartAndEnd[1];
      }
    },
    handleGoToOrderDetail: function handleGoToOrderDetail(_ref) {
      var _this4 = this;
      var DataKey = _ref.DataKey,
        MessageID = _ref.MessageID,
        Status = _ref.Status,
        DepartmentCode = _ref.DepartmentCode;
      if (MessageID && Status == 0) {
        this.MarkUnreadMessage([MessageID]).then(function (res) {
          _this4.$getUnreadMessageCount();
        });
      }
      if (DataKey) {
        var path = "";
        var roleCode = this.$store.getters.roles[0];
        this.departmentOrderRoutes.some(function (item) {
          if (item.departmentCode == DepartmentCode && item.roleCode.includes(roleCode)) {
            path = item.path;
            return true;
          }
        });
        path && this.$router.push({
          path: "".concat(path, "/").concat(DataKey)
        });
      }
    }
  },
  created: function created() {
    this.getDataList();
  }
};
exports.default = _default;