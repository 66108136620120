"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mockjs = _interopRequireDefault(require("mockjs"));
var data = _mockjs.default.mock({
  'items|30': [{
    id: '@id',
    title: '@sentence(10, 20)',
    'status|1': ['published', 'draft', 'deleted'],
    author: 'name',
    display_time: '@datetime',
    pageviews: '@integer(300, 5000)'
  }]
});
var roleData = _mockjs.default.mock({
  'items|4': [{
    'value|+1': ["super admin", "Internal user admin", "External user admin", "Report admin"],
    'label|+1': ["super admin", "Internal user admin", "External user admin", "Report admin"]
  }]
});
var countryData = _mockjs.default.mock({
  'items|4': [{
    'value|+1': ["China", "Japan", "America", "Australia"],
    'label|+1': ["China", "Japan", "America", "Australia"]
  }]
});
var mangerData = _mockjs.default.mock({
  'items|10': [{
    'user|+1': 1,
    name: '@name',
    'country|1': ["China", "Japan", "America", "Australia"],
    'role|+1': ["super admin", "Internal user admin", "External user admin", "Report admin"]
  }]
});
var _default = [{
  url: '/table/list',
  type: 'get',
  response: function response(config) {
    var items = data.items;
    return {
      Status: 0,
      data: {
        total: items.length,
        items: items
      }
    };
  }
}, {
  url: '/table/roleList',
  type: 'get',
  response: function response(config) {
    var items = roleData.items;
    return {
      Status: 0,
      data: {
        total: items.length,
        items: items
      }
    };
  }
}, {
  url: '/table/countryList',
  type: 'get',
  response: function response(config) {
    var items = countryData.items;
    return {
      Status: 0,
      data: {
        total: items.length,
        items: items
      }
    };
  }
}, {
  url: '/table/mangerList',
  type: 'get',
  response: function response(config) {
    var items = mangerData.items;
    return {
      Status: 0,
      data: {
        total: 20,
        items: items
      }
    };
  }
}];
exports.default = _default;