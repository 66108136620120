var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.formComponentName
    ? _c(
        "el-card",
        { staticStyle: { padding: "20px" } },
        [
          _c(_vm.formComponentName, {
            key: _vm.formComponentName,
            tag: "component",
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }