"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _order = require("@/api/order");
var _order2 = require("@/api/order2");
var _Material = require("@/api/Material");
var _Product = require("@/api/Product");
var _OrderMenu = _interopRequireDefault(require("@/views/components/OrderMenu"));
var _TimeState = _interopRequireDefault(require("@/views/components/TimeState"));
var _dayjs = _interopRequireDefault(require("dayjs"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ExportList,
var _default = {
  data: function data() {
    var _this = this;
    return {
      dayjs: _dayjs.default,
      loading: false,
      activeName: "1",
      materialTypes: [],
      tableData: [],
      // 工单状态
      ticketStatuss: [],
      workOrderInformation: {
        OrderNo: "",
        Status: null,
        OrderStatusGroup: 1,
        DepartmentID: "",
        shopName: "",
        ShopCode: "",
        startTime: [],
        processTimeStart: "",
        processTimeEnd: "",
        ProcessDateTimeStart: "",
        ProcessDateTimeEnd: "",
        OrderYearAndMonth: "",
        ProductTypeID: null,
        PageIndex: 1,
        PageSize: 10,
        total: 0,
        OrderBy: "",
        OrderType: "" //asc
      },

      processedDateSection: [],
      pickerOptionsStart: {
        disabledDate: function disabledDate(time) {
          if (_this.workOrderInformation.processTimeEnd) {
            return time.getTime() > new Date(_this.workOrderInformation.processTimeEnd).getTime();
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: function disabledDate(time) {
          if (_this.workOrderInformation.processTimeStart) {
            return time.getTime() < new Date(_this.workOrderInformation.processTimeStart).getTime() - 86400000;
          }
        }
      }
    };
  },
  inject: ['$openMainLoading', '$closeMainLoading'],
  components: {
    OrderMenu: _OrderMenu.default,
    TimeState: _TimeState.default
  },
  methods: {
    //  表格取数据
    purchaseData: function purchaseData() {
      var _this2 = this;
      var proms = {
        DepartmentCode: "adidasDP004",
        OrderNo: this.workOrderInformation.OrderNo,
        Status: this.workOrderInformation.Status - 0 || -1,
        OrderStatusGroup: this.workOrderInformation.OrderStatusGroup,
        ShopCode: this.workOrderInformation.ShopCode,
        ShopName: this.workOrderInformation.shopName,
        ProductTypeID: this.workOrderInformation.ProductTypeID,
        ProductTypeIDsString: null,
        ProcessStartTime: this.workOrderInformation.startTime ? this.workOrderInformation.startTime[0] : '',
        ProcessEndTime: this.workOrderInformation.startTime ? this.workOrderInformation.startTime[1] : '',
        OrderProcessStartTime: this.workOrderInformation.processTimeStart,
        OrderProcessEndTime: this.workOrderInformation.processTimeEnd,
        OrderYearAndMonth: this.workOrderInformation.OrderYearAndMonth,
        ProcessDateTimeStart: this.workOrderInformation.ProcessDateTimeStart,
        ProcessDateTimeEnd: this.workOrderInformation.ProcessDateTimeEnd,
        // 订单类别id
        OrderCategoryID: 0,
        // 选项类别ID
        OptionCategoryID: 0,
        // 选项对象id
        OptionObjectID: 0,
        PageIndex: this.workOrderInformation.PageIndex,
        PageSize: this.workOrderInformation.PageSize,
        OrderBy: this.workOrderInformation.OrderBy,
        OrderType: this.workOrderInformation.OrderType
      };
      var invokeMethod = this.workOrderInformation.OrderStatusGroup == 0 ? _order.GetAllGroupOrderListByStatusGroup : _order.GetOrderListByStatusGroup;
      this.$openMainLoading();
      invokeMethod(proms).then(function (res) {
        _this2.$closeMainLoading();
        // if (res.data.code == 0) {
        //   let data = res.data.dataList || [];
        //   this.tableData = data;
        //   this.workOrderInformation.total = res.data.Total;
        // } else {
        //   this.$message.error(this.res.msg);
        // }
        if (res.code == 0) {
          var data = res.dataList || [];
          _this2.tableData = data;
          _this2.workOrderInformation.total = res.Total;
        } else {
          _this2.$message.error(res.msg);
        }
      }).catch(function (err) {
        _this2.$closeMainLoading();
        _this2.tableData = [];
        _this2.workOrderInformation.total = 0;
      });
    },
    // 物料类型
    materialType: function materialType() {
      var _this3 = this;
      // 选定 rx 店铺
      this.loading = true;
      // 传死
      var postdata = {
        category: "PurchaseProduct"
      };
      (0, _Product.GetTreeList)(postdata).then(function (res) {
        _this3.loading = false;
        var data = res.data;
        if (data.code !== 0) {
          return _this3.$message.error("网络异常");
        }
        console.log("物料类型获取", res);
        _this3.materialTypes = res.data.dataList;
      }).catch(function (err) {
        _this3.loading = false;
        console.log("接口问题", err);
      });
    },
    // 工单状态
    ticketStatus: function ticketStatus() {
      var _this4 = this;
      var params = {
        // 需要与后端约定 orderStatusGroup 默认为0 是显示全部工单状态
        OrderStatusGroup: this.workOrderInformation.OrderStatusGroup,
        // orderStatusGroup: -1,
        // 写死 部门
        departmentCode: "adidasDP004"
      };
      console.log("工单状态传参", params);
      (0, _order.GetOrderStatusByStatusGroup)(params).then(function (res) {
        var data = res.data;
        if (data.code !== 0) return _this4.$message.error("网络异常");
        console.log("工单状态获取成功", res);
        _this4.ticketStatuss = data.data;
      }).catch(function (err) {
        _this4.loading = false;
        console.log("接口问题", err);
      });
    },
    // 进入创建工单页面
    createWorkOrder: function createWorkOrder() {
      this.$router.push("/rx/order/add");
    },
    handleClick: function handleClick(e) {
      // this.workOrderInformation.OrderStatusGroup = e.name - 0;
      //this.workOrderInformation.OrderStatusGroup = e;
      this.clearData();
      // 工单状态
      this.ticketStatus();
      this.purchaseData();
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
      this.workOrderInformation.PageSize = val;
      this.purchaseData();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.workOrderInformation.PageIndex = val;
      this.purchaseData();
    },
    // 检索
    search: function search() {
      this.workOrderInformation.PageIndex = 1;
      this.workOrderInformation.total = 0;
      this.tableData = [];
      this.purchaseData();
    },
    // 导出
    batchExport: function batchExport() {
      var _this5 = this;
      var queryEntity = {
        DepartmentCode: "adidasDP004",
        // 订单id 
        OrderNo: this.workOrderInformation.OrderNo,
        Status: this.workOrderInformation.Status || -1,
        OrderStatusGroup: this.workOrderInformation.OrderStatusGroup || -1,
        ShopCode: this.workOrderInformation.ShopCode,
        ShopName: this.workOrderInformation.shopName,
        ProductTypeID: this.workOrderInformation.ProductTypeID,
        ProcessStartTime: this.workOrderInformation.startTime ? this.workOrderInformation.startTime[0] : '',
        ProcessEndTime: this.workOrderInformation.startTime ? this.workOrderInformation.startTime[1] : '',
        OrderProcessStartTime: this.workOrderInformation.processTimeStart,
        OrderProcessEndTime: this.workOrderInformation.processTimeEnd,
        OrderYearAndMonth: this.workOrderInformation.OrderYearAndMonth,
        ProcessDateTimeStart: this.processedDateSection[0],
        ProcessDateTimeEnd: this.processedDateSection[1],
        PageIndex: this.workOrderInformation.PageIndex,
        PageSize: this.workOrderInformation.PageSize,
        OrderBy: this.workOrderInformation.OrderBy,
        OrderType: this.workOrderInformation.OrderType

        // OrderBy: null,
        // OrderType: null,
      };

      // debugger
      var invokeMethod = this.workOrderInformation.OrderStatusGroup == 0 ? _order.ExportAllGroupList : _order2.ExportOrder;
      invokeMethod(queryEntity).then(function (res) {
        console.log(res);
        if (res.status == 200) {
          var blob = new Blob([res.data], {
            type: res.headers["content-type"]
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = res.headers.filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        } else if (res.status == 204) {
          _this5.$message.warning("当前状态下没有数据");
        } else {
          _this5.$message.warning("导出失败");
        }
      });
    },
    //  进入查看详情
    viewDetails: function viewDetails(orderId) {
      console.log(orderId);
      this.$router.push({
        path: "/rx/order/" + orderId
      });
    },
    // 清除数据
    clearData: function clearData() {
      this.workOrderInformation.OrderNo = "";
      this.workOrderInformation.Status = "";
      this.workOrderInformation.DepartmentID = "";
      this.workOrderInformation.ShopCode = '';
      this.workOrderInformation.shopName = "";
      this.workOrderInformation.startTime = [];
      // this.workOrderInformation.processTimeStart = "";
      // this.workOrderInformation.processTimeEnd = "";
      this.workOrderInformation.ProductTypeID = "";
      this.workOrderInformation.PageIndex = 1;
      this.workOrderInformation.total = 0;
      this.tableData = [];
      this.processedDateSection = [];
      this.ticketStatuss = [];
      this.initProcessTime();
      this.workOrderInformation.ProcessDateTimeStart = "";
      this.workOrderInformation.ProcessDateTimeEnd = "";
    },
    // // 订单状态展现列表数据
    // statusTableData() {
    //   let proms = {
    //     // 工单状态分类 待处理 进行中
    //     OrderStatusGroup: this.workOrderInformation.OrderStatusGroup,
    //     PageIndex: this.workOrderInformation.PageIndex,
    //     PageSize: this.workOrderInformation.PageSize,
    //   };
    //   this.loading = true;
    //   GetOrderListByStatusGroup(proms)
    //     .then((res) => {
    //       this.loading = false;
    //       if (res.data.code == 0) {
    //         console.log("成功", res);
    //         let data = res.data.data;
    //         this.tableData = data;
    //         this.workOrderInformation.total = data.length;
    //       } else {
    //         console.log("接口问题");
    //         this.$message.error(this.res.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       this.loading = false;
    //       console.log("失败", err);
    //     });
    // },
    //排序
    handleSortchange: function handleSortchange(_ref) {
      var column = _ref.column,
        prop = _ref.prop,
        order = _ref.order;
      if (prop == "OrderProcessStartTime") {
        prop = "ProcessStartTime";
      }
      if (prop == "OrderProcessEndTime") {
        prop = "ProcessEndTime";
      }
      this.workOrderInformation.OrderBy = prop;
      this.workOrderInformation.OrderType = order == "descending" ? "desc" : "asc";
      this.search();
    },
    handleChangeProcessedDateSection: function handleChangeProcessedDateSection() {
      if (this.processedDateSection && this.processedDateSection.length) {
        this.workOrderInformation.ProcessDateTimeStart = this.processedDateSection[0];
        this.workOrderInformation.ProcessDateTimeEnd = this.processedDateSection[1];
      } else {
        this.workOrderInformation.ProcessDateTimeStart = "";
        this.workOrderInformation.ProcessDateTimeEnd = "";
      }
    },
    initProcessTime: function initProcessTime() {
      if (this.workOrderInformation.OrderStatusGroup == 0) {
        var range = [(0, _dayjs.default)().startOf('month').format("YYYY-MM-DD"), (0, _dayjs.default)().format("YYYY-MM-DD")];
        this.workOrderInformation.startTime = range;
      } else {
        this.workOrderInformation.startTime = [];
      }
    }
  },
  created: function created() {
    if (this.$route.query.group) {
      this.workOrderInformation.OrderStatusGroup = parseInt(this.$route.query.group);
      this.initProcessTime();
    }
    // this.workOrderInformation.processTimeStart = dayjs().startOf('month').format("YYYY-MM-DD");
    // this.workOrderInformation.processTimeEnd = dayjs().format("YYYY-MM-DD");
    this.materialType();
    this.purchaseData();
    this.ticketStatus();
  }
};
exports.default = _default;