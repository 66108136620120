var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _c(
        "div",
        {
          staticClass: "main-title",
          staticStyle: { "justify-content": "space-between" },
        },
        [_c("div", { staticClass: "title-name" }, [_vm._v(_vm._s(_vm.title))])]
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: true, model: _vm.condition } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "类型" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.dateChange },
                  model: {
                    value: _vm.currentTime,
                    callback: function ($$v) {
                      _vm.currentTime = $$v
                    },
                    expression: "currentTime",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "本月" } }, [
                    _vm._v("本月"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "本季" } }, [
                    _vm._v("本季"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "本年" } }, [
                    _vm._v("本年"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "日期" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "220px" },
                attrs: {
                  type: "monthrange",
                  "start-placeholder": "开始月份",
                  "end-placeholder": "结束月份",
                  "value-format": "yyyy-MM",
                  clearable: false,
                },
                on: { change: _vm.monthChange },
                model: {
                  value: _vm.monthRange,
                  callback: function ($$v) {
                    _vm.monthRange = $$v
                  },
                  expression: "monthRange",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "店铺区域" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择店铺区域",
                    multiple: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.condition.ShopArea,
                    callback: function ($$v) {
                      _vm.$set(_vm.condition, "ShopArea", $$v)
                    },
                    expression: "condition.ShopArea",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: "" } }),
                  _vm._v(" "),
                  _vm._l(_vm.storeAreaList, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "店铺类型" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择店铺类型",
                    multiple: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.condition.ShopType,
                    callback: function ($$v) {
                      _vm.$set(_vm.condition, "ShopType", $$v)
                    },
                    expression: "condition.ShopType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: "" } }),
                  _vm._v(" "),
                  _vm._l(_vm.storeList, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "维修项目" } },
            [
              _c("el-cascader", {
                staticStyle: { "min-width": "300px" },
                attrs: {
                  filterable: "",
                  placeholder: "类型",
                  options: _vm.orderTypeList,
                  props: {
                    multiple: true,
                    value: "ID",
                    label: "OrderTypeName",
                    children: "SonOrderTypes",
                    expandTrigger: "hover",
                  },
                  "collapse-tags": "",
                  clearable: "",
                },
                model: {
                  value: _vm.optionObjectIDList,
                  callback: function ($$v) {
                    _vm.optionObjectIDList = $$v
                  },
                  expression: "optionObjectIDList",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "main-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.search },
                },
                [_vm._v("检索")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          完成工单:"),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.summaryData.CompleteNum)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 12, md: 24, lg: 12, xl: 12 } },
                        [
                          _c("div", { staticClass: "desc" }, [
                            _vm._v("\n              总工单数:"),
                            _c("span", [
                              _vm._v(_vm._s(_vm.summaryData.TotalNum)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "desc" }, [
                            _vm._v(
                              "\n              完成率:\n              " +
                                _vm._s(
                                  _vm.getPercent(
                                    _vm.summaryData.CompleteNum,
                                    _vm.summaryData.TotalNum
                                  ) + " %"
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { xs: 12, sm: 12, md: 24, lg: 12, xl: 12 },
                        },
                        [
                          _c("circle-progress", {
                            attrs: {
                              percentage: _vm.getPercent(
                                _vm.summaryData.CompleteNum,
                                _vm.summaryData.TotalNum
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          超时工单:"),
                    _c("span", [_vm._v(_vm._s(_vm.summaryData.TimeOutNum))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 12, md: 24, lg: 12, xl: 12 } },
                        [
                          _c("div", { staticClass: "desc" }, [
                            _vm._v("\n              总工单数:"),
                            _c("span", [
                              _vm._v(_vm._s(_vm.summaryData.TotalNum)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "desc" }, [
                            _vm._v("\n              超时率:"),
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.getPercent(
                                      _vm.summaryData.TimeOutNum,
                                      _vm.summaryData.TotalNum
                                    ) + " %"
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { xs: 12, sm: 12, md: 24, lg: 12, xl: 12 },
                        },
                        [
                          _c("circle-progress", {
                            attrs: {
                              percentage: _vm.getPercent(
                                _vm.summaryData.TimeOutNum,
                                _vm.summaryData.TotalNum
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("供应商TOP5")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                        [
                          _c("v-chart", {
                            staticStyle: { width: "100%", height: "100px" },
                            attrs: { options: _vm.supplierChartObj },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("返修率TOP5")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                        [
                          _c("v-chart", {
                            staticStyle: { width: "100%", height: "100px" },
                            attrs: { options: _vm.repairItemChartObj },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c("v-chart", {
                    staticStyle: { height: "540px" },
                    attrs: { options: _vm.storeOrderCategoryChartObj },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "切换项目" },
                      on: { change: _vm.positionChane },
                      model: {
                        value: _vm.position,
                        callback: function ($$v) {
                          _vm.position = $$v
                        },
                        expression: "position",
                      },
                    },
                    _vm._l(_vm.positionList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("v-chart", {
                    staticStyle: { height: "500px" },
                    attrs: { options: _vm.repairCategoryChartObj },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }