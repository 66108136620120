"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EstimatedValueImport = exports.EstimatedValueDownload = exports.DownloadShopTemplate = exports.DownloadLogisticsTemplate = void 0;
exports.ExportOrder = ExportOrder;
exports.ExportOrderConstruction = ExportOrderConstruction;
exports.ExportOrderEHSAndPP = ExportOrderEHSAndPP;
exports.ExportOrderISC = ExportOrderISC;
exports.ExportOrderSCM = ExportOrderSCM;
exports.GetProccess = GetProccess;
exports.GetProductList = GetProductList;
exports.GetRxOrderDetail = GetRxOrderDetail;
exports.GetStoreListGroup = GetStoreListGroup;
exports.MoreMonthPurchaseSubmit = MoreMonthPurchaseSubmit;
exports.PurchaseSelect = PurchaseSelect;
exports.PurchaseSubmit = PurchaseSubmit;
exports.SCMReceiveProductSubmit = SCMReceiveProductSubmit;
exports.SubmitRemark = SubmitRemark;
exports.UpLoadStoreFiles = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request2"));
var _auth = require("@/utils/auth");
// POST /api/order2/GetStoreListGroup
// 获取商店分组信息
function GetStoreListGroup(data) {
  return (0, _request.default)({
    url: '/api/order2/GetStoreListGroup',
    method: 'post',
    data: data
  });
}

// POST /api/order2/UpLoadStoreFiles
// 上传店铺数据文件
var UpLoadStoreFiles = process.env.VUE_APP_BASE_API + '/api/order2/UpLoadStoreFiles?UserToken=' + (0, _auth.getToken)();
exports.UpLoadStoreFiles = UpLoadStoreFiles;
var DownloadShopTemplate = process.env.VUE_APP_BASE_API + '/Template/商店模板.xlsx';

// GET /api/order2/GetProductList
// 获取物料类型
exports.DownloadShopTemplate = DownloadShopTemplate;
function GetProductList(params) {
  return (0, _request.default)({
    url: '/api/order2/GetProductList',
    method: 'get',
    params: params
  });
}

// 模板下载-购物袋预估值计算
var EstimatedValueDownload = process.env.VUE_APP_BASE_API + '/Template/购物袋预估值计算模板.xlsx';
exports.EstimatedValueDownload = EstimatedValueDownload;
var DownloadLogisticsTemplate = process.env.VUE_APP_BASE_API + '/Template/物流单模板.xlsx';

// POST /api/order2/EstimatedValueImport
// 模版上传-购物袋预估值计算
exports.DownloadLogisticsTemplate = DownloadLogisticsTemplate;
var EstimatedValueImport = process.env.VUE_APP_BASE_API + '/api/order2/EstimatedValueImport?UserToken=' + (0, _auth.getToken)();

// POST /api/order2/PurchaseSelect
// 批量采购选择
exports.EstimatedValueImport = EstimatedValueImport;
function PurchaseSelect(data) {
  return (0, _request.default)({
    url: '/api/order2/PurchaseSelect',
    method: 'post',
    data: data
  });
}

// GET /api/order2/GetRxOrderDetail
// 获取工单详情
function GetRxOrderDetail(params) {
  return (0, _request.default)({
    url: '/api/order2/GetRxOrderDetail',
    method: 'get',
    params: params
  });
}

// GET /api/order2/GetProccess
// 获取工单流程
function GetProccess(params) {
  return (0, _request.default)({
    url: '/api/order2/GetProccess',
    method: 'get',
    params: params
  });
}

// POST /api/order2/ExportOrder
// 导出订单
function ExportOrder(data) {
  return (0, _request2.default)({
    url: '/api/order2/ExportOrder',
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  });
}

// POST /api/order2/ExportOrderEHSAndPP
//导出EHSAndPP订单
function ExportOrderEHSAndPP(data) {
  return (0, _request2.default)({
    url: '/api/order2/ExportOrderEHSAndPP',
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  });
}

// POST /api/order2/PurchaseSubmit
// 批量采购提交
function PurchaseSubmit(data) {
  return (0, _request.default)({
    url: '/api/order2/PurchaseSubmit',
    method: 'post',
    data: data
  });
}

/**
 * @description: 批量采购（多月份）
 * @param {*} data
 * @return {*}
 */
function MoreMonthPurchaseSubmit(data) {
  return (0, _request.default)({
    url: '/api/order2/MoreMonthPurchaseSubmit',
    method: 'post',
    data: data
  });
}

// POST /api/order2/SCMReceiveProductSubmit
// scm工单提交
function SCMReceiveProductSubmit(data) {
  return (0, _request.default)({
    url: '/api/order/SCMReceiveProductSubmit',
    method: 'post',
    data: data
  });
}

// GET /api/order2/SubmitRemark
// 
function SubmitRemark(params) {
  return (0, _request.default)({
    url: '/api/order2/SubmitRemark',
    method: 'get',
    params: params
  });
}

//POST /api/order2/ExportOrder

// export function ExportOrder(data) {
//     return request({
//         url: '/api/order2/ExportOrder',
//         method: 'post',
//         data
//     })
// }//

function ExportOrderConstruction(data) {
  return (0, _request2.default)({
    url: 'api/order2/ExportOrderConstruction',
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  });
}

// POST api/order2/ExportOrderISC
function ExportOrderISC(data) {
  return (0, _request2.default)({
    url: 'api/order2/ExportOrderISC',
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  });
}
function ExportOrderSCM(data) {
  return (0, _request2.default)({
    url: 'api/order2/ExportOrderSCM',
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  });
}