"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "RXFormItem",
  data: function data() {
    // let checkShipmentNumber = function (rule, value, callback) {
    //   if (!/^[A-Za-z0-9]+$/g.test(value)) {
    //     callback(new Error(rule.message));
    //   } else {
    //     if (this.orderForm.checkPass !== "") {
    //       this.$refs.orderForm.validateField("checkPass");
    //     }
    //     callback();
    //   }
    // };
    return {
      orderForm: {
        LogisticsOrderCode: "",
        ComCode: "",
        OrderID: "",
        OrderNo: ""
      },
      orderFormRules: {
        LogisticsOrderCode: [{
          required: true,
          message: "请填写物流单号",
          trigger: ["blur"]
        }, {
          message: "请填写英文数字组合",
          validator: this.checkShipmentNumber,
          trigger: "blur"
        }],
        ComCode: [{
          required: true,
          message: "请填写物流公司",
          trigger: ["blur"]
        }]
      }
    };
  },
  inject: ["$openMainLoading", "$closeMainLoading", "updateDetailsPage", "orderBasicData"],
  methods: {
    checkShipmentNumber: function checkShipmentNumber(rule, value, callback) {
      if (!/^[A-Za-z0-9]+$/g.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    },
    handleSubmit: function handleSubmit(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (!valid) return false;
        _this.$openMainLoading();
        (0, _order.SupplierSummit)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this.orderForm), {}, {
          OrderID: _this.orderBasicData.OrderID,
          OrderNo: _this.orderBasicData.OrderNo
        })).then(function (res) {
          _this.$closeMainLoading();
          _this.$message({
            message: "提交成功",
            type: "success"
          });
          _this.orderForm.LogisticsOrderCode = "";
          _this.orderForm.ComCode = "";
          _this.updateDetailsPage();
        }).catch(function (err) {
          _this.$closeMainLoading();
        });
      });
    }
  }
};
exports.default = _default;