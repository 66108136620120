"use strict";

var _interopRequireWildcard = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var API = _interopRequireWildcard(require("@/api/notice"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      id: null,
      loading: false,
      informData: {}
    };
  },
  methods: {
    getNoticeWithStoreStatus: function getNoticeWithStoreStatus() {
      var _this = this;
      this.loading = true;
      API.GetNoticeWithStoreStatus({
        NoticeID: this.id
      }).then(function (res) {
        _this.loading = false;
        var Stores = {};
        if (res.data.Stores) {
          res.data.Stores.map(function (item) {
            if (!Stores[item.Region]) {
              Stores[item.Region] = [item];
            } else {
              Stores[item.Region].push(item);
            }
          });
        }
        _this.informData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.data), {}, {
          Stores: Stores
        });
      }).catch(function (err) {
        console.log(err);
        _this.loading = false;
      });
    },
    formatterDate: function formatterDate(data) {
      return data ? (0, _moment.default)(data).format("yyyy-MM-DD") : "";
    }
  },
  created: function created() {
    this.id = this.$route.params.id;
    this.getNoticeWithStoreStatus();
  },
  computed: {
    departmentID: function departmentID() {
      return this.$route.meta.departmentID;
    }
  }
};
exports.default = _default;