"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/Users/zy/Desktop/topwin/apsadmin/node_modules/core-js/modules/es6.array.iterator.js");
require("/Users/zy/Desktop/topwin/apsadmin/node_modules/core-js/modules/es6.promise.js");
require("/Users/zy/Desktop/topwin/apsadmin/node_modules/core-js/modules/es6.object.assign.js");
require("/Users/zy/Desktop/topwin/apsadmin/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("babel-polyfill");
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/icons");
require("@/permission");
var _vueEcharts = _interopRequireDefault(require("vue-echarts"));
require("echarts/lib/chart/pie");
require("echarts/lib/component/legend");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legendScroll");
var _vueMiniPlayer = _interopRequireDefault(require("vue-mini-player"));
require("vue-mini-player/lib/vue-mini-player.css");
var _mock = require("../mock");
// A modern alternative to CSS resets

// lang i18n

// global css

// icon
// permission control

_vue.default.use(_vueMiniPlayer.default);

// import 'jquery'

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
if (process.env.NODE_ENV === 'production') {
  (0, _mock.mockXHR)();
}
// set ElementUI lang to EN
//Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
_vue.default.use(_elementUi.default);
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});