"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _report = require("@/api/report");
var _Product = require("@/api/Product");
var _vueEcharts = _interopRequireDefault(require("vue-echarts"));
require("echarts/lib/chart/line");
require("echarts/lib/component/title");
require("echarts/lib/chart/bar");
var _vuex = require("vuex");
var _date = require("@/utils/date");
var _CircleProgress = _interopRequireDefault(require("@/components/CircleProgress"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      title: "RX Dashboard",
      loading: false,
      currentTime: "本月",
      monthRange: [],
      summaryData: {},
      summaryList: [],
      materialTypes: [{
        text: "全部",
        id: "0"
      }],
      materialType: "",
      condition: {
        DepartmentID: 4,
        OptionObjectID: 0,
        StartDateTime: "",
        EndDateTime: ""
      },
      bar: {},
      line: {},
      getPercent: _utils.getPercent
    };
  },
  components: {
    "v-chart": _vueEcharts.default,
    CircleProgress: _CircleProgress.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["chartColors"])),
  methods: {
    monthChange: function monthChange(val) {
      var start = (0, _date.GetMonthStartAndEnd)(val[0]);
      var end = (0, _date.GetMonthStartAndEnd)(val[1]);
      this.monthRange = [start[0], end[1]];
    },
    dateChange: function dateChange(val) {
      var range = [,];
      switch (val) {
        case "本月":
          range = (0, _date.CurrentMonthOfStartAndEnd)();
          break;
        case "本季":
          range = (0, _date.CurrentQuarterlyOfStartAndEnd)();
          break;
        case "本年":
          range = (0, _date.CurrentYearOfStartAndEnd)();
          break;
      }
      this.monthRange = (0, _toConsumableArray2.default)(range);
    },
    getMaterialTypes: function getMaterialTypes() {
      var _this = this;
      this.loading = true;
      var postdata = {
        category: "PurchaseProduct"
      };
      (0, _Product.GetTreeList)(postdata).then(function (res) {
        _this.loading = false;
        var data = res.data;
        if (data.code !== 0) {
          return _this.$message.error("网络异常");
        }
        _this.materialTypes = res.data.dataList;
      }).catch(function (err) {
        _this.loading = false;
        console.log("接口问题", err);
      });
    },
    getRXOrderReport: function getRXOrderReport(data) {
      var _this2 = this;
      this.loading = true;
      (0, _report.GetRXOrderReportByParam)(data).then(function (res) {
        _this2.loading = false;
        var data = res.data;
        if (res.code !== 0) {
          return _this2.$message.error("网络异常");
        } else {
          _this2.summaryData = res.data;
          var polar = {
            color: _this2.chartColors,
            tooltip: {
              trigger: "item",
              formatter: "{b}: {c}"
            },
            series: [{
              type: "pie",
              radius: ["50%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center"
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "14",
                  fontWeight: "bold"
                }
              },
              labelLine: {
                show: false
              },
              data: []
            }]
          };
          _this2.summaryList = [];
          polar.series[0].data = [{
            value: data && data.TotalNum,
            name: "总工单"
          }, {
            value: data && data.CompleteNum,
            name: "完成工单"
          }];
          var c1 = JSON.stringify(polar);
          _this2.summaryList.push(JSON.parse(c1));
          polar.series[0].data = [{
            value: data && data.TotalNum,
            name: "总工单"
          }, {
            value: data && data.TimeOutNum,
            name: "超时工单"
          }];
          var c2 = JSON.stringify(polar);
          _this2.summaryList.push(JSON.parse(c2));
          polar.series[0].data = [{
            value: data && data.CompleteNum,
            name: "配送总数"
          }, {
            value: data && data.OnceCompleteNum,
            name: "完整收货"
          }];
          var c3 = JSON.stringify(polar);
          _this2.summaryList.push(JSON.parse(c3));
          polar.series[0].data = [{
            value: data && data.NormalCompleteNum,
            name: "配送总数"
          }, {
            value: data && data.NoOnceCompleteNum,
            name: "不完整收货"
          }];
          var c4 = JSON.stringify(polar);
          _this2.summaryList.push(JSON.parse(c4));
          _this2.purchaseFinishChart(data && data.MonthReportList);
          _this2.receiptFinishChart(data && data.MonthReportList);
        }
      }).catch(function (err) {
        _this2.loading = false;
        console.log("接口问题", err);
      });
    },
    purchaseFinishChart: function purchaseFinishChart(list) {
      var bar = {
          color: this.chartColors,
          title: {
            text: "采购任务完成情况",
            left: "center"
          },
          legend: {
            data: ["按时完成", "超时完成"],
            bottom: 0
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
            }
          },

          grid: {
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true
          },
          yAxis: {
            type: "value"
          },
          xAxis: {
            type: "category",
            data: []
          },
          series: [{
            name: "按时完成",
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              formatter: "{a}:{c}"
            },
            data: []
          }, {
            name: "超时完成",
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              formatter: "{a}:{c}"
            },
            data: []
          }]
        },
        dateList = [],
        overTimeList = [],
        onTimeList = [];
      if (list && list.length > 0) {
        list.forEach(function (val) {
          dateList.push(val.OrderCreateTimeMonth);
          onTimeList.push(val.CompleteNum - val.TimeOutNum);
          overTimeList.push(val.TimeOutNum);
        });
      }
      bar.xAxis.data = dateList;
      bar.series[0].data = onTimeList;
      bar.series[1].data = overTimeList;
      this.bar = bar;
    },
    receiptFinishChart: function receiptFinishChart(list) {
      var line = {
          color: this.chartColors,
          title: {
            text: "采购任务收货情况",
            left: "center"
          },
          legend: {
            data: ["完整收货", "不完整收货"],
            bottom: 0
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
            }
          },

          xAxis: {
            type: "category",
            data: []
          },
          yAxis: {
            type: "value"
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true
          },
          series: [{
            name: "完整收货",
            data: [],
            barWidth: 5,
            type: "line"
          }, {
            name: "不完整收货",
            data: [],
            type: "line"
          }]
        },
        finishList = [],
        unFinishList = [],
        dateList = [];
      if (list && list.length > 0) {
        list.forEach(function (val) {
          dateList.push(val.OrderCreateTimeMonth);
          finishList.push(val.OnceCompleteNum);
          unFinishList.push(val.NoOnceCompleteNum);
        });
      }
      line.xAxis.data = dateList;
      line.series[0].data = finishList;
      line.series[1].data = unFinishList;
      this.line = line;
    },
    search: function search() {
      this.condition.StartDateTime = this.monthRange[0];
      this.condition.EndDateTime = this.monthRange[1];
      this.condition.OptionObjectID = this.materialType;
      this.getRXOrderReport(this.condition);
    }
  },
  created: function created() {
    this.getMaterialTypes();
    this.monthRange = (0, _toConsumableArray2.default)((0, _date.CurrentMonthOfStartAndEnd)());
    this.condition.StartDateTime = this.monthRange[0];
    this.condition.EndDateTime = this.monthRange[1];
    this.getRXOrderReport(this.condition);
  }
};
exports.default = _default;