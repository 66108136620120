var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择店铺",
        visible: _vm.dialogVisible,
        width: "90%",
        "close-on-click-modal": false,
        id: "ShopPopup",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleCloseDialog,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { height: "80vh" },
        },
        [
          _c(
            "el-scrollbar",
            {
              staticClass: "main-el-scrollbar",
              staticStyle: { height: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "chooseStore" },
                [
                  _c("div", { staticClass: "inputArea" }, [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "160px",
                            "margin-right": "10px",
                          },
                          attrs: { placeholder: "店铺编号", clearable: "" },
                          model: {
                            value: _vm.selectForm.ShopCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectForm, "ShopCode", $$v)
                            },
                            expression: "selectForm.ShopCode",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-input", {
                          staticStyle: {
                            width: "160px",
                            "margin-right": "10px",
                          },
                          attrs: { placeholder: "店铺名称", clearable: "" },
                          model: {
                            value: _vm.selectForm.ShopName,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectForm, "ShopName", $$v)
                            },
                            expression: "selectForm.ShopName",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "160px",
                              "margin-right": "10px",
                            },
                            attrs: {
                              clearable: "",
                              placeholder: "地区",
                              filterable: "",
                            },
                            model: {
                              value: _vm.selectForm.Area,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectForm, "Area", $$v)
                              },
                              expression: "selectForm.Area",
                            },
                          },
                          _vm._l(_vm.areaList, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "160px",
                              "margin-right": "10px",
                            },
                            attrs: {
                              clearable: "",
                              placeholder: "城市",
                              filterable: "",
                            },
                            model: {
                              value: _vm.selectForm.City,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectForm, "City", $$v)
                              },
                              expression: "selectForm.City",
                            },
                          },
                          _vm._l(_vm.cityList, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "160px",
                              "margin-right": "10px",
                            },
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "店铺类型",
                            },
                            model: {
                              value: _vm.selectForm.ShopType,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectForm, "ShopType", $$v)
                              },
                              expression: "selectForm.ShopType",
                            },
                          },
                          _vm._l(_vm.storeTypeList, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "main-btn",
                            attrs: { type: "subPrimary" },
                            on: { click: _vm.handleSearch },
                          },
                          [_vm._v("检 索")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "main-btn",
                            attrs: {
                              icon: "el-icon-circle-check",
                              type: "primary",
                            },
                            on: { click: _vm.handleSubmit },
                          },
                          [_vm._v("确 定\n            ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "buts" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-right": "10px",
                                  },
                                  attrs: {
                                    action: _vm.UpLoadStoreFiles,
                                    "show-file-list": false,
                                    "on-success": _vm.handleUploadSuccess,
                                    "on-error": _vm.handleUploadError,
                                    "before-upload": _vm.beforeUpload,
                                    accept: ".xls,.xlsx",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "main-btn",
                                      attrs: { type: "subPrimary" },
                                    },
                                    [_vm._v("导 入")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "underline",
                                  attrs: { type: "text", size: "small" },
                                  on: { click: _vm.handleDownloadTemplate },
                                },
                                [_vm._v("下载模板")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !!_vm.storeTotal,
                          expression: "!!storeTotal",
                        },
                      ],
                      staticClass: "selectAll",
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { indeterminate: _vm.isIndeterminate },
                                  on: { change: _vm.handleCheckedAll },
                                  model: {
                                    value: _vm.isCheckedAll,
                                    callback: function ($$v) {
                                      _vm.isCheckedAll = $$v
                                    },
                                    expression: "isCheckedAll",
                                  },
                                },
                                [_vm._v("全选\n              ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.storeList, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.ShopModels && item.ShopModels.length,
                              expression:
                                "item.ShopModels && item.ShopModels.length",
                            },
                          ],
                          key: index,
                          staticClass: "storeArea",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "region" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "16px",
                                          color: "#000",
                                          "font-weight": "700",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.Key))]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 12 },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            indeterminate: item.indeterminate,
                                          },
                                          on: {
                                            change: function (val) {
                                              return _vm.handleChangeArea(
                                                val,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.checked,
                                            callback: function ($$v) {
                                              _vm.$set(item, "checked", $$v)
                                            },
                                            expression: "item.checked",
                                          },
                                        },
                                        [_vm._v("区域选择")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "glideLine" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "storeCollection" },
                            [
                              _c(
                                "el-row",
                                _vm._l(item.ShopModels, function (items) {
                                  return _c(
                                    "el-col",
                                    { key: items.ShopCode, attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "shopItemCheckbox",
                                          on: {
                                            change: function (val) {
                                              return _vm.handleChangeStore(
                                                val,
                                                items,
                                                item,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: items.checked,
                                            callback: function ($$v) {
                                              _vm.$set(items, "checked", $$v)
                                            },
                                            expression: "items.checked",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(items.ShopName)),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(items.CNCode) +
                                                "-" +
                                                _vm._s(items.STCode) +
                                                "\n                    "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }