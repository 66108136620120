"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var PPSupplyRouter = [{
  path: '/ppsupply',
  name: 'PPSupply',
  component: _layout.default,
  meta: {
    title: '维修供应商',
    icon: 'order',
    roles: ['adidasrole016']
  },
  redirect: '/ppsupply/order',
  children: [{
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/PPSupply/order/index'));
      });
    },
    name: 'PPSupplyOrder',
    redirect: '/ppsupply/order/index',
    meta: {
      title: '维修订单',
      roles: ['adidasrole016']
    },
    children: [{
      hidden: true,
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/PPSupply/order/list'));
        });
      },
      name: 'PPSupplyOrderList',
      meta: {
        title: '工单列表',
        activeMenu: '/ppsupply/order',
        roles: ['adidasrole016']
      }
    }, {
      hidden: true,
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/PPSupply/order/detail'));
        });
      },
      name: 'PPSupplyOrderDetail',
      meta: {
        title: '查看工单',
        activeMenu: '/ppsupply/order',
        roles: ['adidasrole016']
      }
    }]
  }]
}];
var _default = PPSupplyRouter;
exports.default = _default;