var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderInfoWrap" },
    [
      _c(
        "el-card",
        { staticClass: "box-card orderInfo" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("工单编号")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.OrderNo))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("创建时间")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.formatterDate(_vm.detail.CreateDateTime))),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("工单类型")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.OptionCategoryName))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("门店编号")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.ShopCode))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("门店名称")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.ShopName))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("工单状态")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.StatusText))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("上报类型")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.OptionCategoryName))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("上报项目")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.OptionObjectName))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("剩余时间")]),
                _vm._v(" "),
                _c(
                  "span",
                  [
                    _c("TimeState", {
                      staticStyle: { display: "inline" },
                      attrs: {
                        show: _vm.detail.IsUnShowLeftTime != 1,
                        time: parseInt(_vm.detail.LeftTime),
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("联系人")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.ContactName))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("联系人手机号")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.ContactTelephone))]),
              ]),
              _vm._v(" "),
              _vm.detail.POCode && _vm.detail.DepartmentID == 6
                ? _c(
                    "el-col",
                    { staticClass: "infoItem", attrs: { span: 8 } },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("发货单号"),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.detail.POCode))]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.ISCProductType
                ? _c(
                    "el-col",
                    { staticClass: "infoItem", attrs: { span: 8 } },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detail.DepartmentID == 5
                              ? "物料类型"
                              : "处置方式"
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.detail.ISCProductType))]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 24 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("工单描述")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.OrderDescription))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.fileData && _vm.fileData.length
            ? _c("OrderFile", {
                attrs: { fileList: _vm.fileData, collapse: false },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.lastProccess.ProcessDateTime
        ? _c(
            "el-card",
            { staticClass: "box-card newReply" },
            [
              _c("div", { staticClass: "title" }, [
                _c("span", { staticClass: "time" }, [
                  _vm._v(
                    _vm._s(_vm.formatterDate(_vm.lastProccess.ProcessDateTime))
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm.lastProccess.Remark
                    ? _c("span", { staticClass: "roleName" }, [
                        _vm._v("「" + _vm._s(_vm.lastProccess.Remark) + "」"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "userName" }, [
                    _vm._v(_vm._s(_vm.lastProccess.OptionUserName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "content",
                domProps: { innerHTML: _vm._s(_vm.lastProccess.ReplyContent) },
              }),
              _vm._v(" "),
              _vm.lastProccess.replyAttachment &&
              _vm.lastProccess.replyAttachment.length
                ? _c("OrderFile", {
                    attrs: {
                      fileList: _vm.lastProccess.replyAttachment,
                      collapse: false,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showclose
        ? _c(
            "el-card",
            [
              _c(
                "el-button",
                {
                  staticClass: "main-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.closeOrder },
                },
                [_vm._v("关单")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showreturn
        ? _c(
            "el-card",
            [
              _c(
                "el-button",
                {
                  staticClass: "main-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.returnOrder },
                },
                [_vm._v("退单")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }