"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
var _default = {
  name: 'CircleProgress',
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 0.5
    }
  },
  data: function data() {
    return {
      showPercentage: 0
    };
  },
  watch: {
    percentage: function percentage(val) {
      this.initDate(val);
    }
  },
  methods: {
    initDate: function initDate(newValue) {
      this.showPercentage = 0;
      if (newValue && newValue > 0) {
        gsap.to(this.$data, {
          duration: this.duration,
          showPercentage: newValue
        });
      }
    }
  },
  mounted: function mounted() {
    this.initDate(this.percentage);
  }
};
exports.default = _default;