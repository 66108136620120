var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "replyFormWrap" }, [
    _c(
      "div",
      { staticClass: "formMain" },
      [
        _c("div", { staticClass: "formLabel" }, [_vm._v("费用承担")]),
        _vm._v(" "),
        _c(
          "el-radio-group",
          {
            staticClass: "tabWrap",
            model: {
              value: _vm.replyType,
              callback: function ($$v) {
                _vm.replyType = $$v
              },
              expression: "replyType",
            },
          },
          [
            _vm._l(_vm.replyTypeList, function (item) {
              return [
                _c(
                  "el-radio",
                  { key: item.value, attrs: { label: item.value } },
                  [_vm._v(_vm._s(item.label))]
                ),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "formFooter" },
      [
        _c(
          "el-button",
          {
            staticClass: "main-btn",
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.handleSubmit()
              },
            },
          },
          [_vm._v("提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }