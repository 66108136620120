"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("./Loading/index"));
var _message = require("@/api/message");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AppMain',
  data: function data() {
    return {
      loading: false
    };
  },
  components: {
    AppMainLoading: _index.default
  },
  provide: function provide() {
    return {
      $openMainLoading: this.openMainLoading,
      $closeMainLoading: this.closeMainLoading,
      $getUnreadMessageCount: this.getUnreadMessageCount
    };
  },
  methods: {
    openMainLoading: function openMainLoading() {
      this.loading = true;
    },
    closeMainLoading: function closeMainLoading() {
      this.loading = false;
    },
    getUnreadMessageCount: function getUnreadMessageCount() {
      var _this = this;
      (0, _message.GetUnreadMessageCount)().then(function (res) {
        console.log(res.data && res.data.code == 0);
        if (res.data && res.data.code == 0) {
          _this.$store.dispatch('user/resetUnreadMessageCount', parseInt(res.data.data));
        }
      });
    }
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  watch: {
    key: function key() {
      this.closeMainLoading();
    }
  },
  created: function created() {
    var _this2 = this;
    this.getUnreadMessageCount();
    if (!window.ARS_UNREAD_MESSAGE_COUNT_TIMER) {
      window.ARS_UNREAD_MESSAGE_COUNT_TIMER = setInterval(function () {
        _this2.getUnreadMessageCount();
      }, 1000 * 60);
    }
  }
};
exports.default = _default;