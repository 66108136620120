"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EstimatedValueDownload = EstimatedValueDownload;
exports.EstimatedValueImport = void 0;
exports.ExportAllGroupList = ExportAllGroupList;
exports.ExportDetail = ExportDetail;
exports.ExportList = ExportList;
exports.GetAllGroupOrderListByStatusGroup = GetAllGroupOrderListByStatusGroup;
exports.GetMoreAttachUrl = GetMoreAttachUrl;
exports.GetOrderDetailByID = GetOrderDetailByID;
exports.GetOrderListByStatusGroup = GetOrderListByStatusGroup;
exports.GetOrderReplyListByOrderID = GetOrderReplyListByOrderID;
exports.GetOrderStatusByStatusGroup = GetOrderStatusByStatusGroup;
exports.GetSupplierOrderList = GetSupplierOrderList;
exports.PurchaseSelect = PurchaseSelect;
exports.PurchaseSubmit = PurchaseSubmit;
exports.SubmitJointSignReplyOption = SubmitJointSignReplyOption;
exports.SubmitOrderReminderReplyOption = SubmitOrderReminderReplyOption;
exports.SubmitOrderReplyOption = SubmitOrderReplyOption;
exports.SupplierSummit = SupplierSummit;
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request2"));
var _auth = require("@/utils/auth");
// 模板下载配置
function EstimatedValueDownload(data) {
  return (0, _request2.default)({
    url: '/api/Order/EstimatedValueDownload',
    method: 'post',
    //data: qs.stringify(data)
    data: data
  });
}
// 模板下载配置
function GetOrderListByStatusGroup(data) {
  return (0, _request.default)({
    url: '/api/Order/GetOrderListByStatusGroup',
    method: 'post',
    data: data
  });
}
// 创建工单 提交
function PurchaseSubmit(data) {
  return (0, _request2.default)({
    url: '/api/Order/PurchaseSubmit',
    method: 'post',
    //data: qs.stringify(data)
    data: data
  });
}
// 工单详情
function GetOrderDetailByID(params) {
  return (0, _request.default)({
    url: '/api/Order/GetOrderDetailByID',
    method: 'get',
    params: params
  });
}
// 流程处理
function GetOrderReplyListByOrderID(params) {
  return (0, _request2.default)({
    url: '/api/Order/GetOrderReplyListByOrderID',
    method: 'get',
    params: params
  });
}
// 详情表单提交
function SubmitOrderReplyOption(data) {
  return (0, _request.default)({
    url: '/api/Order/SubmitOrderReplyOption',
    method: 'post',
    data: data
  });
}
// 列表渲染
function PurchaseSelect(data) {
  return (0, _request2.default)({
    url: '/api/Order/PurchaseSelect',
    method: 'post',
    data: data
  });
}
// 工单状态
function GetOrderStatusByStatusGroup(params) {
  return (0, _request2.default)({
    url: '/api/Order/GetOrderStatusByStatusGroup',
    method: 'get',
    params: params
  });
}
// 导出 
function ExportList(data) {
  return (0, _request2.default)({
    url: '/api/Order/ExportList',
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  });
}
// 供应商列表获取 
function GetSupplierOrderList(data) {
  return (0, _request2.default)({
    url: '/api/Order/GetSupplierOrderList',
    method: 'post',
    data: data
  });
}
// 供应商列表获取 
function SupplierSummit(data) {
  return (0, _request2.default)({
    url: '/api/Order/SupplierSummit',
    method: 'post',
    data: data
  });
}
// 查看详情 导出 
function ExportDetail(params) {
  return (0, _request2.default)({
    url: '/api/Order/ExportDetail',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  });
}
function SubmitOrderReminderReplyOption(params) {
  return (0, _request.default)({
    url: 'api/Order/SubmitOrderReminderReplyOption',
    method: 'post',
    params: params
  });
}
function SubmitJointSignReplyOption(data) {
  return (0, _request.default)({
    url: 'api/Order/SubmitJointSignReplyOption',
    method: 'post',
    data: data
  });
}

// /api/Order / ProductOrderImport            /api/Oderr / SupplierSummit;
var EstimatedValueImport = process.env.VUE_APP_BASE_API + '/api/Order/EstimatedValueImport?UserToken=' + (0, _auth.getToken)();

// 获取外网图片地址
exports.EstimatedValueImport = EstimatedValueImport;
function GetMoreAttachUrl(data) {
  return (0, _request.default)({
    url: 'api/UploadFile/GetMoreAttachUrl',
    method: 'post',
    data: data
  });
}

/**
 * @description: 导出(全部选项卡)工单列表
 * @param {*} data
 * @return {*}
 */
function ExportAllGroupList(data) {
  return (0, _request2.default)({
    url: 'api/Order/ExportAllGroupList',
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  });
}

/**
 * @description: 查询全部工单列表
 * @param {*} data
 * @return {*}
 */
function GetAllGroupOrderListByStatusGroup(data) {
  return (0, _request.default)({
    url: 'api/Order/GetAllGroupOrderListByStatusGroup',
    method: 'post',
    data: data
  });
}