var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-badge",
            {
              staticClass: "unreadMessageCount",
              attrs: {
                max: 99,
                hidden: !_vm.unreadMessageCount,
                value: _vm.unreadMessageCount,
              },
            },
            [
              _c(
                "router-link",
                {
                  staticStyle: { "margin-right": "10px", "font-size": "18px" },
                  attrs: { to: "/message" },
                },
                [_c("span", { staticClass: "el-icon-message-solid" })]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("div", { staticClass: "username" }, [
                  _vm._v(
                    "欢迎您，" +
                      _vm._s(
                        _vm.nowRole.RoleName ? _vm.nowRole.RoleName + "，" : ""
                      ) +
                      "\n          "
                  ),
                  _c(
                    "span",
                    {
                      attrs: { title: "切换" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.hideName = !_vm.hideName
                        },
                      },
                    },
                    [
                      _vm.hideName
                        ? _c("span", [
                            _c("i", {
                              staticClass: "el-icon-view",
                              staticStyle: {
                                "font-size": "18px",
                                "vertical-align": "middle",
                              },
                            }),
                          ])
                        : _c("span", [_vm._v(" " + _vm._s(_vm.name) + " ")]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _vm.roleList.length > 1
                    ? _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.dialogVisible = true
                            },
                          },
                        },
                        [_vm._v("角色切换")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/message" } },
                    [_c("el-dropdown-item", [_vm._v(" 我的消息 ")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/user/info" } },
                    [_c("el-dropdown-item", [_vm._v(" 用户信息 ")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/user/password" } },
                    [_c("el-dropdown-item", [_vm._v(" 修改密码 ")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出登录"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "角色切换",
            visible: _vm.dialogVisible,
            width: "30%",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "mini" },
              model: {
                value: _vm.selectValue,
                callback: function ($$v) {
                  _vm.selectValue = $$v
                },
                expression: "selectValue",
              },
            },
            _vm._l(_vm.roleList, function (item) {
              return _c(
                "el-radio",
                {
                  key: item.RoleCode,
                  staticStyle: { margin: "0 10px 10px 0" },
                  attrs: { label: item.RoleCode, border: "" },
                },
                [_vm._v(_vm._s(item.RoleName))]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.switchRoles } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }