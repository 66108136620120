"use strict";

var _interopRequireWildcard = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _ShopPopup = _interopRequireDefault(require("@/views/components/ShopPopup"));
var _moment = _interopRequireDefault(require("moment"));
var API = _interopRequireWildcard(require("@/api/notice"));
var _NoticeMenu = _interopRequireDefault(require("@/views/components/NoticeMenu"));
var _PhotoAndVideoUpload = _interopRequireDefault(require("@/views/components/PhotoAndVideoUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      action: "",
      activeTab: 1,
      StartAndEnd: "",
      addform: {
        StoreID: [],
        Start: "",
        End: "",
        Content: "",
        Pics: [],
        Atts: []
        // DepartmentID:1,
        // fileList:[]
      },

      addformRules: {
        StoreID: [{
          required: true,
          message: "请选择门店",
          trigger: "change"
        }],
        Start: [{
          required: true,
          message: "请选择日期",
          trigger: "change"
        }],
        Content: [{
          required: true,
          message: "请输入描述",
          trigger: "change"
        }]
      },
      isShowStore: false,
      loading: false,
      dialogVisible: false,
      tableData: [],
      ImportNoticeAPI: API.ImportNoticeAPI,
      BatchNo: "",
      UpLoadFilesAPI: API.UpLoadFilesAPI,
      dialogImageUrl: "",
      pickerOptions: {
        // disabledDate(time) {
        //     return time.getTime() <= Date.now();
        // },
      },
      UpLoadFilesTemplate: API.UpLoadFilesTemplate
    };
  },
  inject: ["$openMainLoading", "$closeMainLoading"],
  components: {
    ShopPopup: _ShopPopup.default,
    NoticeMenu: _NoticeMenu.default,
    PhotoAndVideoUpload: _PhotoAndVideoUpload.default
  },
  methods: {
    handleSubmit: function handleSubmit(formName) {
      var _this2 = this;
      if (this.activeTab == 1) {
        this.$refs[formName].validate(function (valid) {
          if (valid) {
            _this2.submitData();
          }
        });
      } else {
        if (!this.BatchNo) {
          this.$message.warning("请上传文件");
          return false;
        }
        this.submitData();
      }
    },
    submitData: function submitData() {
      var _this3 = this;
      this.$openMainLoading();
      var _this = this;
      this.addform.Pics.map(function (item) {
        item.FileName = item.Filename;
      });
      var submitFn = function submitFn() {
        return _this3.activeTab == 1 ? API.SubmitNotice((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this3.addform), {}, {
          DepartmentID: _this3.departmentID
        })) : API.SubmitImportNotice({
          BatchNo: _this3.BatchNo,
          DepartmentID: _this3.departmentID
        });
      };
      submitFn().then(function (res) {
        _this3.$closeMainLoading();
        if (res.code == 0) {
          _this3.$message({
            type: "success",
            message: "创建成功",
            onClose: function onClose() {}
          });
          _this.$router.back(-1);
        } else {
          _this3.$message({
            type: "error",
            message: "创建失败",
            onClose: function onClose() {}
          });
        }
      }).catch(function (err) {
        _this3.$closeMainLoading();
      });
    },
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      if (res.code == 0) {
        this.tableData = res.data.Notices;
        this.BatchNo = res.data.BatchNo;
        this.$message.success("导入成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    handleChangeDaterange: function handleChangeDaterange(val) {
      this.addform.Start = val[0];
      this.addform.End = val[1];
    },
    formatterDate: function formatterDate(row, column, cellValue, index) {
      return cellValue ? (0, _moment.default)(cellValue).format("yyyy-MM-DD") : "";
    },
    handleChangeStore: function handleChangeStore() {
      if (!this.addform.StoreID.length) {
        this.$refs["addform"].clearValidate("StoreID");
      }
    },
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url;
    //   this.dialogVisible = true;
    // },
    // handleUploadPictureSuccess(res,file){
    //   if(res.code==0){
    //     if(this.addform.Pics.indexOf(res.FilePath)==-1){
    //       this.addform.Pics.push(res.FilePath)
    //     }
    //   }else{
    //   }
    // },
    // handlePictureRemove(file, fileList){
    //   let url = file.response.FilePath;
    //   let index = this.addform.Pics.indexOf(url);
    //   if(index>-1){
    //     this.addform.Pics.splice(index,1)
    //   }
    // },
    handleFilesSuccess: function handleFilesSuccess(res, fileList) {
      if (res.code == 0) {
        var isExist = this.addform.Atts.some(function (item) {
          if (item.FilePath == res.FilePath) {
            return true;
          }
        });
        if (!isExist) {
          this.addform.Atts.push({
            FileName: res.Filename,
            FilePath: res.FilePath,
            Thumbnail: res.Thumbnail,
            FileType: res.FileType
          });
        }
      } else {
        this.$message.error("上传附件失败");
      }
    },
    handleRemove: function handleRemove(file) {
      var url = file.response.FilePath;
      var index = -1;
      this.addform.Atts.some(function (t, i) {
        if (t.FilePath == url) {
          index = i;
          return true;
        }
      });
      if (index > -1) {
        this.addform.Atts.splice(index, 1);
      }
    },
    handleDownloadTemplate: function handleDownloadTemplate() {
      window.open(this.UpLoadFilesTemplate);
    }
  },
  computed: {
    departmentID: function departmentID() {
      return this.$route.meta.departmentID;
    }
  }
};
exports.default = _default;