"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _qs = _interopRequireDefault(require("qs"));
var _router = _interopRequireDefault(require("@/router"));
var CancelToken = _axios.default.CancelToken;
var source = CancelToken.source();
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // baseURL: window.ARS_CONFIG.api,
  //withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (_store.default.getters.token) {
    config.params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, config.params), {}, {
      UserToken: (0, _auth.getToken)()
    });
  }
  config.headers = {
    'Content-Type': 'application/json'
  };
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 0) {
    (0, _elementUi.Message)({
      message: res.msg || res.data || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(new Error(res.msg || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log(error);
  if (error.response.status === 401) {
    source.cancel();
    _store.default.dispatch('user/resetToken').then(function () {
      location.reload();
    });
    // if(document.getElementsByClassName('el-message-box').length === 0){
    //   MessageBox.alert('The authentication is invalid, please login again!', {
    //     confirmButtonText: 'ok',
    //     type: 'warning',
    //     showClose:false
    //   }).then(() => {
    //     store.dispatch('user/resetToken').then(() => {
    //       location.reload()
    //     })
    //   })
    // }
    return Promise.reject(error);
  }
  if (_axios.default.isCancel(error)) {
    // 取消请求的情况下，终端Promise调用链
    return new Promise(function () {});
  }
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;