"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteMessage = DeleteMessage;
exports.GetMessageList = GetMessageList;
exports.GetUnreadMessageCount = GetUnreadMessageCount;
exports.MarkUnreadMessage = MarkUnreadMessage;
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request2"));
// POST /api/Message/GetMessageList
function GetMessageList(data) {
  return (0, _request.default)({
    url: '/api/Message/GetMessageList',
    method: 'post',
    data: data
  });
}
// POST /api/Message/MarkUnreadMessage
function MarkUnreadMessage(data) {
  return (0, _request.default)({
    url: '/api/Message/MarkUnreadMessage',
    method: 'post',
    data: data
  });
}

// POST /api/Message/DeleteMessage
function DeleteMessage(data) {
  return (0, _request.default)({
    url: '/api/Message/DeleteMessage',
    method: 'post',
    data: data
  });
}

//GET /api/Message/GetUnreadMessageCount

function GetUnreadMessageCount() {
  return (0, _request2.default)({
    url: '/api/Message/GetUnreadMessageCount',
    method: 'get'
  });
}