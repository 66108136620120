import { render, staticRenderFns } from "./RXFormItem.vue?vue&type=template&id=31be1fae&scoped=true&"
import script from "./RXFormItem.vue?vue&type=script&lang=js&"
export * from "./RXFormItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31be1fae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31be1fae')) {
      api.createRecord('31be1fae', component.options)
    } else {
      api.reload('31be1fae', component.options)
    }
    module.hot.accept("./RXFormItem.vue?vue&type=template&id=31be1fae&scoped=true&", function () {
      api.rerender('31be1fae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/OrderForm/RXFormItem.vue"
export default component.exports