"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ISCDOMApprove",
  inject: ["$openMainLoading", "$closeMainLoading", "updateDetailsPage", "orderBasicData"],
  data: function data() {
    return {
      replyTypeList: [{
        label: "RM",
        value: "RM"
      }, {
        label: "OPS",
        value: "OPS"
      }],
      replyType: "RM"
    };
  },
  computed: {
    replyContent: function replyContent() {
      return "RM\u9009\u62E9\u7531".concat(this.replyType, "\u627F\u62C5");
    }
  },
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      var postData = {
        NextOrderStatus: 1,
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderNO,
          ReplyContent: this.replyContent,
          ReplyCategoryName: this.replyType
        },
        attachmentList: []
      };
      this.$openMainLoading();
      (0, _order.SubmitJointSignReplyOption)(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: "提交成功",
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    }
  }
};
exports.default = _default;