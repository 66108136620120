var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "fileWrap", staticClass: "fileWrap" }, [
    _c(
      "div",
      { ref: "fileBox", staticClass: "fileBox" },
      [
        _c(
          "div",
          { staticClass: "imgwrap el-upload-list--picture-card" },
          [
            _vm._l(_vm.fileList, function (item, index) {
              return [
                _c(
                  "div",
                  { key: index, staticClass: "el-upload-list__item" },
                  [
                    _c("el-image", {
                      staticClass: "imgItem",
                      attrs: { src: item.remoreURL, fit: "cover" },
                      on: {
                        error: function ($event) {
                          return _vm.getRemotePath(
                            item.Thumbnail ? item.Thumbnail : item.FilePath,
                            item
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "el-upload-list__item-actions" },
                      [
                        item.FileType == 1
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-preview",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePicPreview(item)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-zoom-in" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.FileType == 2
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-preview",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleVideoPreview(item)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-video-play" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-delete",
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            }),
            _vm._v(" "),
            !!(_vm.fileList.length < _vm.limitTotle)
              ? _c(
                  "el-upload",
                  {
                    ref: "PhotoAndVideoUpload",
                    staticStyle: {
                      display: "inline-block",
                      "vertical-align": "top",
                    },
                    attrs: {
                      multiple: "",
                      limit: _vm.limit,
                      action: _vm.UpLoadFilesAPI,
                      "show-file-list": false,
                      "on-success": _vm.handleUploadSuccess,
                      "on-exceed": _vm.handleUploadExceed,
                      accept: ".jpg,.png,.jpeg,.mp4,.avi,.wmv",
                      "before-upload": _vm.handleUploadBefore,
                    },
                  },
                  [
                    _c("div", { staticClass: "picVideoUpLoadWrap" }, [
                      _c("i", { staticClass: "el-icon-plus" }),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.dialogVisible },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c("img", {
              attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.nowVideoData.title,
              visible: _vm.videoPlayDialogVisible,
              width: "800px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.videoPlayDialogVisible = $event
              },
            },
          },
          [
            _vm.videoPlayDialogVisible
              ? _c("vueMiniPlayer", {
                  staticClass: "uploadVideoPlay",
                  attrs: { video: _vm.nowVideoData },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }