"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetMaterialList = GetMaterialList;
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request2"));
var _qs = _interopRequireDefault(require("qs"));
// 物料类型
function GetMaterialList(data) {
  return (0, _request2.default)({
    url: '/api/Material/GetMaterialList',
    method: 'get',
    params: data
  });
}