"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _ehs = require("@/api/ehs");
var _orderFormConfig = require("./orderFormConfig");
var _notice = require("@/api/notice");
var _PhotoAndVideoUpload = _interopRequireDefault(require("@/views/components/PhotoAndVideoUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "CONSupplyFormItem",
  data: function data() {
    return {
      replyType: 5010,
      //5010接单，5000退单
      replyContent: '',
      returnCauseList: _orderFormConfig.BackAndRejectCause.CONSupply_back,
      returnCauseValue: "",
      UpLoadFilesAPI: _notice.UpLoadFilesAPI,
      fileList: {
        Pics: [],
        Atts: []
      }
    };
  },
  components: {
    PhotoAndVideoUpload: _PhotoAndVideoUpload.default
  },
  inject: ['$openMainLoading', '$closeMainLoading', 'updateDetailsPage', 'orderBasicData'],
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      if (this.replyType == 5000 && !this.returnCauseValue) {
        //退单
        this.$message.warning('请选择退单原因');
        return false;
      }
      if (!this.replyContent && this.replyType !== 5010) {
        this.$message.warning('请输入描述信息');
        return false;
      }
      var postData = {
        NextOrderStatus: this.replyType,
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderCode,
          ReplyReasonName: this.replyType == 5000 ? this.returnCauseValue : '',
          ReplyContent: this.replyContent,
          ReplyCategoryName: this.replyType == 5000 ? "退单" : "接单"
        },
        attachmentList: [].concat((0, _toConsumableArray2.default)(this.fileList.Pics), (0, _toConsumableArray2.default)(this.fileList.Atts))
      };
      this.$openMainLoading();
      (0, _ehs.SubmitEHSAndPPOrderReplyOption)(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: '提交成功',
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    }
  }
};
exports.default = _default;