"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _PhotoAndVideoUpload = _interopRequireDefault(require("@/views/components/PhotoAndVideoUpload"));
var _orderFormConfig = require("./orderFormConfig");
var _ehs = require("@/api/ehs");
var _notice = require("@/api/notice");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "EHSEventSub",
  data: function data() {
    return {
      loading: false,
      replyType: 2,
      replyContent: "",
      orderType: [],
      orderTypeName: [],
      orderTypeList: [],
      departmentCode: "adidasDP002",
      UpLoadFilesAPI: _notice.UpLoadFilesAPI,
      departmentList: [{
        code: "adidasDP002",
        name: "健康安全",
        sendCode: "adidasDP003",
        sendName: "利润保护"
      }, {
        code: "adidasDP003",
        name: "利润保护",
        sendCode: "adidasDP002",
        sendName: "健康安全"
      }],
      fileList: {
        Pics: [],
        Atts: []
      },
      returnCauseList: _orderFormConfig.BackAndRejectCause.EHSAndPP_back,
      returnCauseValue: ""
    };
  },
  components: {
    PhotoAndVideoUpload: _PhotoAndVideoUpload.default
  },
  inject: ["$openMainLoading", "$closeMainLoading", "updateDetailsPage", "orderBasicData"],
  methods: {
    handleChangeOrderType: function handleChangeOrderType() {
      var _this = this;
      var arr = [];
      if (this.orderType && this.orderType.length) {
        this.orderTypeList.some(function (item) {
          if (item.ID == _this.orderType[0]) {
            arr[0] = item.OrderTypeName;
            item.SonOrderTypes.some(function (t) {
              if (t.ID == _this.orderType[1]) {
                arr[1] = t.OrderTypeName;
                return true;
              }
            });
            return true;
          }
        });
      }
      this.orderTypeName = arr;
    },
    handleSubmit: function handleSubmit() {
      if (this.replyType == 1) {
        if (this.orderType[0]) {
          this.handleForwarding();
        }
      } else {
        this.handleReply();
      }
    },
    handleForwarding: function handleForwarding() {
      var _this2 = this;
      if (!this.replyContent) {
        this.$message.warning("请输入描述信息");
        return false;
      }
      var postData = {
        EHSAndPPChangeDto: {
          DepartmentCode: this.forwardingDepartmentCode,
          OptionCategoryName: this.orderTypeName[0],
          OptionCategoryID: this.orderType[0],
          OptionObjectID: this.orderType[1],
          OptionObjectName: this.orderTypeName[1],
          OrderID: this.orderBasicData.OrderID,
          ReplyContent: this.replyContent,
          ReplyCategoryName: "转发",
          OldReplyID: this.orderBasicData.OldReplyID
        },
        AttachmentList: [].concat((0, _toConsumableArray2.default)(this.fileList.Pics), (0, _toConsumableArray2.default)(this.fileList.Atts))
      };
      this.$openMainLoading();
      (0, _ehs.SubmitEHSAndPPOrderForwarding)(postData).then(function (res) {
        _this2.$closeMainLoading();
        _this2.$message({
          message: "转发成功",
          type: "success"
        });
        _this2.updateDetailsPage();
        //this.$router.go(-1);
      }).catch(function (err) {
        _this2.$closeMainLoading();
      });
    },
    handleReply: function handleReply() {
      var _this3 = this;
      if (!this.replyContent) {
        this.$message.warning("请输入描述信息");
        return false;
      }
      var postData = {
        NextOrderStatus: "",
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderCode,
          ReplyContent: this.replyContent
        },
        AttachmentList: [].concat((0, _toConsumableArray2.default)(this.fileList.Pics), (0, _toConsumableArray2.default)(this.fileList.Atts))
      };
      if (this.replyType == 2) {
        postData.reply.ReplyCategoryName = "跟进";
        postData.NextOrderStatus = 3010; //跟进
      } else if (this.replyType == 3) {
        postData.reply.ReplyCategoryName = "退单";
        postData.NextOrderStatus = 90000; //退单
        postData.ReplyReasonName = this.replyType == 3 ? this.returnCauseValue : "";
      } else if (this.replyType == 4) {
        postData.reply.ReplyCategoryName = "关闭";
        postData.NextOrderStatus = 100000; //关闭
      }

      this.$openMainLoading();
      (0, _ehs.SubmitEHSAndPPOrderReplyOption)(postData).then(function (res) {
        _this3.$closeMainLoading();
        _this3.$message({
          message: "提交成功",
          type: "success"
        });
        _this3.updateDetailsPage();
      }).catch(function (err) {
        _this3.$closeMainLoading();
      });
    },
    getOrderType: function getOrderType() {
      var _this4 = this;
      var postData = {
        DepartmentCode: this.forwardingDepartmentCode,
        CategoryType: "EventsReported"
      };
      (0, _ehs.GetEHSOrderType)(postData).then(function (res) {
        _this4.orderTypeList = res.data;
      });
    },
    handleFilesSuccess: function handleFilesSuccess(res, fileList) {
      if (res.code == 0) {
        if (this.fileList.Atts.indexOf(res.FilePath) == -1) {
          this.fileList.Atts.push(res);
        }
      } else {
        this.$message.error(res.msg || "上传失败");
      }
    },
    handleRemove: function handleRemove(file) {
      var url = file.response.FilePath;
      var index = -1;
      this.fileList.Atts.some(function (item, i) {
        if (item.FilePath == url) {
          index = i;
        }
        return true;
      });
      if (index > -1) {
        this.fileList.Atts.splice(index, 1);
      }
    },
    handleExceed: function handleExceed() {
      this.$message.warning("文件最多上传5个附件");
    }
  },
  created: function created() {
    this.getOrderType();
  },
  computed: {
    forwardingDepartmentCode: function forwardingDepartmentCode() {
      var _this5 = this;
      var str = "";
      this.departmentList.some(function (item) {
        if (item.code === _this5.orderBasicData.DepartmentCode) {
          str = item.sendCode;
          return true;
        }
      });
      return str;
    },
    forwardingDepartmentName: function forwardingDepartmentName() {
      var _this6 = this;
      var str = "";
      this.departmentList.some(function (item) {
        if (item.code === _this6.orderBasicData.DepartmentCode) {
          str = item.sendName;
          return true;
        }
      });
      return str;
    }
  }
};
exports.default = _default;