"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _moment = _interopRequireDefault(require("moment"));
var _OrderFile = _interopRequireDefault(require("../OrderFile"));
var _TimeState = _interopRequireDefault(require("../TimeState"));
var _order = require("@/api/order");
var _order2 = require("@/api/order2");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "OrderInfo",
  props: {
    orderId: {
      type: Number,
      default: 0
    },
    detailInfo: {
      type: Object,
      default: null
    },
    showclose: {
      type: Boolean,
      default: false
    },
    showreturn: {
      type: Boolean,
      default: false
    }
  },
  inject: ['$openMainLoading', '$closeMainLoading', 'updateDetailsPage'],
  components: {
    OrderFile: _OrderFile.default,
    TimeState: _TimeState.default
  },
  data: function data() {
    return {
      detail: {},
      lastProccess: {},
      fileData: []
    };
  },
  methods: {
    closeOrder: function closeOrder() {
      var _this = this;
      var roleId = this.detailInfo && this.detailInfo.ChangedepartmentOrder && this.detailInfo.ChangedepartmentOrder.ToRoleID,
        message = "确认关单？";
      if (roleId == this.nowRole.RoleID) {
        message = "转发出去的工单也将关闭，确认关单？";
      }
      this.$confirm(message, '确认信息', {
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(function () {
        var postData = {
          NextOrderStatus: 100000,
          reply: {
            ID: _this.detailInfo.OldReplyID,
            OrderID: _this.orderId,
            OrderCode: _this.detail.OrderNo,
            ReplyContent: "主动关单",
            ReplyCategoryName: "关单"
          }
        };
        _this.$openMainLoading();
        (0, _order.SubmitOrderReplyOption)(postData).then(function (res) {
          _this.$closeMainLoading();
          _this.$message({
            message: "提交成功",
            type: "success",
            onClose: function onClose() {
              _this.$router.back(-1);
            }
          });
        }).catch(function (err) {
          _this.$closeMainLoading();
        });
      }).catch(function () {
        return;
      });
    },
    returnOrder: function returnOrder() {
      var _this2 = this;
      this.$confirm('确认退单？', '确认信息', {
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(function () {
        var postData = {
          NextOrderStatus: 90000,
          reply: {
            ID: _this2.detailInfo.OldReplyID,
            OrderID: _this2.orderId,
            OrderCode: _this2.detail.OrderNo,
            ReplyContent: "主动退单",
            ReplyCategoryName: "退单"
          }
        };
        _this2.$openMainLoading();
        (0, _order.SubmitOrderReplyOption)(postData).then(function (res) {
          _this2.$closeMainLoading();
          _this2.$message({
            message: "提交成功",
            type: "success",
            onClose: function onClose() {
              _this2.$router.back(-1);
            }
          });
        }).catch(function (err) {
          _this2.$closeMainLoading();
        });
      }).catch(function () {
        return;
      });
    },
    formatterDate: function formatterDate(data) {
      return data ? (0, _moment.default)(data).format("yyyy-MM-DD HH:mm:ss") : "";
    },
    dataProcessing: function dataProcessing() {
      this.detail = this.detailInfo.BDOrder || {};
      this.lastProccess = this.detailInfo.LastOneReply || {};
      this.fileData = this.detailInfo.OrderAttachment || [];
    }
  },
  created: function created() {
    if (this.detailInfo) {
      this.dataProcessing();
    }
  },
  watch: {
    detailInfo: function detailInfo(val) {
      if (val) {
        this.dataProcessing();
      }
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["nowRole"]))
};
exports.default = _default;