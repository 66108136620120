var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "orderProcess", staticStyle: { padding: "40px 0" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 60 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-timeline",
                { staticStyle: { "padding-top": "2px" } },
                _vm._l(_vm.processList, function (item, index) {
                  return _c(
                    "el-timeline-item",
                    { key: index },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                            "margin-bottom": "13px",
                          },
                        },
                        [
                          item.Status !== 1
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "17px",
                                    color: "#6b6f7b",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatterDate(item.ProcessDateTime)
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.Status == 1
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "17px",
                                    color: "#6b6f7b",
                                    "letter-spacing": "1px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        item.ReplyCategoryName
                                          ? item.ReplyCategoryName + ":"
                                          : ""
                                      ) +
                                      "\n              " +
                                      _vm._s(item.ReplyContent) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            item.Remark
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "17px",
                                      color: "#6b6f7b",
                                    },
                                  },
                                  [_vm._v("「" + _vm._s(item.Remark) + "」")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "16px",
                                  color: "#000000",
                                },
                              },
                              [_vm._v(_vm._s(item.RealName))]
                            ),
                            _vm._v(" "),
                            item.Status == 1
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "16px",
                                      color: "#000000",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.RoleName))]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      item.Status !== 1
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "16px",
                                color: "#a0aac2",
                                "letter-spacing": "1px",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    item.ReplyCategoryName
                                      ? item.ReplyCategoryName + ":"
                                      : ""
                                  ) +
                                  "\n            "
                              ),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(item.ReplyContent),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.Att && item.Att.length
                        ? _c("OrderFile", {
                            attrs: { collapse: false, fileList: item.Att },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { attrs: { slot: "dot" }, slot: "dot" }, [
                        index == 0 && index !== _vm.processList.length - 1
                          ? _c("div", { staticClass: "processDot active" }, [
                              _c("i", { staticClass: "el-icon-right" }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        index !== 0 && index !== _vm.processList.length - 1
                          ? _c("div", { staticClass: "processDot" }, [
                              _c("div", { staticClass: "outerCircle" }, [
                                _c("div", { staticClass: "insideCircle" }),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        index == _vm.processList.length - 1
                          ? _c("div", { staticClass: "processDot first" }, [
                              _c("i", { staticClass: "el-icon-document" }),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "remark-wrap" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("写备注")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "remark-input",
                  attrs: { type: "textarea", rows: 3 },
                  model: {
                    value: _vm.remark,
                    callback: function ($$v) {
                      _vm.remark = $$v
                    },
                    expression: "remark",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "main-btn",
                    attrs: { disabled: !_vm.remark, type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleSubmitRemark()
                      },
                    },
                  },
                  [_vm._v("提交")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }