"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _RXRouter = _interopRequireDefault(require("./RXRouter"));
var _EHSRouter = _interopRequireDefault(require("./EHSRouter"));
var _PPRouter = _interopRequireDefault(require("./PPRouter"));
var _PPSupplyRouter = _interopRequireDefault(require("./PPSupplyRouter"));
var _CONRouter = _interopRequireDefault(require("./CONRouter"));
var _CONSupplyRouter = _interopRequireDefault(require("./CONSupplyRouter"));
var _RXSupplyRouter = _interopRequireDefault(require("./RXSupplyRouter"));
var _MKTSupplyRouter = _interopRequireDefault(require("./MKTSupplyRouter"));
var _MKTRouter = _interopRequireDefault(require("./MKTRouter"));
var _SCMRouter = _interopRequireDefault(require("./SCMRouter"));
_vue.default.use(_vueRouter.default);
/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

// 1 Rx管理员 adidasrole001
// 2 门店人员 adidasrole002
// 3 RX供应商 adidasrole003
// 4 DOM adidasrole004
// 5 ROM adidasrole005
// 6 协调员 adidasrole006
// 7 RM-SP管理员 adidasrole007
// 8 RM-ORI adidasrole008
// 9 SCM管理员 adidasrole009
// 10 CON管理员 adidasrole010
// 11 EHS管理员 adidasrole011
// 12 PP管理员 adidasrole012
// 13 CON维修供应商 adidasrole013
// 14 RM-NEO管理员 adidasrole014
// 15 RM-KIDS管理员 adidasrole015
// 16 PP维修供应商 adidasrole016

var constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/User/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  hidden: true,
  redirect: '/dashboard'
}, {
  path: '/dashboard',
  component: _layout.default,
  redirect: '/dashboard/index',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/Dashboard/index'));
      });
    },
    hidden: true,
    meta: {
      title: 'Dashboard',
      icon: "dashboard"
    }
  }]
}, {
  path: '/message',
  component: _layout.default,
  hidden: true,
  name: 'Message',
  redirect: '/message/index',
  meta: {
    title: '系统消息'
  },
  children: [{
    path: 'index',
    hidden: true,
    name: 'MessageList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/User/message/index'));
      });
    },
    meta: {
      title: '消息列表'
    }
  }]
}, {
  path: '/user',
  component: _layout.default,
  hidden: true,
  name: 'User',
  redirect: '/user/info',
  meta: {
    title: '用户信息'
  },
  children: [{
    path: 'info',
    hidden: true,
    name: '用户信息',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/User/info/index'));
      });
    },
    meta: {
      title: '用户信息'
    }
  }, {
    path: 'password',
    hidden: true,
    name: '修改密码',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/User/password/index'));
      });
    },
    meta: {
      title: '修改密码'
    }
  }]
}];
exports.constantRoutes = constantRoutes;
var asyncRoutes = [].concat((0, _toConsumableArray2.default)(_RXRouter.default), (0, _toConsumableArray2.default)(_EHSRouter.default), (0, _toConsumableArray2.default)(_PPRouter.default), (0, _toConsumableArray2.default)(_CONRouter.default), (0, _toConsumableArray2.default)(_PPSupplyRouter.default), (0, _toConsumableArray2.default)(_CONSupplyRouter.default), (0, _toConsumableArray2.default)(_RXSupplyRouter.default), (0, _toConsumableArray2.default)(_MKTSupplyRouter.default), (0, _toConsumableArray2.default)(_MKTRouter.default), (0, _toConsumableArray2.default)(_SCMRouter.default), [{
  path: '*',
  redirect: '/404',
  hidden: true
}]);
exports.asyncRoutes = asyncRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;