var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("OrderDetail", {
    attrs: {
      orderId: _vm.orderId,
      departmentCode: _vm.departmentCode,
      routePath: _vm.routePath,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }