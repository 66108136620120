var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderInfoWrap" },
    [
      _c(
        "el-card",
        { staticClass: "box-card orderInfo" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("工单编号")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.OrderNo))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("创建时间")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.formatterDate(_vm.detail.CreateDateTime))),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("RX设置时间")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.formatterDate2(_vm.detail.ProcessStartTime))
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "split" }, [_vm._v("/")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.formatterDate2(_vm.detail.ProcessEndTime))),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("所属年月")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.OrderYearAndMonth))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("工单类型")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.OptionCategoryName))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("门店编号")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.ShopCode))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("门店名称")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.ShopName))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("工单状态")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.StatusText))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("采购项目")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.detail.OptionObjectName))]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "infoItem", attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("截至时间")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.formatterDate(_vm.detail.ClosingDateTime))),
                ]),
              ]),
              _vm._v(" "),
              _vm.detail.PredictNum > -1
                ? _c(
                    "el-col",
                    { staticClass: "infoItem", attrs: { span: 8 } },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("预估数量"),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.detail.PredictNum))]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.productList.length
                ? _c(
                    "el-col",
                    { staticClass: "infoItem", attrs: { span: 24 } },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("店铺提交物料"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "underline",
                          staticStyle: { padding: "0" },
                          attrs: { type: "text" },
                          on: { click: _vm.exportOrder },
                        },
                        [_vm._v("导出")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "productList" },
                        [
                          _vm._l(_vm.productList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "productItem" },
                              [
                                _c("div", {}, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.ProductName) +
                                      " - " +
                                      _vm._s(item.Num) +
                                      _vm._s(item.Unit) +
                                      "\n              "
                                  ),
                                  !!item.Specifications
                                    ? _c("span", [
                                        _vm._v(
                                          "（" +
                                            _vm._s(item.Specifications) +
                                            "）"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm.detail.PredictNum > -1
                            ? _c("div", [
                                _c("div", [
                                  _vm._v("\n              合计:"),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v(_vm._s(this.productTotal))]
                                  ),
                                  _vm._v("个，超出预估数量\n              "),
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        color: _vm.proportionType
                                          ? "red"
                                          : "#333",
                                      },
                                    },
                                    [_vm._v(_vm._s(this.proportion))]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.detail.Att && _vm.detail.Att.length
            ? _c("OrderFile", {
                attrs: { fileList: _vm.detail.Att, collapse: false },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.lastProccess.ProcessDateTime
        ? _c(
            "el-card",
            { staticClass: "box-card newReply" },
            [
              _c("div", { staticClass: "title" }, [
                _c("span", { staticClass: "time" }, [
                  _vm._v(
                    _vm._s(_vm.formatterDate(_vm.lastProccess.ProcessDateTime))
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm.lastProccess.Remark
                    ? _c("span", { staticClass: "roleName" }, [
                        _vm._v("「" + _vm._s(_vm.lastProccess.Remark) + "」"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "userName" }, [
                    _vm._v(_vm._s(_vm.lastProccess.RealName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.lastProccess.ReplyContent
                ? _c("div", {
                    staticClass: "content",
                    domProps: {
                      innerHTML: _vm._s(_vm.lastProccess.ReplyContent),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.lastProccess.replyAttachment &&
              _vm.lastProccess.replyAttachment.length
                ? _c("OrderFile", {
                    attrs: {
                      fileList: _vm.lastProccess.replyAttachment,
                      collapse: false,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }