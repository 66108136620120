"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
var _default = {
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  name: 'OrderDetailMenu',
  model: {
    prop: 'value',
    event: 'setValue'
  },
  data: function data() {
    return {
      menuList: [{
        label: '工单详情',
        value: 1
      }, {
        label: '工单处理流程',
        value: 2
      }]
    };
  },
  methods: {
    handleSwitchMenu: function handleSwitchMenu(val) {
      if (val === this.value) {
        return false;
      }
      this.$emit('setValue', val);
      this.$emit('change', val);
    }
  }
};
exports.default = _default;