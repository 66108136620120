"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Dashboard",
  data: function data() {
    return {
      quickLink: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["name", "permission_routes", "sidebar"])),
  created: function created() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var quickLink = [];
      this.permission_routes.map(function (item) {
        if (!item.hidden && item.path != "/") {
          item.children.map(function (e) {
            var obj = (0, _objectSpread2.default)({}, e);
            obj.path = item.path + "/" + e.path;
            quickLink.push(obj);
          });
        }
      });
      this.quickLink = quickLink;
    }
  },
  watch: {
    permission_routes: function permission_routes(val) {
      this.initData();
    }
  }
};
exports.default = _default;