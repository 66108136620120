var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "replyFormWrap" }, [
    _c(
      "div",
      { staticClass: "formMain" },
      [
        _c(
          "el-radio-group",
          {
            staticClass: "tabWrap",
            model: {
              value: _vm.replyType,
              callback: function ($$v) {
                _vm.replyType = $$v
              },
              expression: "replyType",
            },
          },
          [
            _c("el-radio", { attrs: { label: 1 } }, [_vm._v("转发")]),
            _vm._v(" "),
            _c("el-radio", { attrs: { label: 2 } }, [_vm._v("跟进")]),
            _vm._v(" "),
            _c("el-radio", { attrs: { label: 3 } }, [_vm._v("退单")]),
            _vm._v(" "),
            _c("el-radio", { attrs: { label: 4 } }, [_vm._v("关闭")]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          { attrs: { gutter: 40 } },
          [
            _c(
              "el-col",
              { attrs: { span: 8 } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.replyType == 1,
                        expression: "replyType == 1",
                      },
                    ],
                    staticClass: "formLabel",
                  },
                  [
                    _vm._v(
                      "\n          转给：" +
                        _vm._s(_vm.forwardingDepartmentName) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("el-cascader", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.replyType == 1,
                      expression: "replyType == 1",
                    },
                  ],
                  ref: "orderTypeCascader",
                  staticStyle: { width: "100%", margin: "0 0 20px 0" },
                  attrs: {
                    filterable: "",
                    placeholder: "请选择事件类型",
                    options: _vm.orderTypeList,
                    props: {
                      value: "ID",
                      label: "OrderTypeName",
                      children: "SonOrderTypes",
                      expandTrigger: "hover",
                    },
                    "collapse-tags": "",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleChangeOrderType("orderTypeCascader")
                    },
                  },
                  model: {
                    value: _vm.orderType,
                    callback: function ($$v) {
                      _vm.orderType = $$v
                    },
                    expression: "orderType",
                  },
                }),
                _vm._v(" "),
                _vm.replyType == 3
                  ? _c(
                      "el-select",
                      {
                        staticStyle: { "margin-bottom": "20px", width: "100%" },
                        attrs: { placeholder: "请选择退单原因" },
                        model: {
                          value: _vm.returnCauseValue,
                          callback: function ($$v) {
                            _vm.returnCauseValue = $$v
                          },
                          expression: "returnCauseValue",
                        },
                      },
                      _vm._l(_vm.returnCauseList, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("el-input", {
                  attrs: {
                    rows: 3,
                    type: "textarea",
                    placeholder: "请输入描述信息",
                  },
                  model: {
                    value: _vm.replyContent,
                    callback: function ($$v) {
                      _vm.replyContent = $$v
                    },
                    expression: "replyContent",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "formLabel" }, [
                _vm._v("上传图片或者视频(最多上传9个):"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("PhotoAndVideoUpload", {
                    model: {
                      value: _vm.fileList.Pics,
                      callback: function ($$v) {
                        _vm.$set(_vm.fileList, "Pics", $$v)
                      },
                      expression: "fileList.Pics",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "formLabel" }, [
                _vm._v("上传附件（WORD/EXCEL/PPT/PDF）"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.UpLoadFilesAPI,
                        "on-remove": _vm.handleRemove,
                        limit: 5,
                        multiple: "",
                        "on-success": _vm.handleFilesSuccess,
                        "on-exceed": _vm.handleExceed,
                        accept: ".xls,.xlsx,.doc,.docx,.ppt,pptx,.pdf",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "formFooter" },
      [
        _c(
          "el-button",
          {
            staticClass: "main-btn",
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.handleSubmit()
              },
            },
          },
          [_vm._v("提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }