"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var PPRouter = [{
  path: '/pp',
  name: 'PP',
  component: _layout.default,
  meta: {
    title: 'PP 工单管理',
    icon: 'order',
    roles: ['adidasrole012', 'adidasrole006', 'adidasrole011']
  },
  redirect: '/pp/order/event',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/PP/index'));
      });
    },
    name: 'PPDashboard',
    meta: {
      title: 'PP Dashboard',
      roles: ['adidasrole012']
    }
  }, {
    path: 'order/event',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/PP/order/event/index'));
      });
    },
    name: 'PPEventOrder',
    redirect: '/pp/order/event/index',
    meta: {
      title: 'PP事件上报',
      roles: ['adidasrole012', 'adidasrole011']
    },
    children: [{
      hidden: true,
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/PP/order/event/list'));
        });
      },
      name: 'PPEventOrderList',
      meta: {
        title: '工单列表',
        activeMenu: '/pp/order/event',
        roles: ['adidasrole012', 'adidasrole006', 'adidasrole011']
      }
    }, {
      hidden: true,
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/PP/order/event/detail'));
        });
      },
      name: 'PPOrderDetail',
      meta: {
        title: '查看工单',
        activeMenu: '/pp/order/event',
        roles: ['adidasrole012', 'adidasrole006', 'adidasrole011']
      }
    }]
  }, {
    path: 'order/service',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/PP/order/service/index'));
      });
    },
    name: 'PPServiceOrder',
    redirect: '/pp/order/service/index',
    meta: {
      title: '维修',
      roles: ['adidasrole012', 'adidasrole006']
    },
    children: [{
      hidden: true,
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/PP/order/service/list'));
        });
      },
      name: 'PPOrderServiceList',
      meta: {
        title: '工单列表',
        activeMenu: '/pp/order/service',
        roles: ['adidasrole012', 'adidasrole006']
      }
    }, {
      hidden: true,
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/PP/order/service/detail'));
        });
      },
      name: 'PPOrderDetail',
      meta: {
        title: '查看工单',
        activeMenu: '/pp/order/service',
        roles: ['adidasrole012', 'adidasrole006']
      }
    }]
  },
  // {
  //   path: 'order/:id',
  //   hidden:true,
  //   component: () => import('@/views/PP/order/detail'),
  //       name: 'PPOrderDetail',
  //       meta: {
  //         title: '查看工单',
  //         activeMenu: '/pp/order/event',
  //         roles:['adidasrole012']
  //       }
  // },
  {
    path: 'notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/Notice/index'));
      });
    },
    name: 'PPNotice',
    meta: {
      title: '任务下发',
      roles: ['adidasrole012']
    },
    redirect: '/PP/notice/index',
    children: [{
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/list'));
        });
      },
      hidden: true,
      name: 'PPNoticelist',
      meta: {
        title: '任务下发',
        parentPath: '/pp/notice',
        activeMenu: '/pp/notice',
        departmentID: 2,
        roles: ['adidasrole012']
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/add'));
        });
      },
      hidden: true,
      name: 'PPNoticeAdd',
      meta: {
        title: '创建任务',
        parentPath: '/pp/notice',
        activeMenu: '/pp/notice',
        departmentID: 2,
        roles: ['adidasrole012']
      }
    }, {
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/detail'));
        });
      },
      hidden: true,
      meta: {
        title: '通知详情',
        parentPath: '/pp/notice',
        activeMenu: '/pp/notice',
        departmentID: 2,
        roles: ['adidasrole012']
      }
    }]
  }]
}];
var _default = PPRouter;
exports.default = _default;