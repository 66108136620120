"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addClass = addClass;
exports.formatTime = formatTime;
exports.getPageSize = getPageSize;
exports.getPercent = getPercent;
exports.hasClass = hasClass;
exports.param2Obj = param2Obj;
exports.parseFormatNum = parseFormatNum;
exports.parseTime = parseTime;
exports.removeClass = removeClass;
exports.setPageSize = setPageSize;
exports.strTrim = strTrim;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {Number} val
 */
function setPageSize(val) {
  localStorage.setItem("pageSize", val);
}
function getPageSize() {
  return parseInt(localStorage.getItem("pageSize")) || 10;
}

/**
 * @param {Number} number
 * @param {Number} n
 */
function parseFormatNum(number, n) {
  if (!number) {
    return '￥0';
  }
  if (n != 0) {
    n = n > 0 && n <= 20 ? n : 2;
  }
  number = parseFloat((number + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  var sub_val = number.split(".")[0].split("").reverse();
  var sub_xs = number.split(".")[1];
  var show_html = "";
  for (var i = 0; i < sub_val.length; i++) {
    show_html += sub_val[i] + ((i + 1) % 3 == 0 && i + 1 != sub_val.length ? "," : "");
  }
  if (n == 0) {
    return '￥' + show_html.split("").reverse().join("");
  } else {
    return '￥' + show_html.split("").reverse().join("") + "." + sub_xs;
  }
}

/**
 * @param {String} str
 */
function strTrim(str) {
  //删除左右两端的空格
  return str.replace(/(^\s*)|(\s*$)/g, "");
}

/**
 * @param {Number} number1
 * @param {Number} number2
 * @param {Number} n
 */
function getPercent(number1, number2, n) {
  //删除左右两端的空格
  var percent = 0;
  if (n != 0) {
    n = n > 0 ? n : 2;
  }
  try {
    number1 && number2 && (percent = Number((number1 / number2 * 100).toFixed(n)) || 0);
  } catch (err) {
    percent = 0;
  }
  return percent;
}