"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _vuex = require("vuex");
var _OrderDetailMenu = _interopRequireDefault(require("@/views/components/OrderDetailMenu"));
var _OrderInfo = _interopRequireDefault(require("@/views/components/OrderInfo"));
var _RXOrderInfo = _interopRequireDefault(require("@/views/components/RXOrderInfo"));
var _OrderProcess = _interopRequireDefault(require("@/views/components/OrderProcess"));
var _OrderForm = _interopRequireDefault(require("@/views/components/OrderForm"));
var _order = require("@/api/order2");
var _ehs = require("@/api/ehs");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "OrderDetail",
  props: {
    orderId: {
      type: Number,
      default: null
    },
    routePath: {
      type: String,
      default: ""
    },
    departmentCode: {
      type: String,
      default: ""
    }
  },
  inject: ['$openMainLoading', '$closeMainLoading'],
  provide: function provide() {
    return {
      // getOrderBasicData:this.getOrderBasicData,
      updateDetailsPage: this.updateDetailsPage
    };
  },
  components: {
    OrderDetailMenu: _OrderDetailMenu.default,
    OrderInfo: _OrderInfo.default,
    OrderProcess: _OrderProcess.default,
    RXOrderInfo: _RXOrderInfo.default,
    OrderForm: _OrderForm.default
  },
  data: function data() {
    return {
      menuId: 1,
      orderStatus: -1,
      detailInfo: null,
      orderBasicData: {}
    };
  },
  computed: (0, _objectSpread2.default)({
    orderInfoType: function orderInfoType() {
      var type = 1;
      if (this.routePath == "rx" || this.routePath == "rxsupply") {
        type = 2;
      }
      return type;
    },
    showCloseBtn: function showCloseBtn() {
      if (["adidasDP002", "adidasDP003"].indexOf(this.orderBasicData.DepartmentCode) > -1 && this.$route.query.group == 2 && ["ehs", "pp"].indexOf(this.routePath) > -1 && this.nowRole.RoleName.toLowerCase().indexOf(this.routePath) > -1 && this.orderBasicData.ShowTempTypeSign != 2 && [90000, 100000].indexOf(this.orderBasicData.Status) == -1) {
        // ehs/pp两部门事件上报
        //  &&进行中
        //  && 路由包含 ehs或pp            
        //  && 有操作按钮
        // && 状态属于 90000或100000
        return true;
      } else {
        return false;
      }
    },
    showReturnBtn: function showReturnBtn() {
      if (this.nowRole.RoleName.toLowerCase().indexOf("con") > -1 && this.$route.query.group == 2 && [1, 4].indexOf(this.orderBasicData.ShowTempTypeSign) > -1) {
        //"adidasDP001" != this.orderBasicData.DepartmentCode &&
        //不属于工程范围的工单显示退单
        return true;
      } else {
        return false;
      }
    }
  }, (0, _vuex.mapGetters)(["nowRole"])),
  methods: {
    getData: function getData() {
      var _this = this;
      this.$openMainLoading();
      var request = this.orderInfoType == 2 ? _order.GetRxOrderDetail : _ehs.GetOrderDetailByID;
      request({
        ID: this.$props.orderId
      }).then(function (res) {
        _this.$closeMainLoading();
        _this.detailInfo = res.data;
        try {
          var _ref = _this.orderInfoType == 1 ? res.data.BDOrder : res.data.Detail,
            Status = _ref.Status,
            OrderNo = _ref.OrderNo;
          var FormType = "",
            ShowTempTypeSign = "",
            OldReplyID = "",
            ISCProductType = "",
            ExtraCharge = "";
          if (_this.orderInfoType == 2 && res.data.Detail.FormType) {
            FormType = res.data.Detail.FormType;
            ShowTempTypeSign = 2;
            OldReplyID = res.data.Detail.OldReplyID;
          }
          if (_this.orderInfoType == 1) {
            ShowTempTypeSign = res.data.ShowTempTypeSign;
            OldReplyID = res.data.OldReplyID;
            ISCProductType = res.data.BDOrder.ISCProductType;
            ExtraCharge = res.data.BDOrder.ExtraCharge;
            if (res.data.OrderCagtegoryOption && res.data.OrderCagtegoryOption.FormType) {
              FormType = res.data.OrderCagtegoryOption.FormType;
            }
          }
          _this.orderBasicData = {
            OrderNo: OrderNo,
            Status: Status,
            FormType: FormType,
            OrderID: _this.orderId,
            DepartmentCode: _this.departmentCode,
            RoutePath: _this.routePath,
            ShowTempTypeSign: ShowTempTypeSign,
            OldReplyID: OldReplyID,
            ISCProductType: ISCProductType,
            ExtraCharge: ExtraCharge,
            Origin: _this.orderInfoType == 1 ? res.data.BDOrder : res.data.Detail
          };
        } catch (err) {}
      }).catch(function () {
        _this.$closeMainLoading();
      });
    },
    changeMenu: function changeMenu() {
      if (this.menuId == 1) {
        this.getData();
      }
    },
    updateDetailsPage: function updateDetailsPage() {
      this.detailInfo = null;
      this.orderBasicData = {};
      this.orderStatus = -1;
      this.getData();
    },
    getOrderBasicData: function getOrderBasicData() {
      return this.orderBasicData;
    }
  },
  created: function created() {
    this.getData();
  }
};
exports.default = _default;