"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var CONRouter = [{
  path: '/con',
  name: 'CON',
  component: _layout.default,
  meta: {
    title: 'CON 工单管理',
    icon: 'order',
    roles: ['adidasrole010']
  },
  redirect: '/con/order',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/COND/index'));
      });
    },
    name: 'CON Dashboard',
    meta: {
      title: 'CON Dashboard',
      roles: ['adidasrole010']
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/COND/order/index'));
      });
    },
    name: 'CONOrder',
    redirect: '/con/order/index',
    meta: {
      title: '维修',
      roles: ['adidasrole010']
    },
    children: [{
      hidden: true,
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/COND/order/list'));
        });
      },
      name: 'CONOrderList',
      meta: {
        title: '工单列表',
        activeMenu: '/con/order',
        roles: ['adidasrole010']
      }
    }, {
      hidden: true,
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/COND/order/detail'));
        });
      },
      name: 'CONOrderDetail',
      meta: {
        title: '查看工单',
        activeMenu: '/con/order',
        roles: ['adidasrole010']
      }
    }]
  }, {
    path: 'notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/Notice/index'));
      });
    },
    name: 'CONNotice',
    meta: {
      title: '任务下发',
      roles: ['adidasrole010']
    },
    redirect: '/con/notice/index',
    children: [{
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/list'));
        });
      },
      hidden: true,
      name: 'CONNoticelist',
      meta: {
        title: '任务下发',
        parentPath: '/con/notice',
        activeMenu: '/con/notice',
        departmentID: 1,
        roles: ['adidasrole010']
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/add'));
        });
      },
      hidden: true,
      name: 'CONPNoticeAdd',
      meta: {
        title: '创建任务',
        parentPath: '/con/notice',
        activeMenu: '/con/notice',
        departmentID: 1,
        roles: ['adidasrole010']
      }
    }, {
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/detail'));
        });
      },
      hidden: true,
      meta: {
        title: '通知详情',
        parentPath: '/con/notice',
        activeMenu: '/con/notice',
        departmentID: 1,
        roles: ['adidasrole010']
      }
    }]
  }]
}];
var _default = CONRouter;
exports.default = _default;