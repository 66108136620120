var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "quickLinks" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _vm._l(_vm.quickLink, function (item, i) {
              return [
                item.meta.title !== "Dashboard"
                  ? _c(
                      "el-col",
                      { key: i, attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: item.path } },
                          [
                            _c("el-card", { staticClass: "box-card" }, [
                              _c("span", [_vm._v(_vm._s(item.meta.title))]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "topWrap" }, [
      _c("img", {
        staticClass: "pic-logo",
        attrs: { src: require("@/assets/logo.jpg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }