"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderFormConfig = require("./orderFormConfig");
//
//
//
//
//
//
var _default2 = {
  props: {
    orderBasicData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  provide: function provide() {
    return {
      orderBasicData: this.orderBasicData
    };
  },
  components: _orderFormConfig.OrderFormComponentlist,
  name: "OrderForm",
  data: function data() {
    return {};
  },
  methods: {},
  computed: {
    formComponentName: function formComponentName() {
      var _this = this;
      var name = null;
      if (_orderFormConfig.OrderFormConfig[this.orderBasicData.RoutePath]) {
        if (this.orderBasicData.ShowTempTypeSign == 2) {
          _orderFormConfig.OrderFormConfig[this.orderBasicData.RoutePath].some(function (item) {
            if (item.formType == _this.orderBasicData.FormType) {
              name = item.component;
              return true;
            }
          });
        } else if (this.orderBasicData.ShowTempTypeSign == 3) {
          name = 'Reminder';
        }
      }
      return name;
    }
  }
};
exports.default = _default2;