var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "main-wrap noticeDetail",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("div", { staticClass: "infoList" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("持续时间：")]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _vm._v(
                _vm._s(_vm.formatterDate(_vm.informData.Start)) +
                  "—" +
                  _vm._s(_vm.formatterDate(_vm.informData.End))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "infoList" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("任务内容：")]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.informData.Content)),
            ]),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.informData.Stores, function (item, k) {
              return _c(
                "div",
                { key: item.area },
                [
                  _c("h4", [_vm._v(_vm._s(k))]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    _vm._l(item, function (t) {
                      return _c(
                        "el-col",
                        { key: t.StoreCode, attrs: { span: 6 } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "storeItem",
                              attrs: { title: t.StoreCode + "-" + t.StoreName },
                            },
                            [
                              _c("i", {
                                staticClass: "dot",
                                class: { active: t.Status == 1 },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "text" }, [
                                _vm._v(
                                  _vm._s(t.StoreCode) +
                                    "-" +
                                    _vm._s(t.StoreName)
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-title" }, [
      _c("div", { staticClass: "title-name" }, [_vm._v("任务明细")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }