"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _report = require("@/api/report");
var _vueEcharts = _interopRequireDefault(require("vue-echarts"));
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/component/title");
var _vuex = require("vuex");
var _date = require("@/utils/date");
var _CircleProgress = _interopRequireDefault(require("@/components/CircleProgress"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  inject: ["$openMainLoading", "$closeMainLoading"],
  data: function data() {
    return {
      title: "EHS Dashboard",
      currentTime: "本月",
      monthRange: [],
      summaryData: {},
      summaryList: [],
      condition: {
        DepartmentID: 2,
        StartDateTime: "",
        EndDateTime: ""
      },
      orderCountByYearObj: {},
      shopEventChartObj: {},
      shopTypeChartObj: {},
      reportTypeChartObj: {},
      envtTypeChartObj: {},
      getPercent: _utils.getPercent
    };
  },
  components: {
    "v-chart": _vueEcharts.default,
    CircleProgress: _CircleProgress.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["chartColors"])),
  methods: {
    monthChange: function monthChange(val) {
      var start = (0, _date.GetMonthStartAndEnd)(val[0]);
      var end = (0, _date.GetMonthStartAndEnd)(val[1]);
      this.monthRange = [start[0], end[1]];
    },
    dateChange: function dateChange(val) {
      var range = [,];
      switch (val) {
        case "本月":
          range = (0, _date.CurrentMonthOfStartAndEnd)();
          break;
        case "本季":
          range = (0, _date.CurrentQuarterlyOfStartAndEnd)();
          break;
        case "本年":
          range = (0, _date.CurrentYearOfStartAndEnd)();
          break;
      }
      this.monthRange = (0, _toConsumableArray2.default)(range);
    },
    orderCountByYear: function orderCountByYear(data) {
      //各年工单数量
      var obj = {
        color: this.chartColors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999"
            }
          }
        },
        title: {
          text: "工单总数",
          left: "center",
          textStyle: {
            fontSize: 16
          },
          padding: [0, 0]
        },
        toolbox: {
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ["line", "bar"]
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        legend: {
          data: [],
          bottom: 0,
          type: "scroll"
        },
        grid: {
          top: 30,
          left: "0%",
          right: "0%",
          bottom: "5%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          data: [],
          axisPointer: {
            type: "shadow"
          }
        }],
        yAxis: [{
          type: "value",
          min: 0,
          interval: 50,
          axisLabel: {
            formatter: "{value}"
          }
        }],
        series: []
      };
      var legendData = [],
        //"月份列表"
        xseriesList = []; //年份列表
      if (data && data.length > 0) {
        data.forEach(function (val) {
          legendData.push(val.OrderCreateTimeMonth.split("-")[0]);
          xseriesList.push(val.OrderCreateTimeMonth.split("-")[1]);
        });
      }
      obj.legend.data = (0, _toConsumableArray2.default)(new Set(legendData)); //去重复
      obj.xAxis[0].data = (0, _toConsumableArray2.default)(new Set(xseriesList)); //去重复
      var seriesList = [];
      obj.legend.data.forEach(function (year) {
        var chartObj = {
          name: year,
          //年
          type: "bar",
          data: [obj.xAxis[0].data.length] //每月数据
        };

        data.forEach(function (item) {
          var cuurentYear = item.OrderCreateTimeMonth.split("-")[0];
          var currentMonth = item.OrderCreateTimeMonth.split("-")[1];
          if (cuurentYear == year) {
            chartObj.data.splice(currentMonth - 1, 1, item.TotalNum);
          }
        });
        seriesList.push(chartObj);
      });
      obj.series = seriesList;
      this.orderCountByYearObj = obj;
    },
    shopEventChart: function shopEventChart(data) {
      //店铺事件类型-线图
      var obj = {
        color: this.chartColors,
        title: {
          text: "店铺事件类型统计",
          left: "center"
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: [],
          bottom: 0,
          type: "scroll"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          axisLabel: {
            interval: 0,
            show: true,
            rotate: 60,
            //倾斜角度
            formatter: function formatter(value, index) {
              var str = value;
              if (str.length > 5) {
                str = str.substring(0, 5) + "...";
              }
              return str;
            }
          }
        },
        yAxis: {
          type: "value"
        },
        series: []
      };
      var legendData = [],
        //"店铺类型"
        xseriesList = []; //事件类型
      if (data && data.length > 0) {
        data.forEach(function (val) {
          legendData.push(val.ShopName);
          xseriesList.push(val.OptionObjectName);
        });
      }
      obj.legend.data = (0, _toConsumableArray2.default)(new Set(legendData)); //去重复
      obj.xAxis.data = (0, _toConsumableArray2.default)(new Set(xseriesList)); //去重复
      var seriesList = [];
      obj.legend.data.forEach(function (shopType) {
        var chartObj = {
          name: shopType,
          //店铺
          type: "line",
          data: [obj.xAxis.data.length] //事件
        };

        obj.xAxis.data.forEach(function (z) {
          data.forEach(function (item) {
            if (item.ShopName == shopType && z == item.OptionObjectName) {
              chartObj.data.splice(obj.xAxis.data.indexOf(item.OptionObjectName), 1, item.TotalNum);
            }
          });
        });
        seriesList.push(chartObj);
        console.log(seriesList);
      });
      obj.series = seriesList;
      this.shopEventChartObj = obj;
    },
    reportTypeChart: function reportTypeChart(data) {
      var obj = {
        color: this.chartColors,
        tooltip: {
          trigger: "item"
        },
        title: {
          text: "上报类型",
          left: "center",
          show: true
        },
        legend: {
          bottom: 0,
          left: "center",
          type: "scroll"
        },
        series: [{
          name: "上报类型",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2
          },
          label: {
            formatter: "{b}: {c}",
            show: false
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "10",
              fontWeight: "bold"
            }
          },
          labelLine: {
            show: true
          },
          data: []
        }]
      };
      var list = [];
      if (data && data.length > 0) {
        list = data.map(function (item) {
          return {
            value: item.TotalNum,
            name: item.OptionObjectName
          };
        });
      }
      obj.series[0].data = list;
      this.reportTypeChartObj = obj;
    },
    envtTypeChart: function envtTypeChart(data) {
      var obj = {
        color: this.chartColors,
        title: {
          text: "事件上报",
          left: "center",
          show: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },

        legend: {
          data: [],
          bottom: 0,
          type: "scroll"
        },
        grid: {
          left: "3%",
          right: "4%",
          // bottom: "10%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: []
        },
        yAxis: [{
          type: "value"
        }],
        series: []
      };
      var legendData = [],
        //"月份列表"
        xseriesList = []; //事件
      if (data && data.length > 0) {
        data.forEach(function (val) {
          legendData.push(val.OptionObjectName);
          xseriesList.push(val.OrderCreateTimeMonth);
        });
      }
      obj.legend.data = (0, _toConsumableArray2.default)(new Set(legendData)); //去重复
      obj.xAxis.data = (0, _toConsumableArray2.default)(new Set(xseriesList)); //去重复

      var seriesList = [];
      obj.legend.data.forEach(function (eventType) {
        var chartObj = {
          name: eventType,
          type: "bar",
          stack: "event",
          // label: { formatter: "{a}:{c}", show: true },
          data: [obj.xAxis.data.length]
        };
        obj.xAxis.data.forEach(function (z) {
          data.forEach(function (item) {
            if (item.OptionObjectName == eventType && z == item.OrderCreateTimeMonth) {
              chartObj.data.splice(obj.xAxis.data.indexOf(item.OrderCreateTimeMonth), 1, item.TotalNum);
            }
          });
        });
        seriesList.push(chartObj);
      });
      obj.series = seriesList;
      this.envtTypeChartObj = obj;
    },
    shopTypeChart: function shopTypeChart(data) {
      //店铺类型
      var obj = {
        color: this.chartColors,
        tooltip: {
          trigger: "item"
        },
        title: {
          text: "店铺类型",
          left: "center",
          show: true
        },
        legend: {
          bottom: 0,
          left: "center",
          type: "scroll"
        },
        series: [{
          name: "店铺类型",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2
          },
          label: {
            formatter: "{b}: {c}",
            show: true
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "14",
              fontWeight: "bold"
            }
          },
          labelLine: {
            show: true
          },
          data: []
        }]
      };
      var list = [];
      if (data && data.length > 0) {
        list = data.map(function (item) {
          return {
            value: item.TotalNum,
            name: item.ShopChildType
          };
        });
      }
      obj.series[0].data = list;
      this.shopTypeChartObj = obj;
    },
    getEHSOrderReportByParam: function getEHSOrderReportByParam(data) {
      var _this = this;
      console.log("condition:", data);
      this.$openMainLoading();
      (0, _report.GetEHSOrderReportByParam)(data).then(function (res) {
        _this.$closeMainLoading();
        var data = res.data;
        if (res.code !== 0) {
          return _this.$message.error("网络异常");
        } else {
          _this.summaryData = res.data;
          var polar = {
            color: _this.chartColors,
            tooltip: {
              trigger: "item",
              formatter: "{b}: {c}"
            },
            series: [{
              type: "pie",
              radius: ["50%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center"
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "14",
                  fontWeight: "bold"
                }
              },
              labelLine: {
                show: false
              },
              data: []
            }]
          };
          _this.summaryList = [];
          polar.series[0].data = [{
            value: data && data.TotalNum,
            name: "总工单"
          }, {
            value: data && data.CompleteNum,
            name: "完成工单"
          }];
          var c1 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c1));
          polar.series[0].data = [{
            value: data && data.TotalNum,
            name: "总工单"
          }, {
            value: data && data.TimeOutNum,
            name: "超时工单"
          }];
          var c2 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c2));
          _this.orderCountByYear(data && data.MonthReportList);
          _this.shopEventChart(data && data.ShopTypeOrderCategoryReportList); //店铺事件
          _this.envtTypeChart(data && data.MonthOrderCategoryReportList); //事件上报
          _this.reportTypeChart(data && data.OrderCategoryReportList); //上报类型
          _this.shopTypeChart(data && data.ShopTypeReportList); //店铺类型
        }
      }).catch(function (err) {
        _this.$closeMainLoading();
        console.log("接口问题", err);
      });
    },
    searchOrder: function searchOrder() {
      this.condition.StartDateTime = this.monthRange[0];
      this.condition.EndDateTime = this.monthRange[1];
      this.getEHSOrderReportByParam(this.condition);
    }
  },
  created: function created() {
    this.monthRange = (0, _toConsumableArray2.default)((0, _date.CurrentMonthOfStartAndEnd)());
    this.condition.StartDateTime = this.monthRange[0];
    this.condition.EndDateTime = this.monthRange[1];
    this.getEHSOrderReportByParam(this.condition);
  }
};
exports.default = _default;