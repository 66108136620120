"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Reminder',
  inject: ['$openMainLoading', '$closeMainLoading', 'updateDetailsPage', 'orderBasicData'],
  data: function data() {
    return {
      replyType: 1
    };
  },
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      this.$openMainLoading();
      (0, _order.SubmitOrderReminderReplyOption)({
        orderID: this.orderBasicData.OrderID
      }).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: '提交成功',
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    }
  }
};
exports.default = _default;