"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetMoreAttachUrl = GetMoreAttachUrl;
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request2"));
// 获取外网图片地址
function GetMoreAttachUrl(path) {
  return (0, _request.default)({
    url: "/api/UploadFile/AttachUrl?path=".concat(path),
    method: 'get'
  });
}