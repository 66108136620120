"use strict";

var _interopRequireWildcard = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var API = _interopRequireWildcard(require("@/api/notice"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      StartAndEnd: "",
      searchForm: {
        Start: "",
        End: "",
        //DepartmentID: 1,
        PageIndex: 1,
        PageSize: 10,
        OrderBy: "",
        OrderType: "" //asc,desc
      },

      tableData: [],
      tableDataTotal: 0
    };
  },
  inject: ["$openMainLoading", "$closeMainLoading"],
  methods: {
    handleSearch: function handleSearch() {
      this.searchForm.PageIndex = 1;
      this.getDataList();
    },
    getDataList: function getDataList() {
      var _this = this;
      this.$openMainLoading();
      API.GetNoticeList((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), {}, {
        DepartmentID: this.departmentID
      })).then(function (res) {
        _this.$closeMainLoading();
        if (res.code == 0) {
          _this.tableData = res.dataList;
          _this.tableDataTotal = res.Total;
        }
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    },
    handleExport: function handleExport() {
      var _this2 = this;
      this.$openMainLoading();
      API.ExportNotice((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), {}, {
        DepartmentID: this.departmentID
      })).then(function (res) {
        _this2.$closeMainLoading();
        var blob = new Blob([res.data], {
          type: res.headers["content-type"]
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = res.headers.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);
      }).catch(function (err) {
        _this2.$closeMainLoading();
      });
    },
    goToAdd: function goToAdd() {
      this.$router.push({
        path: this.$route.meta.parentPath + "/add"
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchForm.PageIndex = val;
      this.getDataList();
    },
    handleSortChange: function handleSortChange(data) {
      var column = data.column,
        prop = data.prop,
        order = data.order;
      !order && (prop = "");
      order = order == "ascending" ? "asc" : order == "descending" ? "desc" : "";
      this.searchForm.OrderBy = prop;
      this.searchForm.OrderType = order;
      this.handleSearch();
    },
    formatterDate: function formatterDate(row, column) {
      return row[column.property] ? (0, _moment.default)(row[column.property]).format("yyyy-MM-DD") : "";
    }
  },
  created: function created() {
    this.getDataList();
  },
  computed: {
    departmentID: function departmentID() {
      return this.$route.meta.departmentID;
    }
  }
};
exports.default = _default;