"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CurrentMonthOfStartAndEnd = CurrentMonthOfStartAndEnd;
exports.CurrentQuarterlyOfStartAndEnd = CurrentQuarterlyOfStartAndEnd;
exports.CurrentYearOfStartAndEnd = CurrentYearOfStartAndEnd;
exports.GetDate = GetDate;
exports.GetMonthStartAndEnd = GetMonthStartAndEnd;
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.to-string");
function GetDate() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var number = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var split = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '-';
  var nowdate = new Date(),
    retrundate = "";
  switch (type) {
    case "day":
      //取number天前、后的时间
      nowdate.setTime(nowdate.getTime() + 24 * 3600 * 1000 * number);
      var y = nowdate.getFullYear();
      var m = nowdate.getMonth() + 1;
      var d = nowdate.getDate();
      retrundate = y + split + m + split + d;
      break;
    case "week":
      //取number周前、后的时间
      var weekdate = new Date(nowdate + 7 * 24 * 3600 * 1000 * number);
      var y = weekdate.getFullYear();
      var m = weekdate.getMonth() + 1;
      var d = weekdate.getDate();
      retrundate = y + split + m + split + d;
      break;
    case "month":
      //取number月前、后的时间					 
      nowdate.setMonth(nowdate.getMonth() + number);
      var y = nowdate.getFullYear();
      var m = nowdate.getMonth() + 1;
      var d = nowdate.getDate();
      retrundate = y + split + m + split + d;
      break;
    case "year":
      //取number年前、后的时间
      nowdate.setFullYear(nowdate.getFullYear() + number);
      var y = nowdate.getFullYear();
      var m = nowdate.getMonth() + 1;
      var d = nowdate.getDate();
      retrundate = y + split + m + split + d;
      break;
    default:
      //取当前时间
      var y = nowdate.getFullYear();
      var m = nowdate.getMonth() + 1;
      var d = nowdate.getDate();
      retrundate = y + split + m + split + d;
  }
  return retrundate;
}
var format = 'YYYY-mm-dd HH:MM';
/*本月起止 */
function CurrentMonthOfStartAndEnd() {
  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  var firstDay = new Date(y, m, 1);
  var lastDay = new Date(y, m + 1, 0, 23, 59, 59);
  return [dateFormat(format, firstDay), dateFormat(format, lastDay)];
}
function GetMonthStartAndEnd(dt) {
  var date = new Date(dt),
    y = date.getFullYear(),
    m = date.getMonth();
  var firstDay = new Date(y, m, 1);
  var lastDay = new Date(y, m + 1, 0, 23, 59, 59);
  return [dateFormat(format, firstDay), dateFormat(format, lastDay)];
}
function CurrentQuarterlyOfStartAndEnd() {
  var list = getCurrentQuarterly();
  return [dateFormat(format, list[0]), dateFormat(format, list[1])];
}
function CurrentYearOfStartAndEnd() {
  var date = new Date(),
    y = date.getFullYear();
  var firstDay = new Date(y, 0, 1);
  var lastDay = new Date(y, 12, 0, 23, 59, 59);
  return [dateFormat(format, firstDay), dateFormat(format, lastDay)];
}
function dateFormat(fmt, date) {
  var ret;
  var opt = {
    "Y+": date.getFullYear().toString(),
    // 年
    "m+": (date.getMonth() + 1).toString(),
    // 月
    "d+": date.getDate().toString(),
    // 日
    "H+": date.getHours().toString(),
    // 时
    "M+": date.getMinutes().toString(),
    // 分
    "S+": date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };

  for (var k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
    }
    ;
  }
  ;
  return fmt;
}
var getCurrentQuarterly = function getCurrentQuarterly() {
  //起止日期数组 
  var startStop = new Array();
  //获取当前时间 
  var currentDate = new Date();
  //获得当前月份0-11 
  var currentMonth = currentDate.getMonth();
  //获得当前年份4位年 
  var currentYear = currentDate.getFullYear();
  //获得本季度开始月份 
  var quarterSeasonStartMonth = getQuarterSeasonStartMonth(currentMonth);
  //获得本季度结束月份 
  var quarterSeasonEndMonth = quarterSeasonStartMonth + 2;

  //获得本季度开始的日期 
  var quarterSeasonStartDate = new Date(currentYear, quarterSeasonStartMonth, 1);
  //获得本季度结束的日期 
  var quarterSeasonEndDate = new Date(currentYear, quarterSeasonEndMonth + 1, 0, 23, 59, 59);

  //返回 
  return [quarterSeasonStartDate, quarterSeasonEndDate];
};
var getQuarterSeasonStartMonth = function getQuarterSeasonStartMonth(month) {
  var spring = 0; //春 
  var summer = 3; //夏 
  var fall = 6; //秋 
  var winter = 9; //冬 
  //月份从0-11 
  if (month < 3) {
    return spring;
  }
  if (month < 6) {
    return summer;
  }
  if (month < 9) {
    return fall;
  }
  return winter;
};