"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.split");
var _QuarterPicker = _interopRequireDefault(require("@/components/QuarterPicker"));
var _index = _interopRequireDefault(require("@/views/components/ShopPopup/index"));
var _dayjs = _interopRequireDefault(require("dayjs"));
var _order = require("@/api/order2");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { EstimatedValueDownload } from "@/api/Material";
var _default = {
  data: function data() {
    var _this2 = this;
    return {
      perMonthData: {
        SuccessCount: 0,
        // 成功匹配数量
        FailCount: 0,
        submissionTime: [],
        name: "",
        title: "",
        smallTicketData: [],
        smallTicketFileName: "",
        tableData: [],
        OrderYearAndMonth: (0, _dayjs.default)().format("YYYY-MM"),
        OrderOperateMonth: ""
      },
      form: {
        // 小票预估值
        CategoryCode: "",
        CategoryName: "",
        ShoppingBagId: "",
        CategoryCodeId: "",
        //物料类型
        perMonthData: [],
        returnShopId: [] // 返回店铺的 id 数组
      },

      PredictNum: 0,
      // 小票数据
      numberOfChoices: "0",
      loading: false,
      //  暂时
      Rx: "PurchaseProduct",
      // 物料类型
      materialTypes: [],
      dialogVisible: false,
      EstimatedValueImport: _order.EstimatedValueImport,
      // 展现 列表匹配数量

      dateType: "month",
      //时间类型
      month: (0, _dayjs.default)().format("YYYY-MM"),
      quarter: 1,
      halfYear: (0, _dayjs.default)().format("YYYY-MM"),
      year: (0, _dayjs.default)().format("YYYY"),
      dateList: "",
      monthCount: "",
      dateTypeList: [{
        dateType: "month",
        valueFormat: "yyyy-MM",
        monthCount: 1,
        label: "月度"
      }, {
        dateType: "quarter",
        valueFormat: "yyyy",
        monthCount: 3,
        label: "季度"
      }, {
        dateType: "halfYear",
        valueFormat: "YYYY",
        monthCount: 6,
        label: "半年度"
      }, {
        dateType: "year",
        valueFormat: "YYYY",
        monthCount: 12,
        label: "全年度"
      }],
      activeTabIndex: "",
      errorTipInstance: null,
      errorTabIndex: -1,
      expireTimeOption: {
        disabledDate: function disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      rules: {
        CategoryCodeId: [{
          required: true,
          message: "请选择物料类型",
          trigger: ["change"]
        }],
        smallTicketData: [{
          type: "array",
          required: false,
          message: "请上传小票预估值",
          trigger: ["change", "blur"],
          validator: function validator(rule, value, callback) {
            if (value && value.length) {
              _this2.errorTabIndex = -1;
              callback();
            } else {
              var tabIndex = rule.field && rule.field.split('.')[1];
              var record = _this2.form.perMonthData.find(function (v) {
                return v.name == tabIndex;
              });
              callback(new Error("请上传小票预估值"));
              if (!_this2.errorTipInstance) {
                _this2.errorTabIndex = tabIndex;
                _this2.errorTipInstance = _this2.$message({
                  message: "\u8BF7\u4E0A\u4F20\u9009\u9879\u5361 '".concat(record && record.title, "' \u7684\u5C0F\u7968\u9884\u4F30\u503C"),
                  type: 'error',
                  duration: 1500,
                  onClose: function onClose() {
                    _this2.errorTipInstance = null;
                  }
                });
              }
            }
          }
        }],
        returnShopId: [{
          required: true,
          message: "请选择店铺",
          trigger: ["change"]
        }],
        submissionTime: [{
          type: "array",
          required: true,
          message: "请选择店铺提交时间",
          trigger: ["change", "blur"],
          validator: function validator(rule, value, callback) {
            if (value && value.length) {
              _this2.errorTabIndex = -1;
              callback();
            } else {
              var tabIndex = rule.field && rule.field.split('.')[1];
              var record = _this2.form.perMonthData.find(function (v) {
                return v.name == tabIndex;
              });
              callback(new Error("请选择店铺提交时间"));
              if (!_this2.errorTipInstance) {
                _this2.errorTabIndex = tabIndex;
                _this2.errorTipInstance = _this2.$message({
                  message: "\u8BF7\u9009\u62E9\u9009\u9879\u5361 '".concat(record && record.title, "' \u7684\u5E97\u94FA\u63D0\u4EA4\u65F6\u95F4"),
                  type: 'error',
                  duration: 1500,
                  onClose: function onClose() {
                    _this2.errorTipInstance = null;
                  }
                });
              }
            }
          }
        }],
        orderYearAndMonth: [{
          required: true,
          message: "请选择工单所属月",
          trigger: ["change", "blur"],
          validator: function validator(rule, value, callback) {
            if (value) {
              _this2.errorTabIndex = -1;
              callback();
            } else {
              var tabIndex = rule.field && rule.field.split('.')[1];
              var record = _this2.form.perMonthData.find(function (v) {
                return v.name == tabIndex;
              });
              callback(new Error("请选择工单所属月"));
              if (!_this2.errorTipInstance) {
                _this2.errorTabIndex = tabIndex;
                _this2.errorTipInstance = _this2.$message({
                  message: "\u8BF7\u9009\u62E9\u9009\u9879\u5361 '".concat(record && record.title, "' \u7684\u5DE5\u5355\u6240\u5C5E\u6708"),
                  type: 'error',
                  duration: 1500,
                  onClose: function onClose() {
                    _this2.errorTipInstance = null;
                  }
                });
              }
            }
          }
        }],
        orderOperateMonth: [{
          required: true,
          message: "请选择供应商发货月",
          trigger: ["change", "blur"],
          validator: function validator(rule, value, callback) {
            if (value) {
              _this2.errorTabIndex = -1;
              callback();
            } else {
              var tabIndex = rule.field && rule.field.split('.')[1];
              var record = _this2.form.perMonthData.find(function (v) {
                return v.name == tabIndex;
              });
              callback(new Error("请选择供应商发货月"));
              if (!_this2.errorTipInstance) {
                _this2.errorTabIndex = tabIndex;
                _this2.errorTipInstance = _this2.$message({
                  message: "\u8BF7\u9009\u62E9\u9009\u9879\u5361 '".concat(record && record.title, "' \u7684\u4F9B\u5E94\u5546\u53D1\u8D27\u6708"),
                  type: 'error',
                  duration: 1500,
                  onClose: function onClose() {
                    _this2.errorTipInstance = null;
                  }
                });
              }
            }
          }
        }]
      }
    };
  },
  methods: {
    onSubmitionDateChange: function onSubmitionDateChange(val) {
      var _this3 = this;
      if (val) {
        this.form.perMonthData.forEach(function (v) {
          v.submissionTime = val;
          if (_this3.dateType == "month") {
            //v.OrderOperateMonth = dayjs(val[0]).format("YYYY-MM");
            v.OrderYearAndMonth = (0, _dayjs.default)(val[0]).format("YYYY-MM");
          }
        });
      }
    },
    onQuarterChange: function onQuarterChange(val) {
      if (val) this.setTabs();
    },
    onDateRangeChange: function onDateRangeChange(val) {
      if (val) this.setTabs();
    },
    onDateTypeChange: function onDateTypeChange(val) {
      if (val) this.setTabs();
    },
    onTabClick: function onTabClick() {},
    setTabs: function setTabs() {
      var _this4 = this;
      var record = this.dateTypeList.find(function (v) {
        return v.dateType == _this4.dateType;
      });
      var startMonth = "";
      if (this.dateType == "month") {
        startMonth = this.month;
      } else if (this.dateType == "quarter") {
        var month = "";
        switch (this.quarter) {
          case 1:
            month = (0, _dayjs.default)(this.year).month(0);
            break;
          case 2:
            month = (0, _dayjs.default)(this.year).month(3);
            break;
          case 3:
            month = (0, _dayjs.default)(this.year).month(6);
            break;
          case 4:
            month = (0, _dayjs.default)(this.year).month(9);
            break;
        }
        startMonth = month;
      } else if (this.dateType == "halfYear") {
        startMonth = (0, _dayjs.default)(this.halfYear);
      } else if (this.dateType == "year") {
        startMonth = this.year;
      }
      var monthCount = record && record.monthCount;
      this.form.perMonthData = [];
      for (var i = 0; i < monthCount; i++) {
        var monthStr = (0, _dayjs.default)(startMonth).add(i, "month").format("YYYY-MM");
        if (i == 0) {
          this.activeTabIndex = i + "";
        }
        this.form.perMonthData.push((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.perMonthData), {}, {
          OrderYearAndMonth: monthStr,
          OrderOperateMonth: "",
          name: i + "",
          title: monthStr
        }));
      }
      if (this.form.CategoryCodeId) {
        this.handleChangeMaterialTypes();
      }
    },
    returns: function returns() {
      this.$router.go(-1);
    },
    handleChangeMaterialTypes: function handleChangeMaterialTypes() {
      var _this5 = this;
      this.materialTypes.some(function (item) {
        if (item.ID == _this5.form.CategoryCodeId) {
          _this5.form.CategoryCode = item.CategoryCode;
          _this5.form.CategoryName = item.Categoryname;
          return true;
        }
      });
      var type = 0;
      if (this.form.perMonthData[this.activeTabIndex].smallTicketFileName && this.isShoppingBag) {
        type = 1;
      }
      this.handleChangeSeletForm(type);
    },
    handleChangeSeletForm: function handleChangeSeletForm(type) {
      if (!this.form.perMonthData[this.activeTabIndex].smallTicketFileName && this.isShoppingBag) {
        console.log("clear");
        this.form.perMonthData.forEach(function (v) {
          v.tableData = [];
        });
        return false;
      }
      if (!this.form.returnShopId || !this.form.returnShopId.length) {
        return false;
      }
      if (!this.form.CategoryCodeId) {
        return false;
      }
      this.listRendering(type);
    },
    successs: function successs(e, file) {
      if (e.code == 0) {
        var fileName = file.name;
        this.form.perMonthData[this.activeTabIndex].smallTicketFileName = fileName;
        this.form.perMonthData[this.activeTabIndex].smallTicketData = e.data;
        this.handleChangeSeletForm(1);
      } else {
        this.form.perMonthData[this.activeTabIndex].smallTicketFileName = "";
        this.$message.error(e.msg || "上传失败");
      }
    },
    errors: function errors() {
      this.form.perMonthData[this.activeTabIndex].smallTicketFileName = "";
      this.$message.error("上传失败");
    },
    templateDownload: function templateDownload() {
      window.open(_order.EstimatedValueDownload);
    },
    // 选择店铺
    choiceShop: function choiceShop() {
      console.log("选择店铺");
      this.$refs["shopPopup"].dialogVisibles();
    },
    // 物料类型
    materialType: function materialType() {
      var _this6 = this;
      // 选定 rx 店铺
      console.log("物料类型");
      var _this = this;
      this.loading = true;
      var postdata = {
        categoryDescription: this.Rx
      };
      (0, _order.GetProductList)(postdata).then(function (res) {
        _this6.loading = false;
        console.log("物料类型获取成功", res.dataList);
        if (!res.dataList) return false;
        _this.materialTypes = res.dataList.filter(function (t) {
          return t.ParentID == 0;
        });
        _this.materialTypes && _this.materialTypes.some(function (item) {
          if (item.CategoryCode == "ShoppingBag") {
            _this6.ShoppingBagId = item.ID;
            return true;
          }
        });
      }).catch(function (err) {
        _this6.loading = false;
        console.log("物料类型获取失败", err);
      });
    },
    // 列表渲染
    listRendering: function listRendering(type) {
      var _this7 = this;
      this.loading = true;
      var params = {
        CategoryID: this.form.CategoryCodeId,
        CategoryName: this.form.CategoryName,
        CategoryCode: this.form.CategoryCode,
        ShopIDs: this.form.returnShopId,
        PurchaseModels: this.form.perMonthData[this.activeTabIndex].smallTicketData
      };
      (0, _order.PurchaseSelect)(params).then(function (res) {
        _this7.loading = false;
        if (res.code !== 0) return false;
        if (type) {
          //type=1上传小票
          var data = res.data || {};
          _this7.form.perMonthData[_this7.activeTabIndex].tableData = (0, _toConsumableArray2.default)(data.PurchaseModels);
          // 未匹配 数量
          _this7.form.perMonthData[_this7.activeTabIndex].FailCount = data.OperateResultModel.FailCount;
          // 成功匹配数量
          _this7.form.perMonthData[_this7.activeTabIndex].SuccessCount = data.OperateResultModel.SuccessCount;
        } else {
          //非上传小票
          _this7.form.perMonthData.forEach(function (v) {
            var data = res.data.PurchaseModels.map(function (z) {
              return (0, _objectSpread2.default)({}, z);
            });
            v.tableData = data;
            v.FailCount = res.data.OperateResultModel && res.data.OperateResultModel.FailCount;
            v.SuccessCount = res.data.OperateResultModel && res.data.OperateResultModel.SuccessCount;
          });
        }
      }).catch(function () {
        _this7.loading = false;
        console.log("列表渲染失败");
      });
    },
    // 提交工单
    workOrderSubmission: function workOrderSubmission() {
      var _this8 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (!_this8.form.CategoryCodeId) {
            _this8.$message.warning("请选择物料类型");
            return false;
          }
          if (!_this8.form.returnShopId.length) {
            _this8.$message.warning("请选择门店");
            return false;
          }
          // if (
          //   (!this.smallTicketData || !this.smallTicketData.length) &&
          //   this.isShoppingBag
          // ) {
          //   this.$message.warning("请上传小票预估值");
          //   return false;
          // }
          if (_this8.form.perMonthData.some(function (v) {
            return v.submissionTime.length[0] == "";
          })) {
            _this8.$message.warning("请选择提交时间");
            return false;
          }
          if (_this8.form.perMonthData.some(function (v) {
            return v.tableData.length == 0;
          })) {
            _this8.$message.warning("当前状态下没有数据");
            return false;
          }
          var listData = [];
          //  console.log(listData)
          var modelList = (_this8.form.perMonthData || []).map(function (v) {
            return {
              ProductCategoryID: _this8.form.CategoryCodeId,
              ProductCategoryName: _this8.form.CategoryName,
              ProductCategoryCode: _this8.form.CategoryCode,
              BeginTime: v.submissionTime[0],
              EndTime: v.submissionTime[1],
              OrderYearAndMonth: v.OrderYearAndMonth,
              OrderOperateMonth: v.OrderOperateMonth,
              PurchaseModels: v.tableData
            };
          });
          console.log(modelList);
          _this8.loading = true;
          (0, _order.MoreMonthPurchaseSubmit)({
            purchaseSubmitModelList: modelList
          }).then(function (res) {
            _this8.loading = false;
            if (res.code !== 0) return false;
            _this8.$message({
              message: "工单创建成功",
              type: "success"
            });
            _this8.returns();
          }).catch(function () {
            _this8.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 小票值判断
    valueJudgment: function valueJudgment(val, index) {
      if (val == "") {
        this.form.perMonthData[this.activeTabIndex].tableData[index].PredictNum = 0;
      } else {
        this.form.perMonthData[this.activeTabIndex].tableData[index].PredictNum = Math.abs(this.form.perMonthData[this.activeTabIndex].tableData[index].PredictNum) || 0;
      }
    },
    addstring: function addstring(e) {
      console.log("addstring", e);
    },
    handleChangeShop: function handleChangeShop(_ref) {
      var value = _ref.value,
        totle = _ref.totle;
      if (value) {
        this.numberOfChoices = "".concat(value.length, "/").concat(totle);
        this.handleChangeSeletForm();
      }
    }
  },
  // 生命周期
  created: function created() {
    this.materialType();
    this.activeTabIndex = "0";
    this.perMonthData.title = (0, _dayjs.default)().format("YYYY-MM");
    this.perMonthData.name = "0";
    this.form.perMonthData.push((0, _objectSpread2.default)({}, this.perMonthData));
  },
  components: {
    shopPopup: _index.default,
    QuarterPicker: _QuarterPicker.default
  },
  computed: {
    isShoppingBag: function isShoppingBag() {
      var flag = this.form.CategoryCodeId && this.form.CategoryCodeId == this.ShoppingBagId;
      if (flag) {
        this.rules.smallTicketData[0].required = true;
      } else {
        this.rules.smallTicketData[0].required = false;
      }
      return flag;
    }
  }
};
exports.default = _default;