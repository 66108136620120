"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _report = require("@/api/report");
var _vuex = require("vuex");
var _vueEcharts = _interopRequireDefault(require("vue-echarts"));
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/component/title");
var _date = require("@/utils/date");
var _CircleProgress = _interopRequireDefault(require("@/components/CircleProgress"));
var _utils = require("@/utils");
var _Shop = require("@/api/Shop");
var _ehs = require("@/api/ehs");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  inject: ["$openMainLoading", "$closeMainLoading"],
  data: function data() {
    return {
      title: "CON Dashboard",
      loading: false,
      currentTime: "本月",
      monthRange: [],
      optionObjectIDList: [],
      summaryData: {},
      summaryList: [],
      condition: {
        DepartmentID: 1,
        StartDateTime: "",
        EndDateTime: "",
        ShopType: "",
        ShopArea: "",
        ProductTypeIDsString: ""
      },
      supplierChartObj: {},
      repairItemChartObj: {},
      storeOrderCategoryChartObj: {},
      repairCategoryChartObj: {},
      position: "",
      positionList: [],
      storeList: [],
      orderTypeList: [],
      storeAreaList: [],
      getPercent: _utils.getPercent
    };
  },
  components: {
    "v-chart": _vueEcharts.default,
    CircleProgress: _CircleProgress.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["chartColors"])),
  methods: {
    monthChange: function monthChange(val) {
      var start = (0, _date.GetMonthStartAndEnd)(val[0]);
      var end = (0, _date.GetMonthStartAndEnd)(val[1]);
      this.monthRange = [start[0], end[1]];
    },
    dateChange: function dateChange(val) {
      var range = [,];
      switch (val) {
        case "本月":
          range = (0, _date.CurrentMonthOfStartAndEnd)();
          break;
        case "本季":
          range = (0, _date.CurrentQuarterlyOfStartAndEnd)();
          break;
        case "本年":
          range = (0, _date.CurrentYearOfStartAndEnd)();
          break;
      }
      this.monthRange = (0, _toConsumableArray2.default)(range);
    },
    getConOrderReportByParam: function getConOrderReportByParam(data) {
      var _this = this;
      this.$openMainLoading();
      (0, _report.GetConOrderReportByParam)(data).then(function (res) {
        _this.$closeMainLoading();
        var data = res.data;
        if (res.code !== 0) {
          return _this.$message.error("网络异常");
        } else {
          _this.summaryData = res.data;
          var polar = {
            color: _this.chartColors,
            tooltip: {
              trigger: "item",
              formatter: "{b}: {c}"
            },
            series: [{
              type: "pie",
              radius: ["50%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center"
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "14",
                  fontWeight: "bold"
                }
              },
              labelLine: {
                show: false
              },
              data: []
            }]
          };
          _this.summaryList = [];
          polar.series[0].data = [{
            value: data && data.TotalNum,
            name: "总工单"
          }, {
            value: data && data.CompleteNum,
            name: "完成工单"
          }];
          var c1 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c1));
          polar.series[0].data = [{
            value: data && data.TotalNum,
            name: "总工单"
          }, {
            value: data && data.TimeOutNum,
            name: "超时工单"
          }];
          var c2 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c2));
          _this.topSupplierChart(data && data.SupplierReportList);
          _this.topRepairItemChart(data && data.TopOrderCategoryReportList);
          _this.storeOrderCategoryChart(data && data.ShopTypeReportList);
          _this.repairCategoryChart(data && data.OrderCategoryReportList);
          _this.getPositionList(data && data.OrderCategoryReportList);
        }
      }).catch(function (err) {
        _this.$closeMainLoading();
        console.log("接口问题", err);
      });
    },
    positionChane: function positionChane(val) {
      var list = [];
      if (val == -1) {
        list = this.summaryData.OrderCategoryReportList;
      } else {
        this.summaryData.OrderCategoryReportList.forEach(function (item) {
          if (item.ProcessPosition == val) {
            list.push(item);
          }
        });
      }
      this.repairCategoryChart(list);
    },
    getPositionList: function getPositionList(data) {
      var list = [{
          label: "全部",
          value: -1
        }],
        pList = [];
      if (data && data.length > 0) {
        data.forEach(function (val) {
          if (pList.indexOf(val.ProcessPosition) == -1) {
            pList.push(val.ProcessPosition);
            list.push({
              label: val.ProcessPositionName,
              value: val.ProcessPosition
            });
          }
        });
      }
      this.positionList = list;
    },
    topSupplierChart: function topSupplierChart(data) {
      var obj = {
        color: this.chartColors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "0%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          axisLabel: {
            textStyle: {
              color: "#000000",
              fontSize: 10
            },
            interval: 0,
            formatter: function formatter(value, index) {
              var str = value;
              if (str.length > 5) {
                str = str.substring(0, 5) + "...";
              }
              return str;
            }
          },
          data: []
        },
        series: []
      };
      var supplierList = [],
        yData = [];
      if (data && data.length > 0) {
        data.forEach(function (val) {
          supplierList.push(val.SupplierName);
          yData.push(val.TotalNum);
        });
      }
      var chartObj = {
        name: "供应商Top5",
        type: "bar",
        stack: "supplier",
        data: yData.reverse(),
        label: {
          show: true
        }
      };
      obj.yAxis.data = supplierList.reverse();
      obj.series = chartObj;
      this.supplierChartObj = obj;
    },
    topRepairItemChart: function topRepairItemChart(data) {
      var obj = {
        color: this.chartColors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "0%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          axisLabel: {
            textStyle: {
              color: "#000000",
              fontSize: 10
            },
            interval: 0,
            formatter: function formatter(value, index) {
              var str = value;
              if (str.length > 5) {
                str = str.substring(0, 5) + "...";
              }
              return str;
            }
          },
          data: []
        },
        series: [{
          name: "",
          type: "bar",
          data: []
        }]
      };
      var supplierList = [],
        yData = [];
      if (data && data.length > 0) {
        data.forEach(function (val) {
          supplierList.push(val.ProcessPositionName + "/" + val.OptionObjectName);
          yData.push(val.TotalNum);
        });
      }
      var chartObj = {
        name: "返修率Top5",
        type: "bar",
        stack: "supplier",
        data: yData.reverse(),
        barWidth: 10,
        label: {
          show: true
        }
      };
      obj.yAxis.data = supplierList.reverse();
      obj.series = chartObj;
      this.repairItemChartObj = obj;
    },
    storeOrderCategoryChart: function storeOrderCategoryChart(data) {
      var obj = {
        color: this.chartColors,
        tooltip: {
          trigger: "item"
        },
        title: {
          text: "店铺类型工单统计",
          left: "center",
          show: true
        },
        legend: {
          bottom: 0,
          left: "center",
          data: []
        },
        series: [{
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2
          },
          label: {
            formatter: "{b}: {c}",
            show: true
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "14",
              fontWeight: "bold"
            }
          },
          labelLine: {
            show: true
          },
          data: []
        }]
      };
      var list = [];
      if (data && data.length > 0) {
        data.forEach(function (item) {
          list.push({
            value: item.TotalNum,
            name: item.ShopChildType
          });
          obj.legend.data.push(item.ShopChildType);
        });
      }
      obj.legend.data = (0, _toConsumableArray2.default)(new Set(obj.legend.data));
      obj.series[0].data = list;
      this.storeOrderCategoryChartObj = obj;
    },
    repairCategoryChart: function repairCategoryChart(data) {
      var obj = {
        color: this.chartColors,
        title: {
          text: "维修项目细分统计",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
          axisLabel: {
            // interval: 0,
            rotate: 60,
            formatter: function formatter(value, index) {
              var str = value;
              if (str.length > 4) {
                str = str.substring(0, 4) + "...";
              }
              return str;
            }
          }
        },
        yAxis: {
          type: "value"
        },
        series: {
          data: [],
          type: "bar",
          barGap: "100%",
          barWidth: 10
          //  label: {
          //   show: true
          // }
        }
      };

      var xseriesList = [],
        seriesData = [];
      if (data && data.length > 0) {
        data.forEach(function (val) {
          xseriesList.push(val.OptionObjectName);
          seriesData.push(val.TotalNum);
        });
      }
      obj.xAxis.data = xseriesList;
      obj.series.data = seriesData;
      this.repairCategoryChartObj = obj;
    },
    search: function search() {
      this.condition.StartDateTime = this.monthRange[0];
      this.condition.EndDateTime = this.monthRange[1];
      var postData = (0, _objectSpread2.default)({}, this.condition);
      var productTypeIDsString = "";
      if (this.optionObjectIDList && this.optionObjectIDList.length) {
        productTypeIDsString = this.optionObjectIDList.map(function (item) {
          return item[item.length - 1];
        }).toString();
      } else {
        productTypeIDsString = "";
      }
      postData.ProductTypeIDsString = productTypeIDsString;
      this.getConOrderReportByParam(postData);
    },
    getStoreAreaList: function getStoreAreaList() {
      var _this2 = this;
      (0, _Shop.GetStoreAreaList)().then(function (res) {
        _this2.storeAreaList = res.data || [];
      }).catch(function (err) {});
    },
    getStoreTypeList: function getStoreTypeList() {
      var _this3 = this;
      (0, _Shop.GetStoreTypeList)().then(function (res) {
        _this3.storeList = res.data || [];
      }).catch(function (err) {});
    },
    getOrderType: function getOrderType() {
      var _this4 = this;
      var postData = {
        DepartmentCode: "adidasDP001",
        CategoryType: "Repair"
      };
      (0, _ehs.GetEHSOrderType)(postData).then(function (res) {
        _this4.orderTypeList = res.data;
      });
    }
  },
  created: function created() {
    this.monthRange = (0, _toConsumableArray2.default)((0, _date.CurrentMonthOfStartAndEnd)());
    this.condition.StartDateTime = this.monthRange[0];
    this.condition.EndDateTime = this.monthRange[1];
    this.getConOrderReportByParam(this.condition);
    this.getStoreAreaList();
    this.getStoreTypeList();
    this.getOrderType();
  }
};
exports.default = _default;