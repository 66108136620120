var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "createWorkOrder main-wrap",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "hide-required-asterisk": true,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-form-item", { attrs: { label: "选择店铺：" } }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "info", size: "small" },
                                  on: { click: _vm.choiceShop },
                                },
                                [_vm._v("选择店铺")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    "font-size": "14px",
                                    color: "#606266",
                                    "font-weight": "700",
                                  },
                                },
                                [
                                  _vm._v(
                                    "已选择 " + _vm._s(_vm.numberOfChoices)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "箱数：" } },
                        [
                          _c("el-input", {
                            attrs: { type: "number", placeholder: "请填箱数" },
                            model: {
                              value: _vm.form.ProductBoxNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "ProductBoxNum", $$v)
                              },
                              expression: "form.ProductBoxNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "运单号：" } }, [
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                placeholder: "请填写运单号",
                              },
                              on: { input: _vm.shipmentCodeInput },
                              model: {
                                value: _vm.form.ShipmentCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ShipmentCode", $$v)
                                },
                                expression: "form.ShipmentCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "收货日期：" } }, [
                        _c(
                          "div",
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: { type: "date", placeholder: "收货日期" },
                              model: {
                                value: _vm.form.EndTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "EndTime", $$v)
                                },
                                expression: "form.EndTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "说明：" } }, [
                _c(
                  "div",
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 5, maxRows: 14 },
                        placeholder: "请填写说明",
                      },
                      model: {
                        value: _vm.form.OrderDescription,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "OrderDescription", $$v)
                        },
                        expression: "form.OrderDescription",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "forms" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.form.tableData,
                    "min-height": "320",
                    "max-height": "600",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "CNCode", width: "160", label: "CNCode" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "STCode", width: "160", label: "STCode" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ShopName",
                      width: "300",
                      label: "店铺名称",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ProductCategoryName",
                      width: "auto",
                      label: "类型",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buts" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.workOrderSubmission },
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.returns } }, [_vm._v("返回")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("shopPopup", {
        ref: "shopPopup",
        on: { change: _vm.handleChangeShop },
        model: {
          value: _vm.returnShopId,
          callback: function ($$v) {
            _vm.returnShopId = $$v
          },
          expression: "returnShopId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-title" }, [
      _c("div", { staticClass: "title-name" }, [_vm._v("创建工单")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }