"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _order = require("@/api/order");
var _orderFormConfig = require("@/views/components/OrderForm/orderFormConfig");
var _notice = require("@/api/notice");
var _construction = require("@/api/construction");
var _PhotoAndVideoUpload = _interopRequireDefault(require("@/views/components/PhotoAndVideoUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "PPRepairSupplierCompleted",
  data: function data() {
    return {
      replyTypeList: [{
        label: "完成",
        value: 6080
      }, {
        label: "退单",
        value: 6030
      }, {
        label: "额外费用",
        value: 6041
      }],
      replyType: 6080,
      replyContent: "",
      fileList: {
        Pics: [],
        Atts: []
      },
      extraCharges: null,
      replyCategroyDateTime: "",
      returnCauseList: _orderFormConfig.BackAndRejectCause.PPSupply_back,
      returnCauseValue: "",
      returnRepairValue: "",
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      UpLoadFilesAPI: _notice.UpLoadFilesAPI
    };
  },
  components: {
    PhotoAndVideoUpload: _PhotoAndVideoUpload.default
  },
  inject: ["$openMainLoading", "$closeMainLoading", "updateDetailsPage", "orderBasicData"],
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      if (this.replyType == 6030 && !this.returnCauseValue) {
        //退单
        this.$message.warning("请选择退单原因");
        return false;
      }
      if (!this.replyContent && this.replyType !== 6080) {
        this.$message.warning("请输入描述信息");
        return false;
      }
      if (this.replyType == 6041 && !this.extraCharges) {
        this.$message.warning("请输入额外费用");
        return false;
      }
      if (this.replyType == 6080 && !this.fileList.Pics.length) {
        this.$message.warning("请上传图片信息");
        return false;
      }
      var postData = {
        NextOrderStatus: this.replyType,
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderNo,
          ReplyReasonName: this.replyType == 6030 ? this.returnCauseValue : "",
          ReplyContent: this.replyContent,
          ReplyCategoryName: this.getReplyCategoryName(),
          ReplyCategroyDateTime: this.replyType == 6041 ? this.replyCategroyDateTime : ""
        },
        attachmentList: [].concat((0, _toConsumableArray2.default)(this.fileList.Pics), (0, _toConsumableArray2.default)(this.fileList.Atts)),
        ExtraCharge: this.replyType == 6041 ? this.extraCharges : null
      };
      // if(this.replyType == 6041){
      //     let msg1=`额外费用为${this.extraCharges}；`;
      //     let msg2=this.replyCategroyDateTime?`延期至${this.replyCategroyDateTime}；`:'';
      //     postData.reply.ReplyContent = msg1+msg2+this.replyContent;
      // }
      this.$openMainLoading();
      (0, _order.SubmitOrderReplyOption)(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: "提交成功",
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    },
    getReplyCategoryName: function getReplyCategoryName() {
      var _this2 = this;
      var label = "";
      this.replyTypeList.some(function (item) {
        if (item.value == _this2.replyType) {
          label = item.label;
          return true;
        }
      });
      return label;
    },
    handleFilesSuccess: function handleFilesSuccess(res, fileList) {
      if (res.code == 0) {
        if (this.fileList.Atts.indexOf(res.FilePath) == -1) {
          this.fileList.Atts.push(res);
        }
      } else {
        this.$message.error(res.msg || "上传失败");
      }
    },
    handleRemove: function handleRemove(file) {
      var url = file.response.FilePath;
      var index = -1;
      this.fileList.Atts.some(function (item, i) {
        if (item.FilePath == url) {
          index = i;
        }
        return true;
      });
      if (index > -1) {
        this.fileList.Atts.splice(index, 1);
      }
    },
    handleExceed: function handleExceed() {
      this.$message.warning("文件最多上传5个附件");
    }
  }
};
exports.default = _default;