"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _dateUtil = require("element-ui/src/utils/date-util");
var _dom = require("element-ui/src/utils/dom");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 获取指定年份和季度的所有日期
var datesInYearAndQuarter = function datesInYearAndQuarter(year, quarter) {
  var numOfDays = getDayCountOfQuarter(year, quarter);
  var firstDay = new Date(year, quarter * 3, 1);
  return (0, _dateUtil.range)(numOfDays).map(function (n) {
    return (0, _dateUtil.nextDate)(firstDay, n);
  });
};

// 获取指定年份和季度总天数
var getDayCountOfQuarter = function getDayCountOfQuarter(year, quarter) {
  switch (quarter) {
    case 0:
      // 第一季度包含二月，需要对是否闰年进行判断处理
      if (year % 4 === 0 && year % 100 !== 0 || year % 400 === 0) {
        return 91;
      } else {
        return 90;
      }
    case 1:
      return 91;
    default:
      return 92;
  }
};
var _default = {
  name: 'QuarterPicker',
  props: {
    size: String,
    format: String,
    // 显示在输入框中的格式，引入季度：q（阿拉伯数字）、Q（中文数字）
    valueFormat: String,
    placeholder: String,
    prefixIcon: String,
    clearIcon: {
      type: String,
      default: 'el-icon-circle-close'
    },
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true
    },
    width: {
      // 组件宽度
      type: String,
      default: ''
    },
    disabledDate: {},
    // 不可用的日期
    value: null
  },
  data: function data() {
    return {
      showClose: false,
      pickerVisible: false,
      date: new Date(),
      quarterText: ['一', '二', '三', '四']
    };
  },
  computed: {
    triggerClass: function triggerClass() {
      return this.prefixIcon || 'el-icon-date';
    },
    displayValue: function displayValue() {
      if (!this.value) return null;
      // 季度，从0开始
      var quarter = parseInt(this.parsedValue.getMonth() / 3);
      var fDate = (0, _dateUtil.formatDate)(this.parsedValue, this.format);
      fDate = fDate.replace(/q/, quarter + 1).replace(/Q/, this.quarterText[quarter]);
      return fDate;
    },
    year: function year() {
      return this.date.getFullYear();
    },
    yearLabel: function yearLabel() {
      return this.year + ' 年';
    },
    parsedValue: function parsedValue() {
      if (!this.value) {
        return this.value;
      }
      if ((0, _dateUtil.isDateObject)(this.value)) {
        return this.value;
      }
      // 非时间格式且设置了valueFormat，进行时间转换
      if (this.valueFormat) {
        return (0, _dateUtil.parseDate)(this.value, this.valueFormat);
      }
      // 非时间格式且未设置valueFormat，再尝试转换时间
      return new Date(this.value);
    }
  },
  watch: {
    value: function value(_value) {
      this.date = _value ? this.parsedValue : new Date();
    }
  },
  methods: {
    handleMouseEnter: function handleMouseEnter() {
      if (this.disabled) return;
      if (this.value && this.clearable) {
        this.showClose = true;
      }
    },
    handleClickIcon: function handleClickIcon(event) {
      if (this.disabled) return;
      if (this.showClose) {
        this.$emit('input', null);
        this.$emit('change', null);
        this.showClose = false;
        this.pickerVisible = false;
        this.$refs.reference.blur();
      }
    },
    handleMousedownIcon: function handleMousedownIcon(event) {
      // 阻止鼠标按下清空按钮，防止清空数据时季度选择面板闪现
      event.preventDefault();
    },
    handleTableClick: function handleTableClick(event) {
      var target = event.target;
      if (target.tagName === 'A') {
        target = target.parentNode;
      }
      if (target.tagName !== 'TD') return;
      if ((0, _dom.hasClass)(target, 'disabled')) return;
      var column = target.cellIndex;
      var row = target.parentNode.rowIndex;
      // 季度，从0开始
      var quarter = row * 2 + column;
      // 季度开始月份，从0开始
      var month = quarter * 3;
      var newDate = new Date(this.year, month, 1);
      if (this.valueFormat) {
        newDate = (0, _dateUtil.formatDate)(newDate, this.valueFormat);
      }
      this.pickerVisible = false;
      this.$emit('input', newDate);
      this.$emit('change', newDate);
    },
    prevYear: function prevYear() {
      this.date = (0, _dateUtil.prevYear)(this.date);
    },
    nextYear: function nextYear() {
      this.date = (0, _dateUtil.nextYear)(this.date);
    },
    getCellStyle: function getCellStyle(quarter) {
      var style = {};
      var today = new Date();
      var date = this.parsedValue ? this.parsedValue : today;
      style.disabled = typeof this.disabledDate === 'function' ? datesInYearAndQuarter(this.year, quarter).every(this.disabledDate) : false;
      // 当前选中的季度样式
      style.current = date.getFullYear() === this.year && parseInt(date.getMonth() / 3) === quarter;
      // 今日所在季度样式
      style.quarter = today.getFullYear() === this.year && parseInt(today.getMonth() / 3) === quarter;
      return style;
    }
  }
};
exports.default = _default;