"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _OrderDetail = _interopRequireDefault(require("@/views/components/OrderDetail"));
//
//
//
var _default = {
  data: function data() {
    return {
      orderId: null,
      routePath: 'scm',
      departmentCode: "adidasDP006"
    };
  },
  methods: {},
  components: {
    OrderDetail: _OrderDetail.default
  },
  created: function created() {
    this.orderId = parseInt(this.$route.params.id);
  }
};
exports.default = _default;