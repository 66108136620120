"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _notice = require("@/api/notice");
var _file = require("@/api/file");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "PhotoAndVideoUpload",
  inject: ["$openMainLoading", "$closeMainLoading"],
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    limitTotle: {
      type: Number,
      default: 9
    }
  },
  data: function data() {
    return {
      fileList: [],
      videoPlayDialogVisible: false,
      nowVideoData: {
        url: "",
        cover: "",
        muted: false,
        loop: false,
        preload: "auto",
        poster: "",
        volume: 1,
        autoplay: false,
        title: ""
      },
      maxDuration: 60,
      //秒
      UpLoadFilesAPI: _notice.UpLoadFilesAPI,
      dialogVisible: false,
      dialogImageUrl: "",
      originFileList: []
    };
  },
  model: {
    prop: "value",
    event: "changeValue"
  },
  methods: {
    getRemotePath: function getRemotePath(pic, record) {
      var _this = this;
      (0, _file.GetMoreAttachUrl)(pic).then(function (res) {
        if (res.code == 0) {
          // record.remoreURL = res.data;
          _this.$set(record, "remoreURL", res.data);
        }
      });
      console.log(pic, record);
    },
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      this.$closeMainLoading();
      var fileUid = file.uid;
      var index = -1,
        remoreURL = "";
      if (res.code == 0) {
        var FilePath = res.FilePath,
          FileType = res.FileType,
          Filename = res.Filename,
          Thumbnail = res.Thumbnail;
        this.fileList.push({
          FilePath: FilePath,
          FileType: FileType,
          Filename: Filename,
          Thumbnail: Thumbnail,
          remoreURL: remoreURL
        });
      } else {
        this.$message.error(res.msg);
      }
      this.$refs.PhotoAndVideoUpload.uploadFiles.some(function (t, i) {
        if (t.uid === fileUid) {
          index = i;
          return false;
        }
      });
      this.$refs.PhotoAndVideoUpload.uploadFiles.splice(index, 1);
      // this.$refs.PhotoAndVideoUpload.abort();
    },
    handleUploadExceed: function handleUploadExceed() {
      this.$closeMainLoading();
      this.$message.error("上传图片视频超过数量限制");
    },
    handleVideoPreview: function handleVideoPreview(data) {
      this.nowVideoData.url = data.FilePath;
      this.nowVideoData.cover = data.Thumbnail;
      this.nowVideoData.title = data.FileName;
      this.videoPlayDialogVisible = true;
    },
    handlePicPreview: function handlePicPreview(data) {
      this.dialogImageUrl = data.remoreURL;
      this.dialogVisible = true;
    },
    handleDelete: function handleDelete(index) {
      this.fileList.splice(index, 1);
    },
    handleUploadBefore: function handleUploadBefore(file, fileList) {
      var _this2 = this;
      // console.log(file,fileList)
      // this.$refs.PhotoAndVideoUpload.uploadFiles=[];
      // this.$refs.PhotoAndVideoUpload.abort();
      // let fileTypeList = ['image/jpeg','image/png','image/gif']
      // const isPicVideo = file.type === 'image/jpeg';
      // if(this.fileList.length>=10){
      //     this.$message.error("上传图片视频超过最大数量限制");
      //     this.$refs.PhotoAndVideoUpload.abort();
      //     return false;
      // }
      // return isPicVideo;

      if (file.type.indexOf("video") > -1) {
        var url = URL.createObjectURL(file);
        var audioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", function () {
          var duration = audioElement.duration;
          if (duration > _this2.maxDuration) {
            _this2.$message.error("请上传" + _this2.maxDuration + "秒之内的视频");
            return false;
          } else {
            _this2.$openMainLoading();
          }
        });
      }
    }
  },
  watch: {
    fileList: function fileList(val) {
      this.$emit("changeValue", val);
    }
  },
  computed: {
    limit: function limit() {
      return this.limitTotle - this.fileList.length;
    }
  }
};
exports.default = _default2;