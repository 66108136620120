"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _OrderMenu = _interopRequireDefault(require("@/views/components/OrderMenu"));
var _TimeState = _interopRequireDefault(require("@/views/components/TimeState"));
var _dayjs = _interopRequireDefault(require("dayjs"));
var _order = require("@/api/order2");
var _Product = require("@/api/Product");
var _order2 = require("@/api/order");
var _ehs = require("@/api/ehs");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "EHSOrderList",
  inject: ["$openMainLoading", "$closeMainLoading"],
  components: {
    OrderMenu: _OrderMenu.default,
    TimeState: _TimeState.default
  },
  data: function data() {
    var _this = this;
    return {
      title: "ISC维修",
      searchForm: {
        DepartmentCode: "adidasDP005",
        OrderNo: "",
        Status: null,
        OrderStatusGroup: 1,
        ShopCode: "",
        ShopName: "",
        ProductTypeIDsString: [],
        ProcessStartTime: "",
        ProcessEndTime: "",
        OrderProcessStartTime: "",
        OrderProcessEndTime: "",
        ProcessDateTimeStart: "",
        ProcessDateTimeEnd: "",
        PageIndex: 1,
        PageSize: 10,
        OrderBy: "",
        OrderType: ""
      },
      tableData: [],
      dateSection: null,
      processedDateSection: [],
      total: 0,
      statuList: [],
      orderTypeList: [],
      pickerOptionsStart: {
        disabledDate: function disabledDate(time) {
          if (_this.searchForm.OrderProcessEndTime) {
            return time.getTime() > new Date(_this.searchForm.OrderProcessEndTime).getTime();
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: function disabledDate(time) {
          if (_this.searchForm.OrderProcessStartTime) {
            return time.getTime() < new Date(_this.searchForm.OrderProcessStartTime).getTime() - 86400000;
          }
        }
      }
    };
  },
  methods: {
    handleChangeOrderMenu: function handleChangeOrderMenu() {
      this.dateSection = "";
      this.processedDateSection = [];
      this.searchForm.OrderNo = "";
      this.searchForm.Status = null;
      this.searchForm.ShopCode = "";
      this.searchForm.ShopName = "";
      this.searchForm.ProductTypeIDsString = [];
      this.initProcessTime();
      this.searchForm.ProcessDateTimeStart = "";
      this.searchForm.ProcessDateTimeEnd = "";
      // this.searchForm.OrderProcessStartTime = "";
      // this.searchForm.OrderProcessEndTime = "";
      this.searchForm.PageIndex = 1;
      this.searchForm.OrderBy = "";
      this.searchForm.OrderType = "";
      this.statuList = [];
      this.tableData = [];
      this.total = 0;
      this.getStateList();
      this.getDataList();
    },
    exportOrder: function exportOrder() {
      var _this2 = this;
      var postData = (0, _objectSpread2.default)({}, this.searchForm);
      postData.Status = postData.Status ? postData.Status : -1;
      if (postData.ProductTypeIDsString && postData.ProductTypeIDsString.length) {
        postData.ProductTypeIDsString = postData.ProductTypeIDsString.map(function (item) {
          return item[item.length - 1];
        }).toString();
      } else {
        postData.ProductTypeIDsString = "";
      }
      var invokeMethod = this.searchForm.OrderStatusGroup == 0 ? _order2.ExportAllGroupList : _order.ExportOrderISC;
      this.$openMainLoading();
      invokeMethod(postData).then(function (res) {
        //ExportOrder(postData).then((res) => {
        _this2.$closeMainLoading();
        if (res.status == 200) {
          var blob = new Blob([res.data], {
            type: res.headers["content-type"]
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = res.headers.filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        } else if (res.status == 204) {
          _this2.$message.warning("当前状态下没有数据");
        } else {
          _this2.$message.warning("导出失败");
        }
      }).catch(function (err) {
        _this2.$closeMainLoading();
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.PageIndex = 1;
      this.getDataList();
    },
    getDataList: function getDataList() {
      var _this3 = this;
      var postData = (0, _objectSpread2.default)({}, this.searchForm);
      postData.Status = postData.Status ? postData.Status : -1;
      if (postData.ProductTypeIDsString && postData.ProductTypeIDsString.length) {
        postData.ProductTypeIDsString = postData.ProductTypeIDsString.map(function (item) {
          return item[item.length - 1];
        }).toString();
      } else {
        postData.ProductTypeIDsString = "";
      }
      var invokeMethod = this.searchForm.OrderStatusGroup == 0 ? _order2.GetAllGroupOrderListByStatusGroup : _ehs.GetOrderListByStatusGroup;
      this.$openMainLoading();
      invokeMethod(postData).then(function (res) {
        _this3.tableData = res.dataList;
        _this3.total = res.Total;
        _this3.$closeMainLoading();
      }).catch(function (err) {
        _this3.$closeMainLoading();
      });
    },
    getOrderType: function getOrderType() {
      var _this4 = this;
      var postData = {
        departmentCode: this.searchForm.DepartmentCode
      };
      (0, _ehs.GetEHSOrderType)(postData).then(function (res) {
        _this4.orderTypeList = res.data;
      });
    },
    goToDetails: function goToDetails(orderId) {
      this.$router.push({
        path: "/mktsupply/order/" + orderId
      });
    },
    handleSizeChange: function handleSizeChange(PageSize) {
      this.searchForm.PageSize = PageSize;
      this.getDataList();
    },
    handleCurrentChange: function handleCurrentChange(PageIndex) {
      this.searchForm.PageIndex = PageIndex;
      this.getDataList();
    },
    getStateList: function getStateList() {
      var _this5 = this;
      var postData = {
        OrderStatusGroup: this.searchForm.OrderStatusGroup,
        departmentCode: this.searchForm.DepartmentCode
      };
      (0, _order2.GetOrderStatusByStatusGroup)(postData).then(function (res) {
        var data = res.data;
        if (data.code !== 0) return false;
        _this5.statuList = data.data;
      });
    },
    handleSortchange: function handleSortchange(_ref) {
      var column = _ref.column,
        prop = _ref.prop,
        order = _ref.order;
      this.searchForm.OrderBy = prop;
      this.searchForm.OrderType = order == "descending" ? "desc" : "asc";
      this.handleSearch();
    },
    handleChangeDateSection: function handleChangeDateSection() {
      if (this.dateSection && this.dateSection.length) {
        this.searchForm.ProcessStartTime = this.dateSection[0];
        this.searchForm.ProcessEndTime = this.dateSection[1];
      } else {
        this.searchForm.ProcessStartTime = "";
        this.searchForm.ProcessEndTime = "";
      }
    },
    handleChangeProcessedDateSection: function handleChangeProcessedDateSection() {
      if (this.processedDateSection && this.processedDateSection.length) {
        this.searchForm.ProcessDateTimeStart = this.processedDateSection[0];
        this.searchForm.ProcessDateTimeEnd = this.processedDateSection[1];
      } else {
        this.searchForm.ProcessDateTimeStart = "";
        this.searchForm.ProcessDateTimeEnd = "";
      }
    },
    initProcessTime: function initProcessTime() {
      if (this.searchForm.OrderStatusGroup == 0) {
        this.dateSection = [(0, _dayjs.default)().startOf('month').format("YYYY-MM-DD"), (0, _dayjs.default)().format("YYYY-MM-DD")];
        this.searchForm.ProcessStartTime = this.dateSection[0];
        this.searchForm.ProcessEndTime = this.dateSection[1];
      } else {
        this.dateSection = [];
        this.searchForm.ProcessStartTime = "";
        this.searchForm.ProcessEndTime = "";
      }
    }
  },
  created: function created() {
    if (this.$route.query.group) {
      this.searchForm.OrderStatusGroup = parseInt(this.$route.query.group);
      this.initProcessTime();
    }
    // this.searchForm.OrderProcessStartTime = dayjs().startOf('month').format("YYYY-MM-DD");
    // this.searchForm.OrderProcessEndTime = dayjs().format("YYYY-MM-DD");
    this.getStateList();
    this.getOrderType();
    this.getDataList();
  }
};
exports.default = _default;