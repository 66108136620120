"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _report = require("@/api/report");
var _vuex = require("vuex");
var _vueEcharts = _interopRequireDefault(require("vue-echarts"));
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/component/title");
var _date = require("@/utils/date");
var _CircleProgress = _interopRequireDefault(require("@/components/CircleProgress"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  inject: ["$openMainLoading", "$closeMainLoading"],
  data: function data() {
    return {
      title: "MKT Dashboard",
      loading: false,
      currentTime: "本月",
      monthRange: [],
      summaryData: {},
      summaryList: [],
      materialType: "",
      condition: {
        DepartmentID: 5,
        StartDateTime: "",
        EndDateTime: ""
      },
      shopTypeChartObj: {},
      extraChargeChartObj: {},
      getPercent: _utils.getPercent
    };
  },
  components: {
    "v-chart": _vueEcharts.default,
    CircleProgress: _CircleProgress.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["chartColors"])),
  methods: {
    monthChange: function monthChange(val) {
      var start = (0, _date.GetMonthStartAndEnd)(val[0]);
      var end = (0, _date.GetMonthStartAndEnd)(val[1]);
      this.monthRange = [start[0], end[1]];
    },
    dateChange: function dateChange(val) {
      var range = [,];
      switch (val) {
        case "本月":
          range = (0, _date.CurrentMonthOfStartAndEnd)();
          break;
        case "本季":
          range = (0, _date.CurrentQuarterlyOfStartAndEnd)();
          break;
        case "本年":
          range = (0, _date.CurrentYearOfStartAndEnd)();
          break;
      }
      this.monthRange = (0, _toConsumableArray2.default)(range);
    },
    getRMOrderReportByParam: function getRMOrderReportByParam(data) {
      var _this = this;
      this.$openMainLoading();
      (0, _report.GetRMOrderReportByParam)(data).then(function (res) {
        _this.$closeMainLoading();
        var data = res.data;
        if (res.code !== 0) {
          return _this.$message.error("网络异常");
        } else {
          _this.summaryData = res.data;
          var polar = {
            color: _this.chartColors,
            tooltip: {
              trigger: "item",
              formatter: "{b}: {c}"
            },
            series: [{
              type: "pie",
              radius: ["60%", "80%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center"
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "14",
                  fontWeight: "bold"
                }
              },
              labelLine: {
                show: false
              },
              data: []
            }]
          };
          _this.summaryList = [];
          polar.series[0].data = [{
            value: data && data.TotalNum,
            name: "总工单"
          }, {
            value: data && data.CompleteNum,
            name: "完成工单"
          }];
          var c1 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c1));
          polar.series[0].data = [{
            value: data && data.TotalNum,
            name: "总工单"
          }, {
            value: data && data.TimeOutNum,
            name: "超时工单"
          }];
          var c2 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c2));
          polar.series[0].data = [{
            value: data && data.RMTotalExtraCharge,
            name: "RM"
          }, {
            value: data && data.OPSTotalExtraCharge,
            name: "OPS"
          }];
          var c3 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c3));
          _this.shopTypeChart(data && data.ShopTypeReportList);
          _this.extraChargeChart(data && data.ExtraChargeReportList);
        }
      }).catch(function (err) {
        _this.$closeMainLoading();
        console.log("接口问题", err);
      });
    },
    extraChargeChart: function extraChargeChart(data) {
      var obj = {
        color: this.chartColors,
        title: {
          text: "增补费用",
          left: "center"
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: [],
          bottom: 0
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: "value"
        },
        series: []
      };
      // data.forEach((item) => {
      //   console.log(item.OrderCreateTimeMonth, item.ChooseType, item.TotalNum);
      // });
      var legendData = [],
        xseriesList = [];
      if (data && data.length > 0) {
        data.forEach(function (val) {
          if (val.ChooseType) {
            legendData.push(val.ChooseType);
          }
          if (val.OrderCreateTimeMonth) {
            xseriesList.push(val.OrderCreateTimeMonth);
          }
        });
      }
      obj.legend.data = (0, _toConsumableArray2.default)(new Set(legendData)); //去重复
      obj.xAxis.data = (0, _toConsumableArray2.default)(new Set(xseriesList)); //去重复

      var seriesList = [];
      obj.legend.data.forEach(function (t) {
        var chartObj = {
          name: t,
          type: "line",
          data: [obj.xAxis.data.length] //事件
        };

        obj.xAxis.data.forEach(function (z) {
          data.forEach(function (item) {
            if (item.ChooseType == t && z == item.OrderCreateTimeMonth) {
              chartObj.data.splice(obj.xAxis.data.indexOf(item.OrderCreateTimeMonth), 1, item.TotalNum);
            }
          });
        });
        seriesList.push(chartObj);
      });
      obj.series = seriesList;
      console.log(obj);
      this.extraChargeChartObj = obj;
    },
    shopTypeChart: function shopTypeChart(data) {
      var obj = {
        color: this.chartColors,
        title: {
          text: "店铺类型工单统计",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: []
        },
        yAxis: {
          type: "value"
        },
        series: {
          data: [],
          type: "bar"
        }
      };
      var xseriesList = [],
        seriesData = [];
      if (data && data.length > 0) {
        data.forEach(function (val) {
          xseriesList.push(val.ShopChildType);
          seriesData.push(val.TotalNum);
        });
      }
      obj.xAxis.data = xseriesList;
      obj.series.data = seriesData;
      this.shopTypeChartObj = obj;
    },
    search: function search() {
      console.log(this.monthRange);
      this.condition.StartDateTime = this.monthRange[0];
      this.condition.EndDateTime = this.monthRange[1];
      this.getRMOrderReportByParam(this.condition);
    }
  },
  created: function created() {
    this.monthRange = (0, _toConsumableArray2.default)((0, _date.CurrentMonthOfStartAndEnd)());
    this.condition.StartDateTime = this.monthRange[0];
    this.condition.EndDateTime = this.monthRange[1];
    this.getRMOrderReportByParam(this.condition);
  }
};
exports.default = _default;