"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var tokens = {
  admin: {
    token: 'admin-token'
  },
  editor: {
    token: 'editor-token'
  }
};
var users = {
  'admin-token': {
    roles: ['admin'],
    introduction: 'I am a super administrator',
    avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
    name: 'Super Admin'
  },
  'editor-token': {
    roles: ['editor'],
    introduction: 'I am an editor',
    avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
    name: 'Normal Editor'
  }
};
var _default = [
// user login
{
  url: '/user/login',
  type: 'post',
  response: function response(config) {
    console.log(config);
    var username = config.body.username;
    //const token = users[username]
    var token = users['admin-token'];

    // mock error
    // if (!token) {
    //   return {
    //     Status: 60204,
    //     message: 'Account and password are incorrect.'
    //   }
    // }

    return {
      Status: 0,
      data: token
    };
  }
},
// get user info
{
  url: '/user/info\.*',
  type: 'get',
  response: function response(config) {
    // const { token } = config.query
    // //const info = users[token]
    // const info = users['admin-token']
    // // mock error
    // if (!info) {
    //   return {
    //     Status: 50008,
    //     message: 'Login failed, unable to get user details.'
    //   }
    // }

    return {
      Status: 20000,
      data: {
        roles: ['admin'],
        introduction: 'I am a super administrator',
        avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
        name: 'Super Admin'
      }
    };
  }
},
// user logout
{
  url: '/user/logout',
  type: 'post',
  response: function response(_) {
    return {
      Status: 0,
      data: 'success'
    };
  }
}];
exports.default = _default;