"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var MKTRouter = [{
  path: '/mkt',
  name: 'MKT',
  component: _layout.default,
  meta: {
    title: 'MKT 工单管理',
    icon: 'order',
    roles: ['adidasrole007', 'adidasrole008', 'adidasrole014', 'adidasrole015', 'adidasrole006']
  },
  redirect: '/mkt/order',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/MKT/index'));
      });
    },
    name: 'MKTDashboard',
    meta: {
      title: 'MKT Dashboard',
      roles: ['adidasrole007', 'adidasrole008', 'adidasrole014', 'adidasrole015']
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/MKT/order/index'));
      });
    },
    name: 'MKTOrder',
    redirect: '/mkt/order/index',
    meta: {
      title: 'ISC',
      roles: ['adidasrole007', 'adidasrole008', 'adidasrole014', 'adidasrole015', 'adidasrole006']
    },
    children: [{
      hidden: true,
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/MKT/order/list'));
        });
      },
      name: 'MKTOrderList',
      meta: {
        title: '工单列表',
        activeMenu: '/mkt/order',
        roles: ['adidasrole007', 'adidasrole008', 'adidasrole014', 'adidasrole015', 'adidasrole006']
      }
    }, {
      hidden: true,
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/MKT/order/detail'));
        });
      },
      name: 'MKTOrderDetail',
      meta: {
        title: '查看工单',
        activeMenu: '/mkt/order',
        roles: ['adidasrole007', 'adidasrole008', 'adidasrole014', 'adidasrole015', 'adidasrole006']
      }
    }]
  }, {
    path: 'notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/Notice/index'));
      });
    },
    name: 'MKTNotice',
    meta: {
      title: '任务下发',
      roles: ['adidasrole007', 'adidasrole008', 'adidasrole014', 'adidasrole015']
    },
    redirect: '/mkt/notice/index',
    children: [{
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/list'));
        });
      },
      hidden: true,
      name: 'MKTNoticelist',
      meta: {
        title: '任务下发',
        parentPath: '/mkt/notice',
        activeMenu: '/mkt/notice',
        departmentID: 5,
        roles: ['adidasrole007', 'adidasrole008', 'adidasrole014', 'adidasrole015']
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/add'));
        });
      },
      hidden: true,
      name: 'MKTNoticeAdd',
      meta: {
        title: '创建任务',
        parentPath: '/mkt/notice',
        activeMenu: '/mkt/notice',
        departmentID: 5,
        roles: ['adidasrole007', 'adidasrole008', 'adidasrole014', 'adidasrole015']
      }
    }, {
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/detail'));
        });
      },
      hidden: true,
      meta: {
        title: '通知详情',
        parentPath: '/mkt/notice',
        activeMenu: '/mkt/notice',
        departmentID: 5,
        roles: ['adidasrole007', 'adidasrole008', 'adidasrole014', 'adidasrole015']
      }
    }]
  }]
}];
var _default = MKTRouter;
exports.default = _default;