var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _c(
        "div",
        {
          staticClass: "main-title",
          staticStyle: { "justify-content": "space-between" },
        },
        [_c("div", { staticClass: "title-name" }, [_vm._v(_vm._s(_vm.title))])]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "el-form-item", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 8, md: 6, lg: 6, xl: 4 } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.dateChange },
                  model: {
                    value: _vm.currentTime,
                    callback: function ($$v) {
                      _vm.currentTime = $$v
                    },
                    expression: "currentTime",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "本月" } }, [
                    _vm._v("本月"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "本季" } }, [
                    _vm._v("本季"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "本年" } }, [
                    _vm._v("本年"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 10, md: 10, lg: 10, xl: 6 } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "monthrange",
                  "start-placeholder": "开始月份",
                  "end-placeholder": "结束月份",
                  "value-format": "yyyy-MM",
                  clearable: false,
                },
                on: { change: _vm.monthChange },
                model: {
                  value: _vm.monthRange,
                  callback: function ($$v) {
                    _vm.monthRange = $$v
                  },
                  expression: "monthRange",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 6, md: 8, lg: 8, xl: 6 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "main-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.search },
                },
                [_vm._v(" 检索 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          完成工单:"),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.summaryData.CompleteNum)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 12, md: 24, lg: 12, xl: 12 } },
                        [
                          _c("div", { staticClass: "desc" }, [
                            _vm._v("\n              总工单数:"),
                            _c("span", [
                              _vm._v(_vm._s(_vm.summaryData.TotalNum)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "desc" }, [
                            _vm._v(
                              "\n              完成率:\n              " +
                                _vm._s(
                                  _vm.getPercent(
                                    _vm.summaryData.CompleteNum,
                                    _vm.summaryData.TotalNum
                                  ) + " %"
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { xs: 12, sm: 12, md: 24, lg: 12, xl: 12 },
                        },
                        [
                          _c("circle-progress", {
                            attrs: {
                              percentage: _vm.getPercent(
                                _vm.summaryData.CompleteNum,
                                _vm.summaryData.TotalNum
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          超时工单:"),
                    _c("span", [_vm._v(_vm._s(_vm.summaryData.TimeOutNum))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 12, md: 24, lg: 12, xl: 12 } },
                        [
                          _c("div", { staticClass: "desc" }, [
                            _vm._v("\n              总工单数:"),
                            _c("span", [
                              _vm._v(_vm._s(_vm.summaryData.TotalNum)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "desc" }, [
                            _vm._v("\n              超时率:"),
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.getPercent(
                                      _vm.summaryData.TimeOutNum,
                                      _vm.summaryData.TotalNum
                                    ) + " %"
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { xs: 12, sm: 12, md: 24, lg: 12, xl: 12 },
                        },
                        [
                          _c("circle-progress", {
                            attrs: {
                              percentage: _vm.getPercent(
                                _vm.summaryData.TimeOutNum,
                                _vm.summaryData.TotalNum
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n          增补费用:"),
                    _c("span", [
                      _vm._v(_vm._s(_vm.summaryData.TotalExtraCharge)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 12, md: 24, lg: 12, xl: 12 } },
                        [
                          _c("div", { staticClass: "desc" }, [
                            _vm._v("\n              RM:"),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.summaryData.RMTotalExtraCharge)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "desc" }, [
                            _vm._v("\n              OPS:"),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.summaryData.OPSTotalExtraCharge)
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
                        [
                          _c("v-chart", {
                            staticStyle: { height: "100px" },
                            attrs: { options: _vm.summaryList[2] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c("v-chart", {
                    staticStyle: { height: "500px" },
                    attrs: { options: _vm.shopTypeChartObj },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", attrs: { shadow: "hover" } },
                [
                  _c("v-chart", {
                    staticStyle: { height: "500px" },
                    attrs: { options: _vm.extraChargeChartObj },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }