var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "fileWrap",
      staticClass: "fileWrap",
      class: { isCollapse: _vm.collapseStatus },
    },
    [
      _c(
        "div",
        { ref: "fileBox", staticClass: "fileBox" },
        [
          _c(
            "div",
            { staticClass: "imgwrap" },
            [
              _vm._l(_vm.imgList, function (item, index) {
                return _c("el-image", {
                  key: "image" + index,
                  staticClass: "imgItem",
                  attrs: {
                    src: item.Thumbnail ? item.Thumbnail : item.FilePath,
                    fit: "cover",
                    "preview-src-list": _vm.imgPreviewList,
                  },
                })
              }),
              _vm._v(" "),
              _vm._l(_vm.videoList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "video" + index,
                    staticClass: "videoItem",
                    on: {
                      click: function ($event) {
                        return _vm.handleVideoPreview(item)
                      },
                    },
                  },
                  [
                    _c("el-image", {
                      staticClass: "img",
                      attrs: { src: item.Thumbnail, fit: "cover" },
                    }),
                    _vm._v(" "),
                    _vm._m(0, true),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.documentList && _vm.documentList.length
            ? _c(
                "div",
                { staticClass: "documentwrap" },
                _vm._l(_vm.documentList, function (item, index) {
                  return _c(
                    "div",
                    { key: "document" + index, staticClass: "documentItem" },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openDocument(item.FilePath)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              item.FileName ? item.FileName : item.Filename
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.nowVideoData.title,
                visible: _vm.videoPlayDialogVisible,
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.videoPlayDialogVisible = $event
                },
              },
            },
            [
              _vm.videoPlayDialogVisible
                ? _c("vueMiniPlayer", {
                    staticClass: "vueMiniPlayer",
                    attrs: { video: _vm.nowVideoData },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showCollapse && _vm.collapse
            ? _c(
                "div",
                { staticClass: "collapseBtn", on: { click: _vm.handleToggle } },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.collapseStatus,
                          expression: "collapseStatus",
                        },
                      ],
                    },
                    [
                      _vm._v("查看详情 "),
                      _c("i", { staticClass: "el-icon-arrow-down" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.collapseStatus,
                          expression: "!collapseStatus",
                        },
                      ],
                    },
                    [
                      _vm._v("收起来 "),
                      _c("i", { staticClass: "el-icon-arrow-up" }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mask" }, [
      _c("i", { staticClass: "el-icon-video-play" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }