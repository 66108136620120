var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-progress", {
    staticStyle: { "vertical-align": "top" },
    attrs: {
      type: "circle",
      color: "#5470c6",
      width: 100,
      "stroke-width": 10,
      percentage: _vm.showPercentage,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }