"use strict";

var _interopRequireWildcard = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _msal = require("msal");
var API = _interopRequireWildcard(require("@/api/user"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      btnText: "获取验证码",
      countDown: 60,
      isDisabledMobileIDCode: false,
      mobileCodeLoading: false,
      timeer: null,
      mobileMSGtip: "",
      loginForm: {
        userName: "",
        password: "",
        idcode: ""
      },
      loginRules: {
        userName: [{
          required: true,
          trigger: "blur",
          message: "请输入用户名"
        }],
        password: [{
          required: true,
          trigger: "blur",
          message: "请输入密码"
        }],
        idcode: [{
          required: true,
          trigger: "blur",
          message: "请输入验证码"
        }]
      },
      loading: false,
      passwordType: "password",
      redirect: undefined,
      idCodeImg: "",
      idCodeLoading: false,
      findPwd: {
        show: false,
        idCodeUrl: "",
        loadingIDCode: false,
        idCodeImg: "",
        form: {
          Loginname: "",
          IDCode: ""
        }
      }
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        console.log("route.query", route.query);
        console.log("route.query.redirect", route.query.redirect);
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.getIDCode();
    this.getfindPwdIDCode();
    window.ssologin = this.SSOLogin;
  },
  methods: {
    getMobileCode: function getMobileCode() {
      var _this = this;
      if (!this.loginForm.userName.trim()) {
        this.$message.warning("请输入账号");
        return;
      }
      this.isDisabledMobileIDCode = true;
      API.GetMobileCode({
        UserName: this.loginForm.userName
      }).then(function (res) {
        if (res.code == 0) {
          _this.mobileMSGtip = res.data;
          setTimeout(function () {
            _this.mobileMSGtip = "";
          }, 8000);
          _this.$message.success("发送成功");
          var timeer = setInterval(function () {
            if (_this.countDown > 0) {
              _this.countDown -= 1;
              _this.btnText = _this.countDown + "s";
            } else {
              clearInterval(timeer);
              _this.isDisabledMobileIDCode = false;
              _this.countDown = 60;
              _this.btnText = "重新获取";
            }
          }, 1000);
        } else {
          _this.countDown = 60;
          _this.isDisabledMobileIDCode = false;
          _this.$message.error(res.data || "发送失败");
        }
      }).catch(function (error) {
        _this.isDisabledMobileIDCode = false;
      });
    },
    // 密码框开启可视密码
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    // 登陆函数
    handleLogin: function handleLogin() {
      var _this3 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          _this3.$store.dispatch("user/login", _this3.loginForm).then(function () {
            // this.$router.push({ path: this.redirect || "/" });
            _this3.$router.push({
              path: "/"
            });
            _this3.loading = false;
            _this3.getIDCode();
          }).catch(function () {
            // this.$message.error('error submit');
            _this3.loading = false;
            _this3.loginForm.idcode = "";
            _this3.getIDCode();
          });
        } else {
          // this.$message.error('error submit');
          console.log("error submit!!");
          return false;
        }
      });
    },
    SSOLogin: function SSOLogin() {
      var _this4 = this;
      this.loading = true;
      this.$store.dispatch("user/ssoLogin").then(function (e) {
        // this.$router.push({ path: this.redirect || "/" });
        _this4.$router.push({
          path: "/"
        });
        _this4.loading = false;
      }).catch(function (err) {
        // this.$message.error('error submit');
        console.log("error ssologin!!");
        _this4.loading = false;
      });
    },
    getIDCode: function getIDCode() {
      var _this5 = this;
      this.idCodeLoading = false;
      this.loginForm.idcode = "";
      API.getIDCode().then(function (res) {
        _this5.idCodeImg = "data:image/png;base64," + res.data;
      });
    },
    getfindPwdIDCode: function getfindPwdIDCode() {
      var _this6 = this;
      this.findPwd.idCodeLoading = false;
      API.getIDCode().then(function (res) {
        _this6.findPwd.idCodeImg = "data:image/png;base64," + res.data;
      });
    },
    retrievePassword: function retrievePassword() {
      var _this7 = this;
      if (this.findPwd.form.Loginname) {
        var myReg = /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
        if (!myReg.test(this.findPwd.form.Loginname)) {
          this.$message.warning("邮箱格式不正确");
          return;
        }
      } else {
        this.$message.warning("请输入邮箱");
        return;
      }
      if (!this.findPwd.form.IDCode) {
        this.$message.warning("请输入右侧验证码");
        return;
      }
      API.RetrievePassword(this.findPwd.form).then(function (res) {
        if (res.code == 0) {
          _this7.$message.success(res.msg);
          _this7.findPwd.show = false;
        }
      }).catch(function (error) {
        _this7.getfindPwdIDCode();
      });
    }
  }
};
exports.default = _default;