"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _ehs = require("@/api/ehs");
var _PhotoAndVideoUpload = _interopRequireDefault(require("@/views/components/PhotoAndVideoUpload"));
var _notice = require("@/api/notice");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "EHSStoreReply",
  data: function data() {
    return {
      loading: false,
      replyType: 4,
      ReplyContent: '',
      fileList: {
        Pics: [],
        Atts: []
      },
      UpLoadFilesAPI: _notice.UpLoadFilesAPI
    };
  },
  components: {
    PhotoAndVideoUpload: _PhotoAndVideoUpload.default
  },
  inject: ['$openMainLoading', '$closeMainLoading', 'updateDetailsPage', 'orderBasicData'],
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      if (!this.ReplyContent) {
        this.$message.warning('请输入描述信息');
        return false;
      }
      var postData = {
        NextOrderStatus: '100000',
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderCode,
          ReplyContent: this.ReplyContent,
          ReplyCategoryName: "关闭"
        },
        AttachmentList: [].concat((0, _toConsumableArray2.default)(this.fileList.Pics), (0, _toConsumableArray2.default)(this.fileList.Atts))
      };
      this.$openMainLoading();
      (0, _ehs.SubmitEHSAndPPOrderReplyOption)(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: '提交成功',
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    },
    handleFilesSuccess: function handleFilesSuccess(res, fileList) {
      if (res.code == 0) {
        if (this.fileList.Atts.indexOf(res.FilePath) == -1) {
          this.fileList.Atts.push(res);
        }
      } else {
        this.$message.error(res.msg || '上传失败');
      }
    },
    handleRemove: function handleRemove(file) {
      var url = file.response.FilePath;
      var index = -1;
      this.fileList.Atts.some(function (item, i) {
        if (item.FilePath == url) {
          index = i;
        }
        return true;
      });
      if (index > -1) {
        this.fileList.Atts.splice(index, 1);
      }
    },
    handleExceed: function handleExceed() {
      this.$message.warning('文件最多上传5个附件');
    }
  }
};
exports.default = _default;