"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetConOrderReportByParam = GetConOrderReportByParam;
exports.GetEHSOrderReportByParam = GetEHSOrderReportByParam;
exports.GetPPOrderReportByParam = GetPPOrderReportByParam;
exports.GetRMOrderReportByParam = GetRMOrderReportByParam;
exports.GetRXOrderReportByParam = GetRXOrderReportByParam;
exports.GetSCMOrderReportByParam = GetSCMOrderReportByParam;
var _request = _interopRequireDefault(require("@/utils/request"));
// ehs报表
function GetEHSOrderReportByParam(data) {
  return (0, _request.default)({
    url: '/api/OrderReport/GetEHSOrderReportByParam',
    method: 'post',
    data: data
  });
}

//pp报表
function GetPPOrderReportByParam(data) {
  return (0, _request.default)({
    url: '/api/OrderReport/GetPPOrderReportByParam',
    method: 'post',
    data: data
  });
}

// rx报表
function GetRXOrderReportByParam(data) {
  return (0, _request.default)({
    url: '/api/OrderReport/GetRXOrderReportByParam',
    method: 'post',
    data: data
  });
}

// con报表
function GetConOrderReportByParam(data) {
  return (0, _request.default)({
    url: '/api/OrderReport/GetConOrderReportByParam',
    method: 'post',
    data: data
  });
}

// rm报表
function GetRMOrderReportByParam(data) {
  return (0, _request.default)({
    url: '/api/OrderReport/GetRMOrderReportByParam',
    method: 'post',
    data: data
  });
}

//scm 报表
function GetSCMOrderReportByParam(data) {
  return (0, _request.default)({
    url: '/api/OrderReport/GetSCMOrderReportByParam',
    method: 'post',
    data: data
  });
}