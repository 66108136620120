"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _order = require("@/api/order");
var _orderFormConfig = require("@/views/components/OrderForm/orderFormConfig");
var _notice = require("@/api/notice");
var _PhotoAndVideoUpload = _interopRequireDefault(require("@/views/components/PhotoAndVideoUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ISCSuppilerReceive",
  inject: ["$openMainLoading", "$closeMainLoading", "updateDetailsPage", "orderBasicData"],
  data: function data() {
    return {
      ISCProductType: "活动",
      //活动,常规
      replyTypeList: [{
        label: "接单",
        value: 4002,
        //活动
        type: ["活动"]
      }, {
        label: "接单",
        value: 4010,
        //常规
        type: ["常规"]
      }],
      replyType: 4002,
      replyContent: "",
      extraCharge: "",
      returnCauseList: _orderFormConfig.BackAndRejectCause.ISCSupply_back,
      returnCauseValue: "",
      fileList: {
        Pics: [],
        Atts: []
      }
    };
  },
  created: function created() {
    this.ISCProductType = this.orderBasicData.Origin.ISCProductType;
    this.replyType = this.orderBasicData.Origin.ISCProductType == "活动" ? 4002 : 4010;
  },
  components: {
    PhotoAndVideoUpload: _PhotoAndVideoUpload.default
  },
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      if (this.replyType == 4010 && !this.extraCharge) {
        this.$message.warning("请输入预估费用");
        return false;
      }
      if (this.replyType == 4000 && !this.replyContent) {
        this.$message.warning("请输入描述信息");
        return false;
      }
      var postData = {
        NextOrderStatus: this.replyType,
        ExtraCharge: this.replyType == 4010 ? this.extraCharge : "",
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderCode,
          ReplyContent: this.replyContent,
          ReplyCategoryName: "接单"
        },
        attachmentList: [].concat((0, _toConsumableArray2.default)(this.fileList.Pics), (0, _toConsumableArray2.default)(this.fileList.Atts))
      };
      this.$openMainLoading();
      (0, _order.SubmitOrderReplyOption)(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: "提交成功",
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    },
    showReplyTypeItem: function showReplyTypeItem(typeList) {
      console.log(typeList);
      return typeList.includes(this.ISCProductType);
    }
  }
};
exports.default = _default;