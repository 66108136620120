var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "el-scrollbar",
        { staticClass: "app-main-container", staticStyle: { height: "100%" } },
        [
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("keep-alive", [_c("router-view", { key: _vm.key })], 1)],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-enter", mode: "out-in" } },
            [
              _c("AppMainLoading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }