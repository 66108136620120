var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "replyFormWrap" }, [
    _c(
      "div",
      { staticClass: "formMain" },
      [
        _c(
          "el-radio-group",
          {
            staticClass: "tabWrap",
            model: {
              value: _vm.replyType,
              callback: function ($$v) {
                _vm.replyType = $$v
              },
              expression: "replyType",
            },
          },
          _vm._l(_vm.replyTypeList, function (item) {
            return _c(
              "el-radio",
              { key: item.value, attrs: { label: item.value } },
              [_vm._v(_vm._s(item.label))]
            )
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          { attrs: { gutter: 40 } },
          [
            _c(
              "el-col",
              { attrs: { span: 8 } },
              [
                _vm.replyType == 4001
                  ? _c(
                      "el-select",
                      {
                        staticStyle: { "margin-bottom": "20px", width: "100%" },
                        attrs: { placeholder: "请选择拒绝退单原因" },
                        model: {
                          value: _vm.returnCauseValue,
                          callback: function ($$v) {
                            _vm.returnCauseValue = $$v
                          },
                          expression: "returnCauseValue",
                        },
                      },
                      _vm._l(_vm.returnCauseList, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("el-input", {
                  attrs: {
                    rows: 3,
                    type: "textarea",
                    placeholder: "请输入描述信息",
                  },
                  model: {
                    value: _vm.replyContent,
                    callback: function ($$v) {
                      _vm.replyContent = $$v
                    },
                    expression: "replyContent",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 16 } }, [
              _c("div", { staticClass: "formLabel" }, [
                _vm._v("上传图片或者视频(最多上传9个):"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("PhotoAndVideoUpload", {
                    model: {
                      value: _vm.fileList.Pics,
                      callback: function ($$v) {
                        _vm.$set(_vm.fileList, "Pics", $$v)
                      },
                      expression: "fileList.Pics",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "formFooter" },
      [
        _c(
          "el-button",
          {
            staticClass: "main-btn",
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.handleSubmit()
              },
            },
          },
          [_vm._v("提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }