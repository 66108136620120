"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _order = require("@/api/order");
var _orderFormConfig = require("@/views/components/OrderForm/orderFormConfig");
var _notice = require("@/api/notice");
var _PhotoAndVideoUpload = _interopRequireDefault(require("@/views/components/PhotoAndVideoUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "PPRepairSupplierReceive",
  data: function data() {
    return {
      replyTypeList: [{
        label: "接单",
        value: 6040
      }, {
        label: "退单",
        value: 6030
      }],
      periodList: [{
        label: "店铺在保",
        value: "在保"
      }, {
        label: "店铺过保",
        value: "过保"
      }],
      IsExpired: "在保",
      replyType: 6040,
      //6040接单，6030退单
      replyContent: "",
      returnCauseList: _orderFormConfig.BackAndRejectCause.PPSupply_back,
      repairType: _orderFormConfig.BackAndRejectCause.PP_repairType,
      returnCauseValue: "",
      returnRepairValue: "",
      UpLoadFilesAPI: _notice.UpLoadFilesAPI,
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: {
        Pics: [],
        Atts: []
      }
    };
  },
  components: {
    PhotoAndVideoUpload: _PhotoAndVideoUpload.default
  },
  inject: ["$openMainLoading", "$closeMainLoading", "updateDetailsPage", "orderBasicData"],
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      if (this.replyType == 6030 && !this.returnCauseValue) {
        //退单
        this.$message.warning("请选择退单原因");
        return false;
      }
      if (this.replyType == 6040 && !this.returnRepairValue) {
        this.$message.warning("请选择处置方式");
        return false;
      }
      if (!this.replyContent && this.replyType !== 6040) {
        this.$message.warning("请输入描述信息");
        return false;
      }
      var nextStatus = 6040,
        isExpireChoosed = "";
      if (this.replyType == 6040) {
        if (this.returnRepairValue.indexOf("远程") > -1) {
          nextStatus = 6042; //远程接单
        }

        isExpireChoosed = nextStatus == 6040 ? this.IsExpired : "";
      } else {
        nextStatus = 6030;
      }
      var postData = {
        ISCProductType: this.replyType == 6040 ? this.returnRepairValue : "",
        NextOrderStatus: nextStatus,
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderCode,
          ReplyReasonName: this.replyType == 6030 ? this.returnCauseValue : "",
          ReplyContent: this.replyContent,
          ReplyCategoryName: this.getReplyCategoryName(),
          IsExpireChoosed: isExpireChoosed
        },
        attachmentList: [].concat((0, _toConsumableArray2.default)(this.fileList.Pics), (0, _toConsumableArray2.default)(this.fileList.Atts))
      };
      this.$openMainLoading();
      (0, _order.SubmitOrderReplyOption)(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: "提交成功",
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    },
    getReplyCategoryName: function getReplyCategoryName() {
      var _this2 = this;
      var label = "";
      this.replyTypeList.some(function (item) {
        if (item.value == _this2.replyType) {
          label = item.label;
          return true;
        }
      });
      return label;
    }
  }
};
exports.default = _default;