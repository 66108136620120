"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    fileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    collapse: {
      type: Boolean,
      default: true
    }
  },
  name: "OrderFile",
  data: function data() {
    return {
      imgList: [],
      imgPreviewList: [],
      videoList: [],
      documentList: [],
      videoPlayDialogVisible: false,
      nowVideoData: {
        url: '',
        cover: '',
        muted: false,
        loop: false,
        preload: 'auto',
        poster: '',
        volume: 1,
        autoplay: false,
        title: ''
      },
      collapseStatus: true,
      showCollapse: false
    };
  },
  methods: {
    getData: function getData() {
      if (this.fileList && this.fileList.length) {
        var imgList = [];
        var videoList = [];
        var imgPreviewList = [];
        var documentList = [];
        this.fileList.map(function (item) {
          if (item.FileType == 1) {
            imgList.push(item);
            imgPreviewList.push(item.FilePath);
          } else if (item.FileType == 2) {
            videoList.push(item);
          } else {
            documentList.push(item);
          }
        });
        this.imgList = imgList;
        this.videoList = videoList;
        this.documentList = documentList;
        this.imgPreviewList = imgPreviewList;
      }
    },
    openDocument: function openDocument(src) {
      if (src) {
        window.open(src);
      }
    },
    handleVideoPreview: function handleVideoPreview(data) {
      this.nowVideoData.url = data.FilePath;
      this.nowVideoData.cover = data.Thumbnail;
      this.nowVideoData.title = data.FileName || data.Filename;
      this.videoPlayDialogVisible = true;
    },
    handleToggle: function handleToggle() {
      this.collapseStatus = !this.collapseStatus;
    },
    imageError: function imageError(e) {
      console.log(e);
    }
  },
  mounted: function mounted() {
    if (this.collapse) {
      var height = this.$refs.fileBox.offsetHeight;
      height > 100 && (this.showCollapse = true);
    } else {
      this.collapseStatus = false;
    }
  },
  created: function created() {
    this.getData();
  },
  watch: {
    fileList: function fileList(val) {
      if (val) {
        this.getData();
      }
    }
  }
};
exports.default = _default2;