"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _moment = _interopRequireDefault(require("moment"));
var _OrderFile = _interopRequireDefault(require("../OrderFile"));
var _order = require("@/api/order2");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "OrderInfo",
  props: {
    orderId: {
      type: Number,
      default: 0
    },
    detailInfo: {
      type: Object,
      default: null
    }
  },
  components: {
    OrderFile: _OrderFile.default
  },
  data: function data() {
    return {
      detail: {},
      productList: [],
      productTotal: 0,
      proportion: "0%",
      proportionType: 0,
      lastProccess: {},
      loading: false
    };
  },
  methods: {
    exportOrder: function exportOrder() {
      var _this = this;
      this.loading = true;
      var postData = {
        OrderIDs: [this.orderId],
        DepartmentCode: "adidasDP004",
        OrderNo: this.detail.OrderNo,
        Status: -1,
        OrderStatusGroup: -1,
        ShopCode: "",
        ShopName: "",
        ProductTypeID: "",
        ProcessStartTime: "",
        ProcessEndTime: "",
        PageIndex: 1,
        PageSize: 9999999999,
        OrderBy: "",
        OrderType: ""
      };
      (0, _order.ExportOrder)(postData).then(function (res) {
        _this.loading = false;
        console.log(res);
        if (res.status == 200) {
          var blob = new Blob([res.data], {
            type: res.headers["content-type"]
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = res.headers.filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        } else if (res.status == 204) {
          _this.$message.warning("当前状态下没有数据");
        } else {
          _this.$message.warning("导出失败");
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    formatterDate: function formatterDate(data) {
      return data ? (0, _moment.default)(data).format("yyyy-MM-DD HH:mm:ss") : "";
    },
    formatterDate2: function formatterDate2(data) {
      return data ? (0, _moment.default)(data).format("yyyy-MM-DD") : "";
    },
    dataProcessing: function dataProcessing() {
      var total = 0;
      var predictNum = 0;
      this.detail = this.detailInfo.Detail || {};
      this.productList = this.detailInfo.Products || [];
      this.lastProccess = this.detailInfo.LastProccess || {};
      predictNum = this.detailInfo.Detail.PredictNum;
      if (this.detailInfo.Products.length) {
        this.detailInfo.Products.map(function (item) {
          total = total + item.MinUnitNum * item.Num;
        });
        this.productTotal = total;
        var proportionNum = parseInt((total - predictNum) / (predictNum > 0 ? predictNum : total) * 10000, 10) / 100;
        if (isNaN(proportionNum)) {
          this.proportion = "0%";
        } else {
          this.proportion = Math.round(proportionNum) + "%";
        }
        this.proportionType = proportionNum > this.detail.PredictNumRange || proportionNum < -this.detail.PredictNumRange ? 1 : 0;
      }
    }
  },
  created: function created() {
    if (this.detailInfo) {
      this.dataProcessing();
    }
  },
  watch: {
    detailInfo: function detailInfo(val) {
      if (val) {
        this.dataProcessing();
      }
    }
  }
};
exports.default = _default;