"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
var _default2 = {
  props: {
    value: {
      type: Number,
      default: 1
    },
    tabs: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  name: 'OrderMenu',
  model: {
    prop: 'value',
    event: 'setValue',
    clickMode: false
  },
  data: function data() {
    return {
      menuList: [{
        label: '待处理',
        value: 1
      }, {
        label: '进行中',
        value: 2
      }, {
        label: '已退单',
        value: 3
      }, {
        label: '已完成',
        value: 4
      }, {
        label: '全部',
        value: 0
      }]
    };
  },
  created: function created() {
    if (this.tabs.length > 0) {
      this.menuList = (0, _toConsumableArray2.default)(this.tabs);
    }
    console.log(this.category, this.tabs);
  },
  methods: {
    handleSwitchMenu: function handleSwitchMenu(val) {
      if (val === this.value) {
        return false;
      }
      this.$router.push({
        path: this.$route.path + '?group=' + val
      });
      // window.history.replaceState(null, null, this.$route.fullPath+'?group='+val)
      this.$emit('setValue', val);
      this.$emit('change', val);
    }
  },
  computed: {
    queryGroupId: function queryGroupId() {
      var id = this.value;
      if (this.$route.query.group != this.value) {
        id = parseInt(this.$route.query.group);
      }
      return id;
    }
  },
  watch: {
    queryGroupId: function queryGroupId(nVal, oVal) {
      if (nVal !== oVal && nVal !== this.value && nVal) {
        this.$emit('setValue', nVal);
        this.$emit('change', nVal);
      }
    }
  }
};
exports.default = _default2;