import { render, staticRenderFns } from "./ISCSuppilerOnlyReceive.vue?vue&type=template&id=4df116ce&scoped=true&"
import script from "./ISCSuppilerOnlyReceive.vue?vue&type=script&lang=js&"
export * from "./ISCSuppilerOnlyReceive.vue?vue&type=script&lang=js&"
import style0 from "./ISCSuppilerOnlyReceive.vue?vue&type=style&index=0&id=4df116ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4df116ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4df116ce')) {
      api.createRecord('4df116ce', component.options)
    } else {
      api.reload('4df116ce', component.options)
    }
    module.hot.accept("./ISCSuppilerOnlyReceive.vue?vue&type=template&id=4df116ce&scoped=true&", function () {
      api.rerender('4df116ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/OrderForm/isc/ISCSuppilerOnlyReceive.vue"
export default component.exports