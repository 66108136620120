"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _order = require("@/api/order");
var _order2 = require("@/api/order2");
var _OrderMenu = _interopRequireDefault(require("@/views/components/OrderMenu"));
var _Material = require("@/api/Material");
var _Product = require("@/api/Product");
var _TimeState = _interopRequireDefault(require("@/views/components/TimeState"));
var _dayjs = _interopRequireDefault(require("dayjs"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _this = this;
    return {
      dayjs: _dayjs.default,
      // longin
      loading: false,
      // 表单绑定的数据
      supplierProcurement: {
        // 工单号
        OrderNo: "",
        // 工单状态
        Status: null,
        // 店铺编号
        ShopCode: "",
        OrderStatusGroup: 1,
        // 店铺名称
        ShopName: "",
        // 时间
        startTime: [],
        OrderProcessStartTime: "",
        OrderProcessEndTime: "",
        ProcessDateTimeStart: "",
        ProcessDateTimeEnd: "",
        OrderYearAndMonth: "",
        // 物料类型 id
        ProductTypeID: null,
        // 分页 当前几页
        PageIndex: 1,
        // 分页 每页条数
        PageSize: 10,
        // 总数据 总条数据
        total: 0,
        OrderBy: "",
        OrderType: "" //asc
      },

      // 工单状态
      ticketStatuss: [],
      // 物料类型
      materialTypes: [],
      // 列表数据
      tableData: [],
      processedDateSection: [],
      ProductOrderImport: _Product.ProductOrderImport,
      pickerOptionsStart: {
        disabledDate: function disabledDate(time) {
          if (_this.supplierProcurement.OrderProcessEndTime) {
            return time.getTime() > new Date(_this.supplierProcurement.OrderProcessEndTime).getTime();
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: function disabledDate(time) {
          if (_this.supplierProcurement.OrderProcessStartTime) {
            return time.getTime() < new Date(_this.supplierProcurement.OrderProcessStartTime).getTime() - 86400000;
          }
        }
      }
    };
  },
  components: {
    OrderMenu: _OrderMenu.default,
    TimeState: _TimeState.default
  },
  inject: ["$openMainLoading", "$closeMainLoading"],
  methods: {
    //  表格取数据
    supplierData: function supplierData() {
      var _this2 = this;
      var proms = {
        DepartmentCode: "adidasDP004",
        OrderNo: this.supplierProcurement.OrderNo,
        Status: this.supplierProcurement.Status - 0 || -1,
        OrderStatusGroup: this.supplierProcurement.OrderStatusGroup,
        ShopCode: this.supplierProcurement.ShopCode,
        ShopName: this.supplierProcurement.ShopName,
        ProductTypeID: this.supplierProcurement.ProductTypeID,
        ProcessStartTime: this.supplierProcurement.startTime ? this.supplierProcurement.startTime[0] : "",
        ProcessEndTime: this.supplierProcurement.startTime ? this.supplierProcurement.startTime[1] : "",
        ProcessDateTimeStart: this.processedDateSection[0],
        ProcessDateTimeEnd: this.processedDateSection[1],
        OrderProcessStartTime: this.supplierProcurement.OrderProcessStartTime,
        OrderProcessEndTime: this.supplierProcurement.OrderProcessEndTime,
        PageIndex: this.supplierProcurement.PageIndex,
        PageSize: this.supplierProcurement.PageSize,
        OrderBy: this.supplierProcurement.OrderBy,
        OrderType: this.supplierProcurement.OrderType,
        OrderYearAndMonth: this.supplierProcurement.OrderYearAndMonth
      };
      var invokeMethod = this.supplierProcurement.OrderStatusGroup == 0 ? _order.GetAllGroupOrderListByStatusGroup : _order.GetOrderListByStatusGroup;
      this.$openMainLoading();
      invokeMethod(proms).then(function (res) {
        _this2.$closeMainLoading();
        if (res.code == 0) {
          var data = res.dataList || [];
          _this2.tableData = data;
          _this2.supplierProcurement.total = res.Total;
        } else {
          _this2.$message.error(res.msg);
        }
      }).catch(function (err) {
        _this2.$closeMainLoading();
      });
    },
    handleDownloadTemplate: function handleDownloadTemplate() {
      window.open(_order2.DownloadLogisticsTemplate);
    },
    // 物料类型
    materialType: function materialType() {
      var _this3 = this;
      // 选定 rx 店铺
      // 传死
      var postdata = {
        category: "PurchaseProduct"
      };
      (0, _Product.GetTreeList)(postdata).then(function (res) {
        var data = res.data;
        if (data.code !== 0) {
          return _this3.$message.error("网络异常");
        }
        console.log("物料类型获取", res);
        _this3.materialTypes = res.data.dataList;
      }).catch(function (err) {
        console.log("接口问题", err);
      });
    },
    // 工单状态
    ticketStatus: function ticketStatus() {
      var _this4 = this;
      var params = {
        // 需要与后端约定 orderStatusGroup 默认为0 是显示全部工单状态
        OrderStatusGroup: this.supplierProcurement.OrderStatusGroup,
        // 写死 部门
        departmentCode: "adidasDP004"
      };
      (0, _order.GetOrderStatusByStatusGroup)(params).then(function (res) {
        var data = res.data;
        if (data.code !== 0) return _this4.$message.error("网络异常");
        console.log("工单状态获取成功", res);
        _this4.ticketStatuss = data.data;
      }).catch(function (err) {
        console.log("接口问题", err);
      });
    },
    handleClick: function handleClick(e) {
      // console.log("切换了一次");
      // this.supplierProcurement.OrderStatusGroup = e.name - 0;
      // console.log(this.supplierProcurement.OrderStatusGroup);
      this.clearData();
      this.ticketStatus();
      this.supplierData();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.supplierProcurement.PageSize = val;
      this.supplierData();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.supplierProcurement.PageIndex = val;
      this.supplierData();
    },
    // 检索
    search: function search() {
      this.supplierProcurement.PageIndex = 1;
      this.supplierProcurement.total = 0;
      this.tableData = [];
      this.supplierData();
    },
    // 导出
    batchExport: function batchExport() {
      var _this5 = this;
      var queryEntity = {
        DepartmentCode: "adidasDP004",
        OrderNo: this.supplierProcurement.OrderNo,
        Status: this.supplierProcurement.Status || -1,
        OrderStatusGroup: this.supplierProcurement.OrderStatusGroup || -1,
        ShopCode: this.supplierProcurement.ShopCode,
        ShopName: this.supplierProcurement.ShopName,
        ProductTypeID: this.supplierProcurement.ProductTypeID,
        ProcessStartTime: this.supplierProcurement.startTime ? this.supplierProcurement.startTime[0] : "",
        ProcessEndTime: this.supplierProcurement.startTime ? this.supplierProcurement.startTime[1] : "",
        OrderProcessStartTime: this.supplierProcurement.OrderProcessStartTime,
        OrderProcessEndTime: this.supplierProcurement.OrderProcessEndTime,
        PageIndex: this.supplierProcurement.PageIndex,
        PageSize: this.supplierProcurement.PageSize,
        OrderBy: this.supplierProcurement.OrderBy,
        OrderType: this.supplierProcurement.OrderType,
        OrderYearAndMonth: this.supplierProcurement.OrderYearAndMonth
      };
      var invokeMethod = this.supplierProcurement.OrderStatusGroup == 0 ? _order.ExportAllGroupList : _order2.ExportOrder;
      this.$openMainLoading();
      invokeMethod(queryEntity).then(function (res) {
        _this5.$closeMainLoading();
        if (res.status == 200) {
          var blob = new Blob([res.data], {
            type: res.headers["content-type"]
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = res.headers.filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        } else if (res.status == 204) {
          _this5.$message.warning("当前状态下没有数据");
        } else {
          _this5.$message.warning("导出失败");
        }
      }).catch(function (err) {
        _this5.$closeMainLoading();
      });
    },
    process: function process(event, file, fileList) {
      this.loading = true;
    },
    //
    success: function success(e) {
      var _this6 = this;
      this.loading = false;
      if (e.code == 0) {
        this.$alert("物流单上传成功", "提示", {
          confirmButtonText: "确定",
          callback: function callback(action) {
            _this6.search();
          }
        });
      } else {
        this.$alert("物流单上传失败", "提示", {
          confirmButtonText: "确定"
        });
      }
    },
    error: function error() {
      this.$alert("物流单上传失败", "提示", {
        confirmButtonText: "确定"
      });
    },
    //  进入查看详情
    viewDetails: function viewDetails(orderId) {
      this.$router.push({
        path: "/rxsupply/order/" + orderId
      });
    },
    // 清除数据
    clearData: function clearData() {
      this.supplierProcurement.OrderNo = "";
      this.supplierProcurement.Status = "";
      this.supplierProcurement.DepartmentID = "";
      this.supplierProcurement.shopName = "";
      this.supplierProcurement.ShopCode = "";
      this.supplierProcurement.startTime = [];
      this.supplierProcurement.ProductTypeID = "";
      this.supplierProcurement.PageIndex = 1;
      // this.supplierProcurement.OrderProcessStartTime = "";
      // this.supplierProcurement.OrderProcessEndTime = "";
      this.tableData = [];
      this.supplierProcurement.total = 0;
      this.ticketStatuss = [];
      this.processedDateSection = [];
      this.initProcessTime();
    },
    //排序
    handleSortchange: function handleSortchange(_ref) {
      var column = _ref.column,
        prop = _ref.prop,
        order = _ref.order;
      this.supplierProcurement.OrderBy = prop;
      this.supplierProcurement.OrderType = order == "descending" ? "desc" : "asc";
      console.log(this.supplierProcurement, prop, order);
      this.search();
    },
    handleChangeProcessedDateSection: function handleChangeProcessedDateSection() {
      if (this.processedDateSection && this.processedDateSection.length) {
        this.supplierProcurement.ProcessDateTimeStart = this.processedDateSection[0];
        this.supplierProcurement.ProcessDateTimeEnd = this.processedDateSection[1];
      } else {
        this.supplierProcurement.ProcessDateTimeStart = "";
        this.supplierProcurement.ProcessDateTimeEnd = "";
      }
    },
    initProcessTime: function initProcessTime() {
      if (this.supplierProcurement.OrderStatusGroup == 0) {
        this.supplierProcurement.startTime = [(0, _dayjs.default)().startOf('month').format("YYYY-MM-DD"), (0, _dayjs.default)().format("YYYY-MM-DD")];
      } else {
        this.supplierProcurement.startTime = [];
      }
    }
  },
  created: function created() {
    if (this.$route.query.group) {
      this.supplierProcurement.OrderStatusGroup = parseInt(this.$route.query.group);
      this.initProcessTime();
    }
    // this.supplierProcurement.OrderProcessStartTime = dayjs().startOf('month').format("YYYY-MM-DD");
    // this.supplierProcurement.OrderProcessEndTime = dayjs().format("YYYY-MM-DD");
    this.materialType();
    this.ticketStatus();
    this.supplierData();
  }
};
exports.default = _default;