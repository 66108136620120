var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "replyFormWrap" }, [
    _c(
      "div",
      { staticClass: "formMain" },
      [
        _c(
          "el-radio-group",
          {
            staticClass: "tabWrap",
            model: {
              value: _vm.replyType,
              callback: function ($$v) {
                _vm.replyType = $$v
              },
              expression: "replyType",
            },
          },
          [_c("el-radio", { attrs: { label: 5010 } }, [_vm._v("接单")])],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          { attrs: { gutter: 40 } },
          [
            _c("el-col", { attrs: { span: 16 } }, [
              _c("div", { staticClass: "formLabel" }, [
                _vm._v("上传图片或者视频(最多上传9个):"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("PhotoAndVideoUpload", {
                    model: {
                      value: _vm.fileList.Pics,
                      callback: function ($$v) {
                        _vm.$set(_vm.fileList, "Pics", $$v)
                      },
                      expression: "fileList.Pics",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "formFooter" },
      [
        _c(
          "el-button",
          {
            staticClass: "main-btn",
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.handleSubmit()
              },
            },
          },
          [_vm._v("提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }