"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExportNotice = ExportNotice;
exports.GetNoticeList = GetNoticeList;
exports.GetNoticeWithStoreStatus = GetNoticeWithStoreStatus;
exports.ImportNoticeAPI = void 0;
exports.SubmitImportNotice = SubmitImportNotice;
exports.SubmitNotice = SubmitNotice;
exports.UpLoadFilesTemplate = exports.UpLoadFilesAPI = void 0;
var _auth = require("@/utils/auth");
var _request = _interopRequireDefault(require("@/utils/request"));
var _qs = _interopRequireDefault(require("qs"));
var _request2 = _interopRequireDefault(require("@/utils/request2"));
// POST api/Notice/GetNoticeList	
function GetNoticeList(data) {
  return (0, _request.default)({
    url: 'api/Notice/GetNoticeList',
    method: 'post',
    //data:qs.stringify(data)
    data: data
  });
}

// POST api/Notice/ExportNotice	
function ExportNotice(data) {
  return (0, _request2.default)({
    url: 'api/Notice/ExportNotice',
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  });
}

// POST api/Notice/SubmitNotice	
function SubmitNotice(data) {
  return (0, _request.default)({
    url: 'api/Notice/SubmitNotice',
    method: 'post',
    //data:qs.stringify(data)
    data: data
  });
}

// GET api/Notice/GetNoticeWithStoreStatus?ID={ID}	
function GetNoticeWithStoreStatus(params) {
  return (0, _request.default)({
    url: 'api/Notice/GetNoticeWithStoreStatus',
    method: 'get',
    params: params
  });
}

// SubmitImportNotice
function SubmitImportNotice(data) {
  return (0, _request.default)({
    url: 'api/Notice/SubmitImportNotice',
    method: 'post',
    data: data
  });
}
var ImportNoticeAPI = process.env.VUE_APP_BASE_API + '/api/Notice/ImportNotice?UserToken=' + (0, _auth.getToken)();
exports.ImportNoticeAPI = ImportNoticeAPI;
var UpLoadFilesAPI = process.env.VUE_APP_BASE_API + '/api/UploadFile/UpLoadFiles?UserToken=' + (0, _auth.getToken)();
exports.UpLoadFilesAPI = UpLoadFilesAPI;
var UpLoadFilesTemplate = process.env.VUE_APP_BASE_API + '/Template/批量上传通知模板.xlsx';
exports.UpLoadFilesTemplate = UpLoadFilesTemplate;