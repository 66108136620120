"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var RXSupplyRouter = [{
  path: '/rxsupply',
  name: 'RXSupply',
  component: _layout.default,
  meta: {
    title: '采购供应商',
    icon: 'order',
    roles: ['adidasrole003']
  },
  redirect: '/rxsupply/order',
  children: [{
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/RXSupply/order/index'));
      });
    },
    name: 'RXSupplyOrder',
    meta: {
      title: '采购订单',
      roles: ['adidasrole003']
    },
    redirect: '/rxsupply/order/index',
    children: [{
      hidden: true,
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/RXSupply/order/list'));
        });
      },
      name: 'RXSupplyOrderList',
      meta: {
        title: '工单列表',
        roles: ['adidasrole003']
      }
    }, {
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/RXSupply/order/detail'));
        });
      },
      name: 'RXSupplyOrderDetail',
      hidden: true,
      meta: {
        title: '工单详情',
        roles: ['adidasrole003']
      }
    }]
  }]
}];
var _default = RXSupplyRouter;
exports.default = _default;