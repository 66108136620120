var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _c("div", { staticClass: "main-title" }, [
        _c("div", { staticClass: "title-name" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              flex: "1",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
            },
          },
          [
            _c("OrderMenu", {
              attrs: { tabs: _vm.tabList },
              on: { change: _vm.handleChangeOrderMenu },
              model: {
                value: _vm.searchForm.OrderStatusGroup,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "OrderStatusGroup", $$v)
                },
                expression: "searchForm.OrderStatusGroup",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "main-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.exportOrder },
                  },
                  [_vm._v("导出")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c("el-input", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: { placeholder: "工单号", clearable: "" },
            model: {
              value: _vm.searchForm.OrderNo,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "OrderNo", $$v)
              },
              expression: "searchForm.OrderNo",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "200px", margin: "0 10px 10px 0" },
              attrs: {
                clearable: "",
                placeholder: "工单状态",
                disabled: _vm.disableChoose,
              },
              model: {
                value: _vm.searchForm.Status,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "Status", $$v)
                },
                expression: "searchForm.Status",
              },
            },
            _vm._l(_vm.statuList, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.StatusText, value: item.Status },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: { placeholder: "店铺编号", clearable: "" },
            model: {
              value: _vm.searchForm.ShopCode,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "ShopCode", $$v)
              },
              expression: "searchForm.ShopCode",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: { placeholder: "店铺名称", clearable: "" },
            model: {
              value: _vm.searchForm.ShopName,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "ShopName", $$v)
              },
              expression: "searchForm.ShopName",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticStyle: { width: "250px", margin: "0 10px 10px 0" },
            attrs: {
              type: "daterange",
              "start-placeholder": "创建时间起",
              "end-placeholder": "创建时间止",
              "default-time": ["00:00:00", "23:59:59"],
              "value-format": "yyyy-MM-dd",
              clearable: "",
            },
            on: { change: _vm.handleChangeDateSection },
            model: {
              value: _vm.dateSection,
              callback: function ($$v) {
                _vm.dateSection = $$v
              },
              expression: "dateSection",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: {
              type: "date",
              "value-format": "yyyy-MM-dd",
              "picker-options": _vm.pickerOptionsStart,
              placeholder: "工单开始日期",
              clearable: "",
            },
            model: {
              value: _vm.searchForm.OrderProcessStartTime,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "OrderProcessStartTime", $$v)
              },
              expression: "searchForm.OrderProcessStartTime",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: {
              type: "date",
              "picker-options": _vm.pickerOptionsEnd,
              "value-format": "yyyy-MM-dd",
              placeholder: "工单结束日期",
              clearable: "",
            },
            model: {
              value: _vm.searchForm.OrderProcessEndTime,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "OrderProcessEndTime", $$v)
              },
              expression: "searchForm.OrderProcessEndTime",
            },
          }),
          _vm._v(" "),
          _vm.searchForm.OrderStatusGroup == 4
            ? _c("el-date-picker", {
                staticStyle: { width: "250px", margin: "0 10px 10px 0" },
                attrs: {
                  type: "daterange",
                  "start-placeholder": "完成处理时间起",
                  "end-placeholder": "完成处理时间止",
                  "default-time": ["00:00:00", "23:59:59"],
                  "value-format": "yyyy-MM-dd",
                  clearable: "",
                },
                on: { change: _vm.handleChangeProcessedDateSection },
                model: {
                  value: _vm.processedDateSection,
                  callback: function ($$v) {
                    _vm.processedDateSection = $$v
                  },
                  expression: "processedDateSection",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-cascader", {
            staticStyle: { width: "250px", margin: "0 10px 10px 0" },
            attrs: {
              filterable: "",
              placeholder: "类型",
              options: _vm.orderTypeList,
              props: {
                multiple: true,
                value: "ID",
                label: "OrderTypeName",
                children: "SonOrderTypes",
                expandTrigger: "hover",
              },
              "collapse-tags": "",
              clearable: "",
            },
            model: {
              value: _vm.searchForm.ProductTypeIDsString,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "ProductTypeIDsString", $$v)
              },
              expression: "searchForm.ProductTypeIDsString",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "main-btn",
              attrs: { type: "primary" },
              on: { click: _vm.handleSearch },
            },
            [_vm._v("检索")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "div",
            { staticClass: "forms" },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, "tooltip-effect": "dark" },
                  on: { "sort-change": _vm.handleSortchange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      width: "120",
                      sortable: "",
                      label: "工单号",
                      prop: "OrderNo",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: { "text-decoration": "underline" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToDetails(scope.row.ID)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.OrderNo) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "120",
                      sortable: "",
                      prop: "CNCode",
                      label: "CNCode",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      sortable: "",
                      width: "120",
                      prop: "STCode",
                      label: "STCode",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "200",
                      sortable: "",
                      prop: "ShopName",
                      label: "店铺名称",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      sortable: "",
                      prop: "CreateDateTime",
                      label: "创建时间",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.CreateDateTime.replace(/T/, " ")
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      sortable: "",
                      prop: "OrderProcessStartTime",
                      label: "工单开始日期",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.OrderProcessStartTime.replace(
                                    /T/,
                                    " "
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      sortable: "",
                      prop: "OrderProcessEndTime",
                      label: "工单结束日期",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.OrderProcessEndTime.replace(
                                    /T/,
                                    " "
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.searchForm.OrderStatusGroup == 4
                    ? _c("el-table-column", {
                        attrs: {
                          width: "160",
                          sortable: "",
                          prop: "ProcessDateTime",
                          label: "完成处理时间",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.ProcessDateTime &&
                                          scope.row.ProcessDateTime.replace(
                                            /T/,
                                            " "
                                          )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2253316042
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      sortable: "",
                      prop: "ProcessEndTime",
                      label: "剩余时间",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("TimeState", {
                              attrs: {
                                show:
                                  _vm.searchForm.OrderStatusGroup != 3 &&
                                  _vm.searchForm.OrderStatusGroup != 4,
                                time: parseInt(scope.row.LeftTime),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      sortable: "",
                      prop: "ReminderNum",
                      label: "催单次数",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "160",
                      sortable: "",
                      prop: "Status",
                      label: "状态",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.StatusText) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      sortable: "",
                      prop: "OptionObjectName",
                      label: "类型",
                      "min-width": "200",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !!_vm.total,
                expression: "!!total",
              },
            ],
            attrs: {
              "current-page": _vm.searchForm.PageIndex,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.searchForm.PageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }