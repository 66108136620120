"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es7.object.values");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order2");
var _store = _interopRequireDefault(require("@/store"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ShopPopup',
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isopen: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      loading: false,
      areaList: [],
      cityList: [],
      storeTypeList: [],
      selectForm: {
        Area: "",
        City: "",
        ShopCode: "",
        ShopName: "",
        ShopType: ""
      },
      dialogVisible: false,
      checked: true,
      storeCollection: [],
      storeList: [],
      isCheckedAll: false,
      isIndeterminate: false,
      storeValues: [],
      storeGroupValues: {},
      storeTotal: 0,
      UpLoadStoreFiles: _order.UpLoadStoreFiles,
      storeAllTotal: 0
    };
  },
  model: {
    prop: 'value',
    event: 'setValue'
  },
  methods: {
    setValue: function setValue(val) {},
    dialogVisibles: function dialogVisibles() {
      this.dialogVisible = !this.dialogVisible;
      this.getStoreData();
    },
    backToTheStore: function backToTheStore() {
      this.getStoreData();
    },
    getStoreData: function getStoreData() {
      var _this = this;
      this.loading = true;
      var total = 0;
      (0, _order.GetStoreListGroup)().then(function (res) {
        _this.loading = false;
        _this.cityList = res.data.CityLists;
        _this.storeTypeList = res.data.TypeLists;
        if (res.data.ShopGroupModels && res.data.ShopGroupModels.length) {
          _this.storeCollection = res.data.ShopGroupModels;
          _this.areaList = _this.storeCollection.map(function (item) {
            total += item.ShopModels.length;
            return item.Key;
          });
          _this.storeAllTotal = total;
          _this.initStoreValue(_this.storeCollection, _this.value);
        }
      }).catch(function () {
        _this.loading = false;
        console.log("全部店铺获取失败");
      });
    },
    handleChangeStore: function handleChangeStore(val, item, data, index) {
      var id = item.ShopID;
      var key = this.storeList[index].Key;
      var length = this.storeList[index].ShopModels.length;
      if (val) {
        this.storeGroupValues[key].push(id);
      } else {
        var i = this.storeGroupValues[key].indexOf(id);
        this.storeGroupValues[key].splice(i, 1);
      }
      if (this.storeGroupValues[key].length === length) {
        this.storeList[index].checked = true;
        this.storeList[index].indeterminate = false;
      } else {
        this.storeList[index].checked = false;
        this.storeList[index].indeterminate = this.storeGroupValues[key].length == 0 ? false : true;
      }
      this.getIsIndeterminate();
    },
    handleChangeArea: function handleChangeArea(val, index) {
      var key = this.storeList[index].Key;
      var list = [];
      this.storeList[index].indeterminate = false;
      this.storeList[index].ShopModels.forEach(function (item) {
        item.checked = val;
        val && list.push(item.ShopID);
      });
      this.storeGroupValues[key] = list;
      this.getIsIndeterminate();
    },
    handleCheckedAll: function handleCheckedAll(val) {
      var _this2 = this;
      this.storeList.forEach(function (item) {
        item.checked = val;
        item.indeterminate = false;
        var list = [];
        item.ShopModels.forEach(function (t) {
          t.checked = val;
          if (val) {
            list.push(t.ShopID);
          }
        });
        _this2.storeGroupValues[item.Key] = list;
      });
      this.isIndeterminate = false;
    },
    getIsIndeterminate: function getIsIndeterminate() {
      var val = false,
        val2 = false;
      var number = 0;
      Object.values(this.storeGroupValues).forEach(function (item) {
        number += item.length;
      });
      if (this.storeTotal && number && this.storeTotal !== number) {
        val = true;
      }
      if (number && number == this.storeTotal) {
        val2 = true;
      }
      this.isCheckedAll = val2;
      this.isIndeterminate = val;
    },
    handleSearch: function handleSearch() {
      var _this3 = this;
      var _this$selectForm = this.selectForm,
        Area = _this$selectForm.Area,
        City = _this$selectForm.City,
        ShopCode = _this$selectForm.ShopCode,
        ShopName = _this$selectForm.ShopName,
        ShopType = _this$selectForm.ShopType;
      var list = [];
      this.storeCollection.forEach(function (item) {
        list.push({
          Key: item.Key,
          indeterminate: false,
          checked: false,
          ShopModels: item.ShopModels.filter(function (t) {
            return _this3.filterStore(t);
          })
        });
      });
      this.initStoreValue(list, []);
    },
    filterStore: function filterStore(item) {
      var _this$selectForm2 = this.selectForm,
        Area = _this$selectForm2.Area,
        City = _this$selectForm2.City,
        ShopCode = _this$selectForm2.ShopCode,
        ShopName = _this$selectForm2.ShopName,
        ShopType = _this$selectForm2.ShopType;
      if (Area && item.Area !== Area) {
        return false;
      }
      if (City && item.City !== City) {
        return false;
      }
      if (ShopCode && item.CNCode.toLowerCase().indexOf(ShopCode.toLowerCase()) == -1 && item.STCode.toLowerCase().indexOf(ShopCode.toLowerCase()) == -1) {
        return false;
      }
      if (ShopType && item.ShopType !== ShopType) {
        return false;
      }
      if (ShopName && item.ShopName.indexOf(ShopName) == -1) {
        return false;
      }
      return true;
    },
    handleDownloadTemplate: function handleDownloadTemplate() {
      window.open(_order.DownloadShopTemplate);
    },
    handleUploadSuccess: function handleUploadSuccess(res) {
      this.$message.success("导入成功");
      this.loading = false;
      if (res.data && res.data.length) {
        var list = res.data.map(function (item) {
          return item.ShopID;
        });
        this.initStoreValue(this.storeCollection, list);
      }
    },
    handleUploadError: function handleUploadError() {
      this.loading = false;
      this.$message.error("导入失败");
    },
    initStoreValue: function initStoreValue(data, list) {
      var _this4 = this;
      var total = 0;
      this.storeList = data.map(function (item) {
        _this4.storeGroupValues[item.Key] = [];
        total += item.ShopModels.length;
        var length = item.ShopModels.length,
          number = 0;
        var group = item.ShopModels.map(function (t) {
          var isChecked = list.indexOf(t.ShopID) > -1;
          if (isChecked) {
            number++;
            _this4.storeGroupValues[item.Key].push(t.ShopID);
          }
          return (0, _objectSpread2.default)({
            checked: isChecked
          }, t);
        });
        console.log(length, number);
        return {
          Key: item.Key,
          indeterminate: length !== number && number != 0,
          checked: length && length === number,
          ShopModels: group
        };
      });
      this.storeTotal = total;
      this.getIsIndeterminate();
    },
    beforeUpload: function beforeUpload() {
      this.loading = true;
    },
    handleSubmit: function handleSubmit() {
      var val = [];
      Object.values(this.storeGroupValues).forEach(function (item) {
        return val = val.concat(item);
      });
      this.$emit('setValue', val);
      this.$emit('change', {
        value: val,
        totle: this.storeAllTotal
      });
      this.dialogVisible = false;
      console.log('Submit', val);
    },
    handleCloseDialog: function handleCloseDialog() {
      this.$emit('update:isopen', false);
    }
  },
  computed: {},
  watch: {
    isopen: function isopen(val) {
      if (val) {
        this.dialogVisible = true;
        this.getStoreData();
        this.selectForm.Area = "";
        this.selectForm.City = "";
        this.selectForm.ShopCode = "";
        this.selectForm.ShopName = "";
        this.selectForm.ShopType = "";
      }
    }
  }
};
exports.default = _default2;