"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _report = require("@/api/report");
var _vuex = require("vuex");
var _vueEcharts = _interopRequireDefault(require("vue-echarts"));
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/component/title");
var _date = require("@/utils/date");
var _CircleProgress = _interopRequireDefault(require("@/components/CircleProgress"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  inject: ["$openMainLoading", "$closeMainLoading"],
  data: function data() {
    return {
      title: "PP Dashboard",
      loading: false,
      currentTime: "本月",
      monthRange: [],
      eventSummaryData: {},
      repairSummaryData: {},
      summaryList: [],
      materialType: "",
      condition: {
        DepartmentID: 3,
        StartDateTime: "",
        EndDateTime: ""
      },
      eventReportByMonthChartObj: {},
      shopEventCategoryByMonthChartObj: {},
      repairReportCategoryByMonthChartObj: {},
      repairReportByMonthChartObj: {},
      line: {},
      getPercent: _utils.getPercent
    };
  },
  components: {
    "v-chart": _vueEcharts.default,
    CircleProgress: _CircleProgress.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["chartColors"])),
  methods: {
    monthChange: function monthChange(val) {
      var start = (0, _date.GetMonthStartAndEnd)(val[0]);
      var end = (0, _date.GetMonthStartAndEnd)(val[1]);
      this.monthRange = [start[0], end[1]];
    },
    dateChange: function dateChange(val) {
      var range = [,];
      switch (val) {
        case "本月":
          range = (0, _date.CurrentMonthOfStartAndEnd)();
          break;
        case "本季":
          range = (0, _date.CurrentQuarterlyOfStartAndEnd)();
          break;
        case "本年":
          range = (0, _date.CurrentYearOfStartAndEnd)();
          break;
      }
      this.monthRange = (0, _toConsumableArray2.default)(range);
    },
    getPPOrderReport: function getPPOrderReport($data) {
      var _this = this;
      this.$openMainLoading();
      (0, _report.GetPPOrderReportByParam)($data).then(function (res) {
        _this.$closeMainLoading();
        var data = res.data;
        if (res.code !== 0) {
          return _this.$message.error("网络异常");
        } else {
          _this.repairSummaryData = data && data.OrderCategoryReportList && data.OrderCategoryReportList[0] || {};
          _this.eventSummaryData = data && data.OrderCategoryReportList && data.OrderCategoryReportList[1] || {};
          var polar = {
            color: _this.chartColors,
            tooltip: {
              trigger: "item",
              formatter: "{b}: {c}"
            },
            series: [{
              type: "pie",
              radius: ["50%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center"
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "14",
                  fontWeight: "bold"
                }
              },
              labelLine: {
                show: false
              },
              data: []
            }]
          };
          _this.summaryList = [];
          polar.series[0].data = [{
            value: _this.eventSummaryData.TotalNum,
            name: "总工单"
          }, {
            value: _this.eventSummaryData.CompleteNum,
            name: "完成工单"
          }];
          var c1 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c1));
          polar.series[0].data = [{
            value: _this.eventSummaryData.TotalNum,
            name: "总工单"
          }, {
            value: _this.eventSummaryData.TimeOutNum,
            name: "超时工单"
          }];
          var c2 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c2));
          polar.series[0].data = [{
            value: _this.repairSummaryData.TotalNum,
            name: "总工单"
          }, {
            value: _this.repairSummaryData.CompleteNum,
            name: "完成工单"
          }];
          var c3 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c3));
          polar.series[0].data = [{
            value: _this.repairSummaryData.TotalNum,
            name: "总工单"
          }, {
            value: _this.repairSummaryData.TimeOutNum,
            name: "超时工单"
          }];
          var c4 = JSON.stringify(polar);
          _this.summaryList.push(JSON.parse(c4));
          _this.eventReportByMonthChart(data && data.MonthReportList);
          _this.shopEventCategoryByMonthChart(data && data.ShopTypeOrderCategoryReportList);
          _this.repairReportByMonthChart(data && data.ShopTypeOrderCategoryReportList);
          _this.repairReportCategoryByMonthChart(data && data.MonthReportList);
        }
      }).catch(function (err) {
        _this.$closeMainLoading();
        console.log("接口问题", err);
      });
    },
    eventReportByMonthChart: function eventReportByMonthChart(data) {
      var obj = {
        color: this.chartColors,
        title: {
          text: "事件上报",
          left: "center"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },

        legend: {
          data: [],
          bottom: "0%",
          left: "center",
          type: "scroll"
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: []
        },
        yAxis: [{
          type: "value"
        }],
        series: []
      };
      // data.forEach((item) => {
      //   console.log(
      //     item.OrderCreateTimeMonth,
      //     item.OrderCategoryName,
      //     item.OptionObjectName,
      //     item.TotalNum
      //   );
      // });

      var legendData = [],
        //"事件类别"
        xseriesList = []; //月份
      if (data && data.length > 0) {
        data.forEach(function (val) {
          if (val.OrderCategoryName.indexOf("事件") > -1) {
            legendData.push(val.OptionObjectName);
            xseriesList.push(val.OrderCreateTimeMonth);
          }
        });
      }
      obj.legend.data = (0, _toConsumableArray2.default)(new Set(legendData)); //去重复
      obj.xAxis.data = (0, _toConsumableArray2.default)(new Set(xseriesList)); //去重复

      var seriesList = [];
      obj.legend.data.forEach(function (eventType) {
        var chartObj = {
          name: eventType,
          //事件类别
          type: "bar",
          stack: "event",
          // label: {
          //   show: true,
          //   formatter: "{a}:{c}",
          // },
          data: [obj.xAxis.data.length] //事件
        };

        obj.xAxis.data.forEach(function (z) {
          data.forEach(function (item) {
            if (item.OptionObjectName == eventType && item.OrderCategoryName.indexOf("事件") > -1 && z == item.OrderCreateTimeMonth) {
              chartObj.data.splice(obj.xAxis.data.indexOf(item.OrderCreateTimeMonth), 1, item.TotalNum);
            }
          });
        });
        seriesList.push(chartObj);
      });
      obj.series = seriesList;
      this.eventReportByMonthChartObj = obj;
    },
    shopEventCategoryByMonthChart: function shopEventCategoryByMonthChart(data) {
      var obj = {
        color: this.chartColors,
        title: {
          text: "店铺事件类型统计",
          left: "center"
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: [],
          bottom: 0,
          type: "scroll"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: "value"
        },
        series: []
      };
      var legendData = [],
        //"店铺类别"
        xseriesList = []; //类型
      if (data && data.length > 0) {
        data.forEach(function (val) {
          if (val.OrderCategoryName.indexOf("事件") > -1) {
            legendData.push(val.ShopName);
            xseriesList.push(val.OptionObjectName);
          }
        });
      }
      obj.legend.data = (0, _toConsumableArray2.default)(new Set(legendData)); //去重复
      obj.xAxis.data = (0, _toConsumableArray2.default)(new Set(xseriesList)); //去重复

      var seriesList = [];
      obj.legend.data.forEach(function (shopType) {
        var chartObj = {
          name: shopType,
          //店铺类别
          type: "line",
          stack: "event",
          data: [obj.xAxis.data.length]
        };
        obj.xAxis.data.forEach(function (z) {
          data.forEach(function (item) {
            if (item.ShopName == shopType && z == item.OptionObjectName) {
              chartObj.data.splice(obj.xAxis.data.indexOf(item.OptionObjectName), 1, item.TotalNum);
            }
          });
        });
        seriesList.push(chartObj);
      });
      obj.series = seriesList;
      this.shopEventCategoryByMonthChartObj = obj;
    },
    repairReportCategoryByMonthChart: function repairReportCategoryByMonthChart(data) {
      var obj = {
        color: this.chartColors,
        title: {
          text: "维修",
          left: "center"
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: [],
          bottom: 0,
          type: "scroll"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: "value"
        },
        series: []
      };
      var legendData = [],
        //"维修类别"
        xseriesList = []; //月份
      if (data && data.length > 0) {
        data.forEach(function (val) {
          if (val.OrderCategoryName.indexOf("维修") > -1) {
            legendData.push(val.OptionObjectName);
            xseriesList.push(val.OrderCreateTimeMonth);
          }
        });
      }
      obj.legend.data = (0, _toConsumableArray2.default)(new Set(legendData)); //去重复
      obj.xAxis.data = (0, _toConsumableArray2.default)(new Set(xseriesList)); //去重复

      var seriesList = [];
      obj.legend.data.forEach(function (eventType) {
        var chartObj = {
          name: eventType,
          //维修类别
          type: "line",
          stack: "event",
          data: [obj.xAxis.data.length] //维修
        };

        obj.xAxis.data.forEach(function (z) {
          console.log(z);
          data.forEach(function (item) {
            if (item.OptionObjectName == eventType && item.OrderCategoryName.indexOf("维修") > -1 && z == item.OrderCreateTimeMonth) {
              chartObj.data.splice(obj.xAxis.data.indexOf(item.OrderCreateTimeMonth), 1, item.TotalNum);
            }
          });
        });
        seriesList.push(chartObj);
      });
      obj.series = seriesList;
      this.repairReportCategoryByMonthChartObj = obj;
    },
    repairReportByMonthChart: function repairReportByMonthChart(data) {
      var obj = {
        color: this.chartColors,
        title: {
          text: "店铺维修类型统计",
          left: "center"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },

        legend: {
          data: [],
          bottom: 0,
          type: "scroll"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            interval: 0,
            // rotate:30,
            formatter: function formatter(value, index) {
              var str = value;
              if (str.length > 4) {
                str = str.substring(0, 4) + "...";
              }
              return str;
            }
          }
        },
        yAxis: [{
          type: "value"
        }],
        series: []
      };

      // data.forEach((item) => {
      //   console.log(
      //     item.OrderCreateTimeMonth,
      //     item.OrderCategoryName,
      //     item.OptionObjectName,
      //     item.TotalNum
      //   );
      // });

      var legendData = [],
        //"店铺类别"
        xseriesList = []; //类型
      if (data && data.length > 0) {
        data.forEach(function (val) {
          if (val.OrderCategoryName.indexOf("维修") > -1) {
            legendData.push(val.ShopName);
            xseriesList.push(val.OptionObjectName);
          }
        });
      }
      obj.legend.data = (0, _toConsumableArray2.default)(new Set(legendData)); //去重复
      obj.xAxis.data = (0, _toConsumableArray2.default)(new Set(xseriesList)); //去重复

      var seriesList = [];
      obj.legend.data.forEach(function (shopType) {
        var chartObj = {
          name: shopType,
          //店铺类别
          type: "bar",
          stack: "event",
          data: [obj.xAxis.data.length]
        };
        obj.xAxis.data.forEach(function (z) {
          data.forEach(function (item) {
            if (item.ShopName == shopType && z == item.OptionObjectName) {
              chartObj.data.splice(obj.xAxis.data.indexOf(item.OptionObjectName), 1, item.TotalNum);
            }
          });
        });
        seriesList.push(chartObj);
      });
      obj.series = seriesList;
      this.repairReportByMonthChartObj = obj;
    },
    search: function search() {
      this.condition.StartDateTime = this.monthRange[0];
      this.condition.EndDateTime = this.monthRange[1];
      this.getPPOrderReport(this.condition);
    }
  },
  created: function created() {
    this.monthRange = (0, _toConsumableArray2.default)((0, _date.CurrentMonthOfStartAndEnd)());
    this.condition.StartDateTime = this.monthRange[0];
    this.condition.EndDateTime = this.monthRange[1];
    this.getPPOrderReport(this.condition);
  }
};
exports.default = _default;