"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _order = require("@/api/order2");
var _moment = _interopRequireDefault(require("moment"));
var _OrderFile = _interopRequireDefault(require("../OrderFile"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "OrderProcess",
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  inject: ["$openMainLoading", "$closeMainLoading"],
  components: {
    OrderFile: _OrderFile.default
  },
  data: function data() {
    return {
      processList: [],
      // loading: false,
      remark: ""
    };
  },
  methods: {
    getProcessData: function getProcessData() {
      var _this = this;
      var postData = {
        ID: this.orderId
      };
      this.$openMainLoading();
      (0, _order.GetProccess)(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.processList = res.data;
      }).catch(function () {
        _this.$closeMainLoading();
      });
    },
    handleSubmitRemark: function handleSubmitRemark() {
      var _this2 = this;
      if (!this.remark.trim()) {
        this.$message.error("请填写备注信息");
        return false;
      }
      this.$openMainLoading();
      (0, _order.SubmitRemark)({
        orderID: this.orderId,
        remark: this.remark
      }).then(function (res) {
        _this2.$closeMainLoading();
        _this2.remark = "";
        _this2.$message.success("备注提交成功");
        _this2.getProcessData();
      }).catch(function (err) {
        _this2.$closeMainLoading();
      });
    },
    formatterDate: function formatterDate(data) {
      return data ? (0, _moment.default)(data).format("yyyy-MM-DD HH:mm:ss") : "";
    }
  },
  created: function created() {
    this.getProcessData();
  }
};
exports.default = _default;