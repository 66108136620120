"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetEHSOrderType = GetEHSOrderType;
exports.GetOrderDetailByID = GetOrderDetailByID;
exports.GetOrderListByStatusGroup = GetOrderListByStatusGroup;
exports.SubmitEHSAndPPOrderForwarding = SubmitEHSAndPPOrderForwarding;
exports.SubmitEHSAndPPOrderReplyOption = SubmitEHSAndPPOrderReplyOption;
var _request = _interopRequireDefault(require("@/utils/request"));
// GET /api/EHS/GetOrderDetailByID
// 根据工单ID 获取工单明细
function GetOrderDetailByID(params) {
  return (0, _request.default)({
    url: '/api/EHS/GetOrderDetailByID',
    method: 'get',
    params: params
  });
}

// POST /api/EHS/GetEHSOrderType
function GetEHSOrderType(data) {
  return (0, _request.default)({
    url: '/api/EHS/GetEHSOrderType',
    method: 'post',
    data: data
  });
}
// POST /api/EHS/GetOrderListByStatusGroup
function GetOrderListByStatusGroup(data) {
  return (0, _request.default)({
    url: '/api/EHS/GetOrderListByStatusGroup',
    method: 'post',
    data: data
  });
}

// POST /api/EHS/SubmitEHSAndPPOrderForwarding
// EHSPP 工单转发操作
function SubmitEHSAndPPOrderForwarding(data) {
  return (0, _request.default)({
    url: '/api/EHS/SubmitEHSAndPPOrderForwarding',
    method: 'post',
    data: data
  });
}

// POST /api/EHS/SubmitEHSAndPPOrderReplyOption
// EHSPP 提交工单回复操作(跟进/退单/关闭)
function SubmitEHSAndPPOrderReplyOption(data) {
  return (0, _request.default)({
    url: '/api/EHS/SubmitEHSAndPPOrderReplyOption',
    method: 'post',
    data: data
  });
}