"use strict";

var _interopRequireWildcard = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var API = _interopRequireWildcard(require("@/api/user"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      userIndo: {},
      hideName: true,
      hidePhone: true
    };
  },
  methods: {
    getUsgerInfoDetail: function getUsgerInfoDetail() {
      var _this = this;
      API.GetUserInfoDetail().then(function (res) {
        _this.userIndo = res.data || {};
      });
    }
  },
  created: function created() {
    this.getUsgerInfoDetail();
  }
};
exports.default = _default;