"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.function.name");
var _index = _interopRequireDefault(require("@/views/components/ShopPopup/index"));
var _order = require("@/api/order2");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { EstimatedValueDownload } from "@/api/Material";
var _default = {
  data: function data() {
    return {
      // 小票数据
      smallTicketData: [],
      // 返回店铺的id 数组
      returnShopId: [],
      form: {
        CategoryCode: "",
        CategoryName: "",
        tableData: [],
        OrderDescription: "",
        ShipmentCode: "",
        //运单号
        EndTime: "",
        //收货时间
        ProductBoxNum: "" //箱数
      },

      loading: false,
      dialogVisible: false,
      EstimatedValueImport: _order.EstimatedValueImport,
      smallTicketFileName: "",
      numberOfChoices: "0"
    };
  },
  methods: {
    returns: function returns() {
      this.$router.go(-1);
    },
    handleChangeSeletForm: function handleChangeSeletForm() {
      this.form.tableData = [];
      if (!this.returnShopId || !this.returnShopId.length) {
        return false;
      }
      this.listRendering();
    },
    successs: function successs(e, file) {
      // console.log(file)
      if (e.code == 0) {
        var fileName = file.name;
        this.smallTicketFileName = fileName;
        this.smallTicketData = e.data;
        this.handleChangeSeletForm();
      } else {
        this.smallTicketFileName = "";
        this.$message.error(e.msg || "上传失败");
      }
    },
    errors: function errors() {
      this.smallTicketFileName = "";
      this.$message.error("上传失败");
    },
    templateDownload: function templateDownload() {
      window.open(_order.EstimatedValueDownload);
    },
    // 选择店铺
    choiceShop: function choiceShop() {
      console.log("选择店铺");
      this.$refs["shopPopup"].dialogVisibles();
    },
    // 列表渲染
    listRendering: function listRendering() {
      var _this = this;
      this.loading = true;
      var proms = {
        CategoryName: this.form.CategoryName,
        CategoryCode: this.form.CategoryCode,
        ShopIDs: this.returnShopId,
        PurchaseModels: this.smallTicketData
      };
      (0, _order.PurchaseSelect)(proms).then(function (res) {
        _this.loading = false;
        // this.form.tableData  res code = 0 代表数据正常 成功请求的
        if (res.code !== 0) return false;
        var list = [];
        if (res.data && res.data.PurchaseModels) {
          list = res.data.PurchaseModels.map(function (val, index) {
            val.ProductCategoryName = "收货催单";
            return val;
          });
        }
        _this.form.tableData = list;
      }).catch(function () {
        _this.loading = false;
        console.log("列表渲染失败");
      });
    },
    // 提交工单
    workOrderSubmission: function workOrderSubmission() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (!_this2.returnShopId.length) {
            _this2.$message.warning("请选择门店");
            return false;
          }
          if (!_this2.form.ProductBoxNum) {
            _this2.$message.warning("请填写箱数");
            return false;
          }
          if (!_this2.form.ShipmentCode) {
            _this2.$message.warning("请填发货单号");
            return false;
          } else {
            if (!/^[0-9A-Za-z]+$/.test(_this2.form.ShipmentCode)) {
              _this2.$message.warning("发货单号由英文+数字或纯数字组合");
              return false;
            }
          }
          if (!_this2.form.EndTime) {
            _this2.$message.warning("请选择收货日期");
            return false;
          }
          if (!_this2.form.OrderDescription) {
            _this2.$message.warning("请填写说明");
            return false;
          }
          if (!_this2.form.tableData.length) {
            _this2.$message.warning("当前状态下没有数据");
            return false;
          }
          var postData = {
            ProductCategoryName: _this2.form.CategoryName,
            ProductCategoryCode: _this2.form.CategoryCode,
            PurchaseModels: _this2.form.tableData,
            OrderDescription: _this2.form.OrderDescription,
            ShipmentCode: _this2.form.ShipmentCode,
            EndTime: _this2.form.EndTime,
            ProductBoxNum: _this2.form.ProductBoxNum
          };
          console.log(JSON.stringify(postData));
          _this2.loading = true;
          (0, _order.SCMReceiveProductSubmit)(postData).then(function (res) {
            _this2.loading = false;
            if (res.code !== 0) return false;
            _this2.$message({
              message: "工单创建成功",
              type: "success"
            });
            _this2.returns();
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    addstring: function addstring(e) {
      console.log("addstring", e);
    },
    handleChangeShop: function handleChangeShop(_ref) {
      var value = _ref.value,
        totle = _ref.totle;
      if (value) {
        this.numberOfChoices = "".concat(value.length, "/").concat(totle);
        this.handleChangeSeletForm();
      }
    },
    shipmentCodeInput: function shipmentCodeInput(val) {
      var codeReg = new RegExp("[A-Za-z0-9]+"),
        //正则 英文+数字；
        len = val.length,
        str = "";
      for (var i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i];
        }
      }
      this.form.ShipmentCode = str;
    }
  },
  // 生命周期
  created: function created() {},
  components: {
    shopPopup: _index.default
  },
  computed: {}
};
exports.default = _default;