"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderFormConfig = exports.OrderFormComponentlist = exports.BackAndRejectCause = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var OrderFormComponentlist = {
  RXFormItem: function RXFormItem() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/RXFormItem'));
    });
  },
  EHSEventSub: function EHSEventSub() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/EHSEventSub'));
    });
  },
  EHSStoreReply: function EHSStoreReply() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/EHSStoreReply'));
    });
  },
  CONSupplyFormItem: function CONSupplyFormItem() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/CONSupplyFormItem'));
    });
  },
  ConRepairManagerApprove: function ConRepairManagerApprove() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/ConRepairManagerApprove'));
    });
  },
  ConRepairSupplierOnlyReceive: function ConRepairSupplierOnlyReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/ConRepairSupplierOnlyReceive'));
    });
  },
  ConRepairSupplierOnDoing: function ConRepairSupplierOnDoing() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/ConRepairSupplierOnDoing'));
    });
  },
  //pp
  PPRepairSupplierReceive: function PPRepairSupplierReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairSupplierReceive'));
    });
  },
  PPRepairSupplierOnlyReceive: function PPRepairSupplierOnlyReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairSupplierOnlyReceive'));
    });
  },
  PPRepairSupplierCompleted: function PPRepairSupplierCompleted() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairSupplierCompleted'));
    });
  },
  PPRepairSupplierReReceive: function PPRepairSupplierReReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairSupplierReReceive'));
    });
  },
  PPRepairPOReceive: function PPRepairPOReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairPOReceive'));
    });
  },
  PPRepairPOExtraReceive: function PPRepairPOExtraReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairPOExtraReceive'));
    });
  },
  PPRepairPPManagerApprove: function PPRepairPPManagerApprove() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairPPManagerApprove'));
    });
  },
  PPRepairPPManagerExtraApprove: function PPRepairPPManagerExtraApprove() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairPPManagerExtraApprove'));
    });
  },
  PPRepairPPManagerReturnApprove: function PPRepairPPManagerReturnApprove() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairPPManagerReturnApprove'));
    });
  },
  PPRepairSupplierReCompleted: function PPRepairSupplierReCompleted() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairSupplierReCompleted'));
    });
  },
  PPRepairPOSupplierReceive: function PPRepairPOSupplierReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRepairPOSupplierReceive'));
    });
  },
  //pp远程维修
  PPRemoteRepairManagerApprove: function PPRemoteRepairManagerApprove() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRemoteRepairManagerApprove'));
    });
  },
  PPRemoteRepairSupplierOnlyCompleted: function PPRemoteRepairSupplierOnlyCompleted() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRemoteRepairSupplierOnlyCompleted'));
    });
  },
  PPRemoteRepairSupplierReReceive: function PPRemoteRepairSupplierReReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRemoteRepairSupplierReReceive'));
    });
  },
  PPRemoteRepairManagerReturnApprove: function PPRemoteRepairManagerReturnApprove() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/pp/PPRemoteRepairManagerReturnApprove'));
    });
  },
  //isc
  ISCRMManagerApprove: function ISCRMManagerApprove() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/isc/ISCRMManagerApprove'));
    });
  },
  ISCRMManagerPriceApprove: function ISCRMManagerPriceApprove() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/isc/ISCRMManagerPriceApprove'));
    });
  },
  ISCDOMApprove: function ISCDOMApprove() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/isc/ISCDOMApprove'));
    });
  },
  ISCRMManagerReApprove: function ISCRMManagerReApprove() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/isc/ISCRMManagerReApprove'));
    });
  },
  ISCPOInput: function ISCPOInput() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/isc/ISCPOInput'));
    });
  },
  ISCSuppilerReceive: function ISCSuppilerReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/isc/ISCSuppilerReceive'));
    });
  },
  ISCSuppilerOnlyReceive: function ISCSuppilerOnlyReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/isc/ISCSuppilerOnlyReceive'));
    });
  },
  ISCSuppilerComplete: function ISCSuppilerComplete() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/isc/ISCSuppilerComplete'));
    });
  },
  ISCSuppilerReReceive: function ISCSuppilerReReceive() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/isc/ISCSuppilerReReceive'));
    });
  },
  //催单
  Reminder: function Reminder() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/Reminder'));
    });
  },
  //scm 大仓
  SCMManagerOption: function SCMManagerOption() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/scm/SCMManagerOption'));
    });
  },
  SCMManagerColse: function SCMManagerColse() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/components/OrderForm/scm/SCMManagerColse'));
    });
  }
};
exports.OrderFormComponentlist = OrderFormComponentlist;
var OrderFormConfig = {
  'rxsupply': [{
    status: [1000, 1010, 1005],
    component: 'RXFormItem',
    formType: 'needDeliver'
  }],
  'ehs': [{
    status: [1000],
    component: 'EHSEventSub',
    formType: 'EHSEventSub'
  }, {
    status: [3020],
    component: 'EHSStoreReply',
    formType: 'StoreReply'
  }],
  'pp': [{
    status: [1000],
    component: 'EHSEventSub',
    formType: 'EHSEventSub'
  }, {
    status: [3020],
    component: 'EHSStoreReply',
    formType: 'StoreReply'
  }, {
    component: 'PPRepairPPManagerApprove',
    formType: 'PPRepairPPManagerApprove'
  }, {
    component: 'PPRepairPPManagerExtraApprove',
    formType: 'PPRepairPPManagerExtraApprove'
  }, {
    component: 'PPRepairPPManagerReturnApprove',
    formType: 'PPRepairPPManagerReturnApprove'
  }, {
    component: 'PPRepairPOReceive',
    formType: 'PPRepairPOReceive'
  }, {
    component: 'PPRepairPOExtraReceive',
    formType: 'PPRepairPOExtraReceive'
  },
  //远程维修
  {
    component: 'PPRemoteRepairManagerApprove',
    formType: 'PPRemoteRepairManagerApprove'
  }, {
    component: 'PPRemoteRepairManagerReturnApprove',
    formType: 'PPRemoteRepairManagerReturnApprove'
  }],
  'ppsupply': [{
    formType: 'PPRepairSupplierReceive',
    component: 'PPRepairSupplierReceive'
  }, {
    formType: 'PPRepairSupplierOnlyReceive',
    component: 'PPRepairSupplierOnlyReceive'
  }, {
    formType: 'PPRepairSupplierOnlyReceive',
    component: 'PPRepairSupplierOnlyReceive'
  }, {
    formType: 'PPRepairSupplierCompleted',
    component: 'PPRepairSupplierCompleted'
  }, {
    formType: 'PPRepairSupplierReEdit',
    component: 'PPRepairSupplierCompleted'
  }, {
    formType: 'PPRepairSupplierReReceive',
    component: 'PPRepairSupplierReReceive'
  }, {
    formType: 'PPRepairSupplierReCompleted',
    component: 'PPRepairSupplierReCompleted'
  }, {
    component: 'PPRemoteRepairSupplierOnlyCompleted',
    formType: 'PPRemoteRepairSupplierOnlyCompleted'
  }, {
    component: 'PPRemoteRepairSupplierReReceive',
    formType: 'PPRemoteRepairSupplierReReceive'
  }, {
    component: 'PPRepairPOSupplierReceive',
    formType: 'PPRepairPOSupplierReceive'
  }],
  'consupply': [{
    formType: 'ConRepairSupplierReceive',
    component: 'CONSupplyFormItem'
  }, {
    formType: 'ConRepairSupplierOnlyReceive',
    component: 'ConRepairSupplierOnlyReceive'
  }, {
    formType: 'ConRepairSupplierOnDoing',
    component: 'ConRepairSupplierOnDoing'
  }],
  'con': [{
    formType: 'ConRepairManagerApprove',
    component: 'ConRepairManagerApprove'
  }],
  'mkt': [{
    component: 'ISCRMManagerApprove',
    formType: 'ISCRMManagerApprove'
  }, {
    component: 'ISCRMManagerPriceApprove',
    formType: 'ISCRMManagerPriceApprove'
  }, {
    component: 'ISCDOMApprove',
    formType: 'ISCDOMApprove'
  }, {
    component: 'ISCRMManagerReApprove',
    formType: 'ISCRMManagerReApprove'
  }, {
    component: 'ISCPOInput',
    formType: 'ISCPOInput'
  }],
  'mktsupply': [{
    component: 'ISCSuppilerReceive',
    formType: 'ISCSuppilerReceive'
  }, {
    component: 'ISCSuppilerOnlyReceive',
    formType: 'ISCSuppilerOnlyReceive'
  }, {
    component: 'ISCSuppilerComplete',
    formType: 'ISCSuppilerComplete'
  }, {
    component: 'ISCSuppilerReReceive',
    formType: 'ISCSuppilerReReceive'
  }, {
    component: 'ISCSuppilerComplete',
    formType: 'ISCSuppilerOnlyComplete'
  }],
  'scm': [{
    component: 'SCMManagerOption',
    formType: 'SCMManagerOption'
  }, {
    component: 'SCMManagerColse',
    formType: 'SCMManagerColse'
  }]
};
exports.OrderFormConfig = OrderFormConfig;
var BackAndRejectCause = {
  'EHSAndPP_back': ['重复上报', '提交类型错误', '其他'],
  'PPSupply_back': ['重复上报', '不属于维保范围', '已修复并签收', '其他'],
  'PP_reject': ['退单理由不成立', '其他'],
  'PP_check': ['费用过高', '有其他替代方案', '其他'],
  'PP_repairType': ['上门', '远程'],
  'CONSupply_back': ['重复上报', '不属于维保范围', '已修复并签收', '其他'],
  'CONSupply_postpone': ['现场无法处置需要带回维修', '需要采购零件', '与门店约定时间完成', '其他'],
  'CON_reject': ['退单理由不成立', '其他'],
  'ISCSupply_back': ['重复上报', '不属于维保范围', '已修复并签收', '接错单', '其他'],
  'ISC_reject': ['退单理由不成立', '其他']
};
// 需要采购零件
// 	与门店约定时间完成
// 	其他
exports.BackAndRejectCause = BackAndRejectCause;