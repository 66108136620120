"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var _msal = require("msal");
var _elementUi = require("element-ui");
var config = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority: process.env.VUE_APP_AUTHORITY,
    redirectUri: process.env.VUE_APP_WEB_URL,
    postLogoutRedirectUri: process.env.VUE_APP_WEB_URL
  }
};
console.log("config:", config);
var userAgentApplication = new _msal.UserAgentApplication(config);
var state = {
  token: (0, _auth.getToken)(),
  name: '',
  avatar: '',
  loginMethod: '',
  roles: [],
  userID: '',
  unreadMessageCount: 0,
  nowRole: null,
  roleList: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : obj,
      name = _ref.name,
      supplierName = _ref.supplierName;
    var userName = "";
    //带供应商名称的
    // if (supplierName) {
    //   userName = `${name}(${supplierName})`
    // } else {
    //   userName = name || "";
    // }
    userName = name || "";
    state.name = userName;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_LOGINMETHOD: function SET_LOGINMETHOD(state, method) {
    state.loginMethod = method;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_USERID: function SET_USERID(state, UserID) {
    state.userID = UserID;
  },
  SET_UNREADMESSAGECOUNT: function SET_UNREADMESSAGECOUNT(state, count) {
    state.unreadMessageCount = count;
  },
  SET_NOWROLE: function SET_NOWROLE(state, nowRole) {
    state.nowRole = nowRole;
  },
  SET_ROLELIST: function SET_ROLELIST(state, list) {
    state.roleList = list;
  }
};
var actions = {
  // user login
  login: function login(_ref2, userInfo) {
    var commit = _ref2.commit;
    var userName = userInfo.userName,
      password = userInfo.password,
      idcode = userInfo.idcode;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        Loginname: userName.trim(),
        Password: password,
        IDCode: idcode
      }).then(function (res) {
        var token = res.data;
        commit('SET_TOKEN', token);
        (0, _auth.setToken)(token);
        resolve(token);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  ssoLogin: function ssoLogin(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      var loginRequest = {
        // scopes: ["https://graph.microsoft.com/User.Read"]
      };
      userAgentApplication.loginPopup(loginRequest).then(function (loginResponse) {
        console.log("SSO_TOKEN:", JSON.stringify(loginResponse));
        var postData = {
          Token: loginResponse.idToken.rawIdToken
        };
        console.log("开始请求");
        (0, _elementUi.Message)({
          showClose: true,
          message: 'Getting user information...'
        });
        (0, _user.SSOLogin)(postData).then(function (res) {
          commit('SET_TOKEN', res.data);
          (0, _auth.setToken)(res.data);
          resolve(res.data);
        }).catch(function (err) {
          reject(err);
        });
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    return new Promise(function (resolve, reject) {
      //let avatar = 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
      (0, _user.getLoginUserInfo)().then(function (res) {
        var _res$data = res.data,
          RealName = _res$data.RealName,
          Roles = _res$data.Roles,
          UserID = _res$data.UserID,
          SupplierName = _res$data.SupplierName;
        if (Roles && Roles.length) {
          var Role = [];
          var nowRole = Roles.filter(function (item) {
            return item.IsCurrentRole;
          })[0];
          if (!nowRole) {
            reject(err);
            return false;
          } else {
            Role = [nowRole.RoleCode];
          }
          commit('SET_NOWROLE', nowRole);
          commit('SET_ROLELIST', Roles);
          commit('SET_ROLES', Role || []);
          commit('SET_NAME', {
            name: RealName || 'Admin',
            supplierName: SupplierName
          });
          commit('SET_USERID', UserID || '');
          resolve({
            Role: Role
          });
        } else {
          (0, _elementUi.Message)({
            showClose: true,
            message: '获取角色信息失败',
            type: 'error'
          });
          throw new Error('获取角色信息失败');
        }
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  // user logout
  logout: function logout(_ref5) {
    var commit = _ref5.commit,
      state = _ref5.state;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)().then(function (res) {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', '');
        commit('SET_NAME', '');
        commit('SET_AVATAR', '');
        (0, _auth.removeToken)();
        if (window.ARS_UNREAD_MESSAGE_COUNT_TIMER) {
          clearInterval(window.ARS_UNREAD_MESSAGE_COUNT_TIMER);
        }
        if (state.loginMethod === 'sso') {
          commit('SET_LOGINMETHOD', '');
          userAgentApplication.logout();
        }
        resolve();
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', '');
      commit('SET_NAME', '');
      commit('SET_AVATAR', '');
      commit('SET_USERID', '');
      (0, _auth.removeToken)();
      resolve();
    });
  },
  //unreadMessageCount
  resetUnreadMessageCount: function resetUnreadMessageCount(_ref7, count) {
    var commit = _ref7.commit;
    return new Promise(function (resolve) {
      commit('SET_UNREADMESSAGECOUNT', count);
      resolve();
    });
  },
  //更换用户角色及Token
  updateTokenRole: function updateTokenRole(_ref8, data) {
    var commit = _ref8.commit;
    return new Promise(function (resolve, reject) {
      var RoleID = data.RoleID,
        RoleName = data.RoleName,
        RoleCode = data.RoleCode;
      (0, _user.UpdateUserTokenRole)({
        RoleID: RoleID,
        RoleName: RoleName,
        RoleCode: RoleCode
      }).then(function (res) {
        var nowRole = {
          RoleID: RoleID,
          RoleName: RoleName,
          RoleCode: RoleCode
        };
        commit('SET_NOWROLE', nowRole);
        resolve(res);
      }).catch(function (err) {
        reject();
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;