var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "bulkPurchases main-wrap",
      attrs: { "element-loading-text": "上传中..." },
    },
    [
      _c("div", { staticClass: "main-title" }, [
        _c("div", { staticClass: "title-name" }, [_vm._v("采购订单")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              flex: "1",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
            },
          },
          [
            _c("OrderMenu", {
              on: { change: _vm.handleClick },
              model: {
                value: _vm.supplierProcurement.OrderStatusGroup,
                callback: function ($$v) {
                  _vm.$set(_vm.supplierProcurement, "OrderStatusGroup", $$v)
                },
                expression: "supplierProcurement.OrderStatusGroup",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "el-button",
              {
                staticClass: "underline",
                staticStyle: { "margin-right": "10px" },
                attrs: { type: "text" },
                on: { click: _vm.handleDownloadTemplate },
              },
              [_vm._v("下载物流单模板\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-upload",
              {
                ref: "upload",
                staticStyle: { diplay: "inline-block", "margin-right": "10px" },
                attrs: {
                  action: _vm.ProductOrderImport,
                  "auto-upload": true,
                  "on-success": _vm.success,
                  "on-error": _vm.error,
                  "on-progress": _vm.process,
                  accept: ".xls,.xlsx",
                  "show-file-list": false,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "templateSelection" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "main-btn",
                        attrs: {
                          slot: "trigger",
                          icon: "el-icon-document",
                          type: "subPrimary",
                        },
                        slot: "trigger",
                      },
                      [_vm._v("导入物流单")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "main-btn",
                attrs: { type: "primary" },
                on: { click: _vm.batchExport },
              },
              [_vm._v("导出")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tableLabel" },
        [
          _c("el-input", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: { placeholder: "工单号", clearable: "" },
            model: {
              value: _vm.supplierProcurement.OrderNo,
              callback: function ($$v) {
                _vm.$set(_vm.supplierProcurement, "OrderNo", $$v)
              },
              expression: "supplierProcurement.OrderNo",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "200px", margin: "0 10px 10px 0" },
              attrs: { clearable: "", placeholder: "工单状态" },
              model: {
                value: _vm.supplierProcurement.Status,
                callback: function ($$v) {
                  _vm.$set(_vm.supplierProcurement, "Status", $$v)
                },
                expression: "supplierProcurement.Status",
              },
            },
            _vm._l(_vm.ticketStatuss, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.StatusText, value: item.Status },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: { placeholder: "店铺编号", clearable: "" },
            model: {
              value: _vm.supplierProcurement.ShopCode,
              callback: function ($$v) {
                _vm.$set(_vm.supplierProcurement, "ShopCode", $$v)
              },
              expression: "supplierProcurement.ShopCode",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: { placeholder: "店铺名称", clearable: "" },
            model: {
              value: _vm.supplierProcurement.ShopName,
              callback: function ($$v) {
                _vm.$set(_vm.supplierProcurement, "ShopName", $$v)
              },
              expression: "supplierProcurement.ShopName",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticStyle: { width: "250px", margin: "0 10px 10px 0" },
            attrs: {
              type: "daterange",
              "start-placeholder": "创建时间起",
              "end-placeholder": "创建时间止",
              "default-time": ["00:00:00", "23:59:59"],
              "value-format": "yyyy-MM-dd",
              clearable: "",
            },
            model: {
              value: _vm.supplierProcurement.startTime,
              callback: function ($$v) {
                _vm.$set(_vm.supplierProcurement, "startTime", $$v)
              },
              expression: "supplierProcurement.startTime",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: {
              type: "date",
              "value-format": "yyyy-MM-dd",
              "picker-options": _vm.pickerOptionsStart,
              placeholder: "工单开始日期",
              clearable: "",
            },
            model: {
              value: _vm.supplierProcurement.OrderProcessStartTime,
              callback: function ($$v) {
                _vm.$set(_vm.supplierProcurement, "OrderProcessStartTime", $$v)
              },
              expression: "supplierProcurement.OrderProcessStartTime",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: {
              type: "date",
              "picker-options": _vm.pickerOptionsEnd,
              "value-format": "yyyy-MM-dd",
              placeholder: "工单结束日期",
              clearable: "",
            },
            model: {
              value: _vm.supplierProcurement.OrderProcessEndTime,
              callback: function ($$v) {
                _vm.$set(_vm.supplierProcurement, "OrderProcessEndTime", $$v)
              },
              expression: "supplierProcurement.OrderProcessEndTime",
            },
          }),
          _vm._v(" "),
          _vm.supplierProcurement.OrderStatusGroup == 4
            ? _c("el-date-picker", {
                staticStyle: { width: "250px", margin: "0 10px 10px 0" },
                attrs: {
                  type: "daterange",
                  "start-placeholder": "完成处理时间起",
                  "end-placeholder": "完成处理时间止",
                  "default-time": ["00:00:00", "23:59:59"],
                  "value-format": "yyyy-MM-dd",
                  clearable: "",
                },
                on: { change: _vm.handleChangeProcessedDateSection },
                model: {
                  value: _vm.processedDateSection,
                  callback: function ($$v) {
                    _vm.processedDateSection = $$v
                  },
                  expression: "processedDateSection",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "200px", margin: "0 10px 10px 0" },
              attrs: { clearable: "", placeholder: "物料类型" },
              model: {
                value: _vm.supplierProcurement.ProductTypeID,
                callback: function ($$v) {
                  _vm.$set(_vm.supplierProcurement, "ProductTypeID", $$v)
                },
                expression: "supplierProcurement.ProductTypeID",
              },
            },
            _vm._l(_vm.materialTypes, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.text, value: item.id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-date-picker", {
            staticStyle: { width: "200px", margin: "0 10px 10px 0" },
            attrs: {
              type: "month",
              "value-format": "yyyy-MM",
              placeholder: "工单所属年月",
              clearable: "",
            },
            model: {
              value: _vm.supplierProcurement.OrderYearAndMonth,
              callback: function ($$v) {
                _vm.$set(_vm.supplierProcurement, "OrderYearAndMonth", $$v)
              },
              expression: "supplierProcurement.OrderYearAndMonth",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "main-btn",
              attrs: { type: "primary" },
              on: { click: _vm.search },
            },
            [_vm._v("检索")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "forms" },
          [
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData, "tooltip-effect": "dark" },
                on: { "sort-change": _vm.handleSortchange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    width: "160",
                    sortable: "",
                    label: "工单号",
                    prop: "OrderNo",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: { "text-decoration": "underline" },
                              on: {
                                click: function ($event) {
                                  return _vm.viewDetails(scope.row.ID)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.OrderNo) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    sortable: "",
                    prop: "CNCode",
                    label: "CNCode",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    sortable: "",
                    prop: "STCode",
                    label: "STCode",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    width: "200",
                    prop: "ShopName",
                    label: "店铺名称",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "CreateDateTime",
                    label: "创建时间",
                    width: "160",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(scope.row.CreateDateTime.replace(/T/, " "))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "OrderYearAndMonth",
                    label: "工单所属年月",
                    width: "160",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(scope.row.OrderYearAndMonth)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "160",
                    sortable: "",
                    prop: "OrderProcessStartTime",
                    label: "工单开始日期",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row.OrderProcessStartTime.replace(
                                  /T/,
                                  " "
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "160",
                    sortable: "",
                    prop: "OrderProcessEndTime",
                    label: "工单结束日期",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row.OrderProcessEndTime.replace(/T/, " ")
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _vm.supplierProcurement.OrderStatusGroup == 4
                  ? _c("el-table-column", {
                      attrs: {
                        width: "160",
                        sortable: "",
                        prop: "ProcessDateTime",
                        label: "完成处理时间",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.ProcessDateTime &&
                                        scope.row.ProcessDateTime.replace(
                                          /T/,
                                          " "
                                        )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2253316042
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "ProcessEndTime",
                    label: "剩余时间",
                    width: "160",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("TimeState", {
                            attrs: {
                              show:
                                _vm.supplierProcurement.OrderStatusGroup != 3 &&
                                _vm.supplierProcurement.OrderStatusGroup != 4,
                              time: parseInt(scope.row.LeftTime),
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "Status",
                    label: "状态",
                    width: "160",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.StatusText) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "OptionObjectName",
                    label: "类型",
                    "min-width": "200",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paging" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !!_vm.supplierProcurement.total,
                expression: "!!supplierProcurement.total",
              },
            ],
            attrs: {
              "current-page": _vm.supplierProcurement.PageIndex,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.supplierProcurement.PageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.supplierProcurement.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }