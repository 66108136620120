"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ISCPOInput",
  data: function data() {
    return {
      replyType: 4021,
      POcode: "",
      replyContent: ""
    };
  },
  inject: ['$openMainLoading', '$closeMainLoading', 'updateDetailsPage', 'orderBasicData'],
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      if (!this.POcode) {
        this.$message.warning('请输入PO单号');
        return false;
      }
      var postData = {
        NextOrderStatus: this.replyType,
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderCode,
          // ReplyContent: "PO单号："+this.POcode,
          ReplyCategoryName: "录入PO单号"
        },
        attachmentList: [],
        ExtraPOcode: this.POcode
      };
      this.$openMainLoading();
      (0, _order.SubmitOrderReplyOption)(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: '提交成功',
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    }
  }
};
exports.default = _default;