"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _order = require("@/api/order");
var _orderFormConfig = require("@/views/components/OrderForm/orderFormConfig");
var _construction = require("@/api/construction");
var _PhotoAndVideoUpload = _interopRequireDefault(require("@/views/components/PhotoAndVideoUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "PPRepairSupplierReReceive",
  data: function data() {
    return {
      replyTypeList: [{
        label: "完成",
        value: 6080
      },
      // {
      //   label: "延期处置",
      //   value: 6040,
      // },
      {
        label: "退单",
        value: 6091
      }],
      replyType: 6080,
      replyContent: '',
      returnCauseList: _orderFormConfig.BackAndRejectCause.CONSupply_postpone,
      returnCauseList2: _orderFormConfig.BackAndRejectCause.PPSupply_back,
      returnCauseValue: "",
      returnCauseValue2: "",
      fileList: {
        Pics: [],
        Atts: []
      },
      extraCharges: null,
      replyCategroyDateTime: '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now();
        }
      }
    };
  },
  components: {
    PhotoAndVideoUpload: _PhotoAndVideoUpload.default
  },
  inject: ['$openMainLoading', '$closeMainLoading', 'updateDetailsPage', 'orderBasicData'],
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      if (this.replyType == 6040 && !this.returnCauseValue) {
        //延期
        this.$message.warning('请选择延期原因');
        return false;
      }
      if (this.replyType == 6091 && !this.returnCauseValue2) {
        //退单
        this.$message.warning('请选择退单原因');
        return false;
      }
      if (this.replyType == 6040 && !this.replyCategroyDateTime) {
        //延期日期
        this.$message.warning('请选择延期日期');
        return false;
      }
      if (!this.replyContent && this.replyType !== 6080) {
        this.$message.warning('请输入描述信息');
        return false;
      }
      if (this.replyType == 6080 && !this.fileList.Pics.length) {
        this.$message.warning('请上传图片信息');
        return false;
      }
      var postData = {
        NextOrderStatus: this.replyType,
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderNo,
          ReplyReasonName: this.replyType == 6040 ? this.returnCauseValue : "",
          ReplyContent: this.replyContent,
          ReplyCategroyDateTime: this.replyType == 6040 ? this.replyCategroyDateTime : '',
          ReplyCategoryName: this.getReplyCategoryName()
        },
        attachmentList: [].concat((0, _toConsumableArray2.default)(this.fileList.Pics), (0, _toConsumableArray2.default)(this.fileList.Atts))
      };
      this.$openMainLoading();
      var request = this.replyType == 6040 ? _construction.SubmitConRepairDelayDateTime : _order.SubmitOrderReplyOption;
      request(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: '提交成功',
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    },
    getReplyCategoryName: function getReplyCategoryName() {
      var _this2 = this;
      var label = "";
      this.replyTypeList.some(function (item) {
        if (item.value == _this2.replyType) {
          label = item.label;
          return true;
        }
      });
      return label;
    }
  }
};
exports.default = _default;