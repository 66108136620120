var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _c(
        "div",
        { staticClass: "main-title" },
        [
          _c("div", { staticClass: "title-name" }, [_vm._v("创建任务")]),
          _vm._v(" "),
          _c("NoticeMenu", {
            model: {
              value: _vm.activeTab,
              callback: function ($$v) {
                _vm.activeTab = $$v
              },
              expression: "activeTab",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", [
          _vm.activeTab == 1
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "addform",
                      attrs: {
                        rules: _vm.addformRules,
                        model: _vm.addform,
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择店铺", prop: "StoreID" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "underline",
                              attrs: { type: "text", target: "_blank" },
                              on: {
                                click: function ($event) {
                                  _vm.isShowStore = true
                                },
                              },
                            },
                            [_vm._v("选择店铺")]
                          ),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "已选择" + _vm._s(_vm.addform.StoreID.length)
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择日期", prop: "Start" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              type: "daterange",
                              "value-format": "yyyy-MM-dd",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            on: { change: _vm.handleChangeDaterange },
                            model: {
                              value: _vm.StartAndEnd,
                              callback: function ($$v) {
                                _vm.StartAndEnd = $$v
                              },
                              expression: "StartAndEnd",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "通知描述", prop: "Content" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.addform.Content,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "Content", $$v)
                              },
                              expression: "addform.Content",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "el-form-item" }, [
                        _c(
                          "label",
                          {
                            staticClass: "el-form-item__label",
                            staticStyle: { float: "none" },
                          },
                          [_vm._v("上传视频或图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("PhotoAndVideoUpload", {
                              model: {
                                value: _vm.addform.Pics,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "Pics", $$v)
                                },
                                expression: "addform.Pics",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "el-form-item" }, [
                        _c(
                          "label",
                          {
                            staticClass: "el-form-item__label",
                            staticStyle: { float: "none" },
                          },
                          [_vm._v("上传附件（WORD/EXCEL/PPT/PDF）")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "upload-demo",
                                attrs: {
                                  action: _vm.UpLoadFilesAPI,
                                  "on-remove": _vm.handleRemove,
                                  multiple: "",
                                  accept: ".xls,.xlsx,.doc,.docx,.ppt,.pdf",
                                  "on-success": _vm.handleFilesSuccess,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "primary" } },
                                  [_vm._v("点击上传")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab == 2
            ? _c(
                "div",
                { attrs: { label: "导入", name: "second" } },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          staticStyle: {
                            display: "inline-block",
                            "margin-right": "10px",
                          },
                          attrs: {
                            action: _vm.ImportNoticeAPI,
                            "show-file-list": false,
                            "on-success": _vm.handleUploadSuccess,
                            accept: ".xls,.xlsx",
                            "on-error": function () {
                              _vm.$closeMainLoading()
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("批量导入")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "underline",
                          attrs: { type: "text", target: "_blank" },
                          on: { click: _vm.handleDownloadTemplate },
                        },
                        [_vm._v("下载模板")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", "margin-bottom": "20px" },
                      attrs: { data: _vm.tableData },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "CNCode",
                          sortable: "",
                          label: "CNCode",
                          width: "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "STCode",
                          sortable: "",
                          label: "STCode",
                          width: "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "StoreName",
                          sortable: "",
                          label: "店铺名称",
                          width: "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "StartDate",
                          sortable: "",
                          formatter: _vm.formatterDate,
                          label: "开始日期",
                          width: "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "EndDate",
                          sortable: "",
                          formatter: _vm.formatterDate,
                          label: "结束日期",
                          width: "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "Content",
                          sortable: "",
                          label: "任务内容",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "margin-top": "40px" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.handleSubmit("addform")
                  },
                },
              },
              [_vm._v("提交")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.back(-1)
                  },
                },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("shopPopup", {
        attrs: { isopen: _vm.isShowStore },
        on: {
          "update:isopen": function ($event) {
            _vm.isShowStore = $event
          },
          change: _vm.handleChangeStore,
        },
        model: {
          value: _vm.addform.StoreID,
          callback: function ($$v) {
            _vm.$set(_vm.addform, "StoreID", $$v)
          },
          expression: "addform.StoreID",
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }