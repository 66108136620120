var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "createWorkOrder main-wrap",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "hide-required-asterisk": true,
            "label-width": "120px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-card",
            {
              staticClass: "box-card",
              attrs: { "body-style": { padding: "0" } },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "时间类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.onDateTypeChange },
                      model: {
                        value: _vm.dateType,
                        callback: function ($$v) {
                          _vm.dateType = $$v
                        },
                        expression: "dateType",
                      },
                    },
                    _vm._l(_vm.dateTypeList, function (type, index) {
                      return _c(
                        "el-radio-button",
                        {
                          key: type.lable + "_" + index,
                          attrs: { label: type.dateType },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(type.label) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "时间范围：" } }, [
                _c(
                  "div",
                  { staticClass: "date-wrap" },
                  [
                    _c("el-date-picker", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dateType == "month",
                          expression: "dateType == 'month'",
                        },
                      ],
                      attrs: {
                        type: "month",
                        placeholder: "请选择月份",
                        "value-format": "yyyy-MM",
                        clearable: false,
                        "picker-options": _vm.expireTimeOption,
                        disabled: _vm.dateType ? false : true,
                      },
                      on: { change: _vm.onDateRangeChange },
                      model: {
                        value: _vm.month,
                        callback: function ($$v) {
                          _vm.month = $$v
                        },
                        expression: "month",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-date-picker", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dateType == "halfYear",
                          expression: "dateType == 'halfYear'",
                        },
                      ],
                      attrs: {
                        type: "month",
                        placeholder: "请选择月份",
                        "value-format": "yyyy-MM",
                        clearable: false,
                        "picker-options": _vm.expireTimeOption,
                        disabled: _vm.dateType ? false : true,
                      },
                      on: { change: _vm.onDateRangeChange },
                      model: {
                        value: _vm.halfYear,
                        callback: function ($$v) {
                          _vm.halfYear = $$v
                        },
                        expression: "halfYear",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-date-picker", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: ["quarter", "year"].indexOf(_vm.dateType) > -1,
                          expression:
                            "['quarter', 'year'].indexOf(dateType) > -1",
                        },
                      ],
                      attrs: {
                        type: "year",
                        clearable: false,
                        placeholder: "请选择年度",
                        "value-format": "yyyy-MM",
                        "picker-options": _vm.expireTimeOption,
                        disabled: _vm.dateType ? false : true,
                      },
                      on: { change: _vm.onDateRangeChange },
                      model: {
                        value: _vm.year,
                        callback: function ($$v) {
                          _vm.year = $$v
                        },
                        expression: "year",
                      },
                    }),
                    _vm._v(" "),
                    _vm.dateType == "quarter"
                      ? _c(
                          "el-radio-group",
                          {
                            staticClass: "quarter",
                            on: { change: _vm.onQuarterChange },
                            model: {
                              value: _vm.quarter,
                              callback: function ($$v) {
                                _vm.quarter = $$v
                              },
                              expression: "quarter",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: 1 } }, [
                              _vm._v("第一季"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: 2 } }, [
                              _vm._v("第二季"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: 3 } }, [
                              _vm._v("第三季"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: 4 } }, [
                              _vm._v("第四季"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择物料类型：", prop: "CategoryCodeId" } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.handleChangeMaterialTypes },
                      model: {
                        value: _vm.form.CategoryCodeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "CategoryCodeId", $$v)
                        },
                        expression: "form.CategoryCodeId",
                      },
                    },
                    _vm._l(_vm.materialTypes, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.Categoryname, value: item.ID },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择店铺：", prop: "returnShopId" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "small" },
                          on: { click: _vm.choiceShop },
                        },
                        [_vm._v("选择店铺")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-left": "10px",
                            "font-size": "14px",
                            color: "#606266",
                            "font-weight": "700",
                          },
                        },
                        [
                          _vm._v(
                            "已选择\n            " + _vm._s(_vm.numberOfChoices)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              staticClass: "tabs",
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.onTabClick },
              model: {
                value: _vm.activeTabIndex,
                callback: function ($$v) {
                  _vm.activeTabIndex = $$v
                },
                expression: "activeTabIndex",
              },
            },
            _vm._l(_vm.form.perMonthData, function (monthData, tabIndex) {
              return _c(
                "el-tab-pane",
                {
                  key: monthData.name + "_" + tabIndex,
                  attrs: {
                    width: "",
                    name: monthData.name,
                    label: monthData.title,
                  },
                },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { staticClass: "el-icon-date" }),
                    _vm._v(" " + _vm._s(monthData.title) + " "),
                    tabIndex == _vm.errorTabIndex
                      ? _c("i", {
                          staticClass: "el-icon-warning",
                          staticStyle: { color: "red" },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.isShoppingBag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上传小票预估：",
                            prop:
                              "perMonthData." + tabIndex + ".smallTicketData",
                            rules: _vm.rules.smallTicketData,
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "upload",
                                  refInFor: true,
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action: _vm.EstimatedValueImport,
                                    "on-success": _vm.successs,
                                    "on-error": _vm.errors,
                                    "show-file-list": false,
                                    accept: ".xls,.xlsx",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "trigger",
                                        size: "small",
                                        icon: "el-icon-document",
                                        type: "info",
                                      },
                                      slot: "trigger",
                                    },
                                    [_vm._v("选取文件")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "downline",
                                      on: { click: _vm.templateDownload },
                                    },
                                    [_vm._v("点击下载模板")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v(_vm._s(monthData.smallTicketFileName)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "店铺提交时间：",
                        prop: "perMonthData." + tabIndex + ".submissionTime",
                        rules: _vm.rules.submissionTime,
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "default-time": ["00:00:00", "23:59:59"],
                          "value-format": "yyyy-MM-dd",
                        },
                        on: { change: _vm.onSubmitionDateChange },
                        model: {
                          value: monthData.submissionTime,
                          callback: function ($$v) {
                            _vm.$set(monthData, "submissionTime", $$v)
                          },
                          expression: "monthData.submissionTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "工单所属月：",
                        prop: "perMonthData." + tabIndex + ".OrderYearAndMonth",
                        rules: _vm.rules.orderYearAndMonth,
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "month",
                          placeholder: "工单所属月",
                          "value-format": "yyyy-MM",
                        },
                        model: {
                          value: monthData.OrderYearAndMonth,
                          callback: function ($$v) {
                            _vm.$set(monthData, "OrderYearAndMonth", $$v)
                          },
                          expression: "monthData.OrderYearAndMonth",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "供应商发货月：",
                        prop: "perMonthData." + tabIndex + ".OrderOperateMonth",
                        rules: _vm.rules.orderOperateMonth,
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "month",
                          placeholder: "供应商发货月",
                          "value-format": "yyyy-MM",
                        },
                        model: {
                          value: monthData.OrderOperateMonth,
                          callback: function ($$v) {
                            _vm.$set(monthData, "OrderOperateMonth", $$v)
                          },
                          expression: "monthData.OrderOperateMonth",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticStyle: { margin: "20px 0" } },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#606266",
                                "font-weight": "700",
                              },
                            },
                            [
                              _vm._v("已匹配店铺数量："),
                              _c(
                                "span",
                                { staticStyle: { color: "#303133" } },
                                [_vm._v(_vm._s(monthData.SuccessCount))]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#606266",
                                "font-weight": "700",
                              },
                            },
                            [
                              _vm._v("未匹配店铺数量："),
                              _c(
                                "span",
                                { staticStyle: { color: "#303133" } },
                                [_vm._v(_vm._s(monthData.FailCount))]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "forms" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: monthData.tableData,
                            "min-height": "320",
                            "max-height": "600",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "CNCode",
                              width: "160",
                              label: "CNCode",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "STCode",
                              width: "160",
                              label: "STCode",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ShopName",
                              width: "300",
                              label: "店铺名称",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ProductCategoryName",
                              width: _vm.isShoppingBag ? 200 : "auto",
                              label: "类型",
                            },
                          }),
                          _vm._v(" "),
                          _vm.isShoppingBag
                            ? _c("el-table-column", {
                                attrs: { label: "小票预估" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            staticStyle: { width: "120px" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.valueJudgment(
                                                  scope.row.PredictNum,
                                                  scope.$index
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.PredictNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "PredictNum",
                                                  _vm._n(
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                )
                                              },
                                              expression:
                                                "scope.row.PredictNum",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("el-card", { staticClass: "box-card" }, [
            _c(
              "div",
              { staticClass: "buts" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.workOrderSubmission },
                  },
                  [_vm._v("提交")]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.returns } }, [
                  _vm._v("返回"),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("shopPopup", {
        ref: "shopPopup",
        on: { change: _vm.handleChangeShop },
        model: {
          value: _vm.form.returnShopId,
          callback: function ($$v) {
            _vm.$set(_vm.form, "returnShopId", $$v)
          },
          expression: "form.returnShopId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-title" }, [
      _c("div", { staticClass: "title-name" }, [_vm._v("创建工单")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }