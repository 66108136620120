"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DownloadShopTemplate = void 0;
exports.GetStoreAreaList = GetStoreAreaList;
exports.GetStoreListGroup = GetStoreListGroup;
exports.GetStoreTypeList = GetStoreTypeList;
exports.UploadShop = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
// 物料类型
function GetStoreListGroup() {
  return (0, _request.default)({
    url: 'api/Shop/GetStoreListGroup',
    method: 'get'
  });
}

/**
 * @description:  获取店铺区域列表
 * @return {*}
 */
function GetStoreAreaList() {
  return (0, _request.default)({
    url: 'api/Shop/GetStoreAreaList',
    method: 'get'
  });
}

/**
 * @description:  获取店铺类型列表
 * @return {*}
 */
function GetStoreTypeList() {
  return (0, _request.default)({
    url: 'api/Shop/GetStoreTypeList',
    method: 'get'
  });
}
var DownloadShopTemplate = process.env.VUE_APP_BASE_API + '/api/Shop/Download?UserToken=' + (0, _auth.getToken)();
exports.DownloadShopTemplate = DownloadShopTemplate;
var UploadShop = process.env.VUE_APP_BASE_API + '/api/Shop/Upload?UserToken=' + (0, _auth.getToken)();
exports.UploadShop = UploadShop;