"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetProductList = GetProductList;
exports.GetTreeList = GetTreeList;
exports.ProductOrderImport = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request2"));
var _qs = _interopRequireDefault(require("qs"));
var _auth = require("@/utils/auth");
// 物料类型
function GetProductList(params) {
  return (0, _request2.default)({
    url: '/api/Product/GetProductList',
    method: 'get',
    params: params
  });
}
// 物料类型
function GetTreeList(params) {
  return (0, _request2.default)({
    url: '/api/Product/GetTreeList',
    method: 'get',
    params: params
  });
}
var ProductOrderImport = process.env.VUE_APP_BASE_API + '/api/Product/ProductOrderImport?UserToken=' + (0, _auth.getToken)();
exports.ProductOrderImport = ProductOrderImport;