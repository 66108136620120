"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangePassword = ChangePassword;
exports.GetMobileCode = GetMobileCode;
exports.GetUserInfoDetail = GetUserInfoDetail;
exports.RetrievePassword = RetrievePassword;
exports.SSOLogin = SSOLogin;
exports.UpdateUserTokenRole = UpdateUserTokenRole;
exports.getIDCode = getIDCode;
exports.getLoginUserInfo = getLoginUserInfo;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request2"));
var _qs = _interopRequireDefault(require("qs"));
function logout() {
  return (0, _request.default)({
    url: '/api/User/Logout',
    method: 'get'
  });
}
function login(data) {
  return (0, _request.default)({
    url: '/api/User/Login',
    method: 'post',
    data: data
  });
}

// /api/User/GetIDCode
function getIDCode(params) {
  return (0, _request.default)({
    url: '/api/User/GetIDCode',
    method: 'get',
    params: params
  });
}
function SSOLogin(data) {
  return (0, _request.default)({
    url: '/api/User/SSOLogin',
    method: 'post',
    data: data
  });
}
function getLoginUserInfo(params) {
  return (0, _request.default)({
    url: '/api/User/GetUserInfo',
    method: 'post',
    params: params
  });
}

// /api/User/GetUserInfoDetail
function GetUserInfoDetail(params) {
  return (0, _request.default)({
    url: '/api/User/GetUserInfoDetail',
    method: 'post',
    params: params
  });
}

// /api/User/ChangePassword
function ChangePassword(data) {
  return (0, _request.default)({
    url: '/api/User/ChangePassword',
    method: 'post',
    data: data
  });
}
function RetrievePassword(data) {
  return (0, _request.default)({
    url: '/api/User/RetrievePassword',
    method: 'post',
    data: data
  });
}
function UpdateUserTokenRole(data) {
  return (0, _request.default)({
    url: '/api/User/UpdateUserTokenRole',
    method: 'post',
    data: data
  });
}
function GetMobileCode(data) {
  return (0, _request.default)({
    url: '/api/User/GetMobileCode',
    method: 'post',
    data: data
  });
}