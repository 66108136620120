"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var SCMRouter = [{
  path: '/scm',
  name: 'SCM',
  component: _layout.default,
  meta: {
    title: 'SCM 工单管理',
    icon: 'order',
    roles: ['adidasrole019']
  },
  redirect: '/scm/order',
  children: [
  // {
  //   path: 'index',
  //   component: () => import('@/views/SCM/index'),
  //   name: 'SCMDashboard',
  //   meta: {
  //     title: 'SCM Dashboard',
  //     roles:['adidasrole019']
  //   }
  // },
  {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/SCM/order/index'));
      });
    },
    name: 'SCMOrder',
    redirect: '/scm/order/index',
    meta: {
      title: '大仓',
      roles: ['adidasrole019']
    },
    children: [{
      hidden: true,
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/SCM/order/list'));
        });
      },
      name: 'SCMOrderList',
      meta: {
        title: '工单列表',
        activeMenu: '/scm/order',
        roles: ['adidasrole019']
      }
    }, {
      hidden: true,
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/SCM/order/add'));
        });
      },
      name: 'SCMOrderAdd',
      meta: {
        title: '创建工单',
        activeMenu: '/scm/order',
        roles: ['adidasrole019']
      }
    }, {
      hidden: true,
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/SCM/order/detail'));
        });
      },
      name: 'SCMOrderDetail',
      meta: {
        title: '查看工单',
        activeMenu: '/scm/order',
        roles: ['adidasrole019']
      }
    }]
  }, {
    path: 'notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/Notice/index'));
      });
    },
    name: 'SCMNotice',
    meta: {
      title: '任务下发',
      roles: ['adidasrole019']
    },
    redirect: '/scm/notice/index',
    children: [{
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/list'));
        });
      },
      hidden: true,
      name: 'SCMNoticelist',
      meta: {
        title: '任务下发',
        parentPath: '/scm/notice',
        activeMenu: '/scm/notice',
        departmentID: 6,
        roles: ['adidasrole019']
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/add'));
        });
      },
      hidden: true,
      name: 'SCMNoticeAdd',
      meta: {
        title: '创建任务',
        parentPath: '/scm/notice',
        activeMenu: '/scm/notice',
        departmentID: 6,
        roles: ['adidasrole019']
      }
    }, {
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/detail'));
        });
      },
      hidden: true,
      meta: {
        title: '通知详情',
        parentPath: '/scm/notice',
        activeMenu: '/scm/notice',
        departmentID: 6,
        roles: ['adidasrole019']
      }
    }]
  }]
}];
var _default = SCMRouter;
exports.default = _default;