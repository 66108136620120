"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _order = require("@/api/order");
var _orderFormConfig = require("@/views/components/OrderForm/orderFormConfig");
var _PhotoAndVideoUpload = _interopRequireDefault(require("@/views/components/PhotoAndVideoUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "PPRepairPPManagerExtraApprove",
  data: function data() {
    return {
      replyTypeList: [{
        label: '同意供应商额外费用',
        value: 6050
      }, {
        label: '拒绝供应商额外费用',
        value: 6051
      }],
      replyType: 6050,
      replyContent: '',
      returnCauseList: _orderFormConfig.BackAndRejectCause.PP_check,
      returnCauseValue: "",
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: {
        Pics: [],
        Atts: []
      }
    };
  },
  components: {
    PhotoAndVideoUpload: _PhotoAndVideoUpload.default
  },
  inject: ['$openMainLoading', '$closeMainLoading', 'updateDetailsPage', 'orderBasicData'],
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      if (this.replyType == 6051 && !this.returnCauseValue) {
        //退单
        this.$message.warning('请选择拒绝原因');
        return false;
      }
      if (!this.replyContent) {
        this.$message.warning('请输入描述信息');
        return false;
      }
      var postData = {
        NextOrderStatus: this.replyType,
        reply: {
          ID: this.orderBasicData.OldReplyID,
          OrderID: this.orderBasicData.OrderID,
          OrderCode: this.orderBasicData.OrderCode,
          ReplyReasonName: this.replyType == 5001 ? this.returnCauseValue : "",
          ReplyContent: this.replyContent,
          ReplyCategoryName: this.getReplyCategoryName()
        },
        attachmentList: [].concat((0, _toConsumableArray2.default)(this.fileList.Pics), (0, _toConsumableArray2.default)(this.fileList.Atts))
      };
      // if(this.replyType == 6050){
      //   let msg = `同意追加费用,费用为${this.orderBasicData.ExtraCharge}；`
      //   postData.reply.ReplyContent = msg+this.replyContent;
      // }
      // if(this.replyType == 6051){
      //   let msg = `拒绝追加费用,费用为${this.orderBasicData.ExtraCharge}；`
      //   postData.reply.ReplyContent = msg+this.replyContent;

      // }
      this.$openMainLoading();
      (0, _order.SubmitOrderReplyOption)(postData).then(function (res) {
        _this.$closeMainLoading();
        _this.$message({
          message: '提交成功',
          type: "success"
        });
        _this.updateDetailsPage();
      }).catch(function (err) {
        _this.$closeMainLoading();
      });
    },
    getReplyCategoryName: function getReplyCategoryName() {
      var _this2 = this;
      var label = "";
      this.replyTypeList.some(function (item) {
        if (item.value == _this2.replyType) {
          label = item.label;
          return true;
        }
      });
      return label;
    }
  }
};
exports.default = _default;