var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c(
      "div",
      { staticClass: "main-title" },
      [
        _c("div", { staticClass: "title-name" }, [_vm._v("查看工单")]),
        _vm._v(" "),
        _c("OrderDetailMenu", {
          on: { change: _vm.changeMenu },
          model: {
            value: _vm.menuId,
            callback: function ($$v) {
              _vm.menuId = $$v
            },
            expression: "menuId",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main-content" },
      [
        this.menuId == 1
          ? [
              _vm.orderInfoType == 1
                ? _c("OrderInfo", {
                    attrs: {
                      orderId: _vm.orderId,
                      detailInfo: _vm.detailInfo,
                      showclose: _vm.showCloseBtn,
                      showreturn: _vm.showReturnBtn,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.orderInfoType == 2
                ? _c("RXOrderInfo", {
                    attrs: { orderId: _vm.orderId, detailInfo: _vm.detailInfo },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.orderBasicData.ShowTempTypeSign
                ? _c("OrderForm", {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { orderBasicData: _vm.orderBasicData },
                  })
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        this.menuId == 2
          ? _c("OrderProcess", { attrs: { orderId: _vm.orderId } })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }