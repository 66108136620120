var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-menu" },
    _vm._l(_vm.menuList, function (item) {
      return _c(
        "div",
        {
          key: item.value,
          class: { "menu-item": true, active: _vm.value == item.value },
          on: {
            click: function ($event) {
              return _vm.handleSwitchMenu(item.value)
            },
          },
        },
        [_vm._v(_vm._s(item.label))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }