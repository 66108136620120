"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
var _default = {
  name: 'TimeState',
  props: {
    time: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      showTime: '',
      showTimeState: null,
      stateList: [{
        type: 0,
        min: -(100 * 12 * 30 * 24 * 60 * 60),
        max: 0
      }, {
        type: 1,
        min: 0,
        max: 4 * 60 * 60
      }, {
        type: 2,
        min: 4 * 60 * 60,
        max: 24 * 60 * 60
      }, {
        type: 3,
        min: 24 * 60 * 60,
        max: 100 * 12 * 30 * 24 * 60 * 60
      }]
    };
  },
  methods: {
    getState: function getState() {
      var _this = this;
      if (this.show) {
        this.stateList.some(function (item) {
          var min = item.min,
            max = item.max;
          if (_this.time > min && _this.time <= max) {
            _this.showTimeState = item.type;
            return true;
          }
        });
      } else {
        this.showTimeState = 0;
      }
    },
    formattingTime: function formattingTime() {
      var str = '';
      var days = parseInt(this.time / 60 / 60 / 24, 10); //计算剩余的天数 
      var hours = parseInt(this.time / 60 / 60 % 24, 10); //计算剩余的小时 
      var minutes = parseInt(this.time / 60 % 60, 10); //计算剩余的分钟 
      var seconds = parseInt(this.time % 60, 10); //计算剩余的秒数 
      if (this.time <= 0) {
        str = '已超时';
      } else if (this.time > 0 && this.time < 60 * 60) {
        str = minutes + '分钟';
        //str =  parseInt(this.time/(60)) + '分钟'
      } else if (this.time >= 60 * 60 && this.time < 24 * 60 * 60) {
        //str =  hours+'小时'
        str = hours + '小时' + (minutes ? minutes + '分钟' : '');
        //str =  parseInt(this.time/(60*60)) + '小时'
      } else if (this.time >= 24 * 60 * 60) {
        str = days + '天' + (hours ? hours + '小时' : '');
        //str = days+'天'+hours+'小时'+minutes+'分钟'
        //str =  parseInt(this.time/(24*60*60)) + '天'
      }

      this.showTime = str;
    }
  },
  created: function created() {
    this.formattingTime();
    this.getState();
  },
  watch: {
    time: function time() {
      this.formattingTime();
      this.getState();
    }
  }
};
exports.default = _default;