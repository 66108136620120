var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "replyFormWrap" }, [
    _c(
      "div",
      { staticClass: "formMain" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 40 } },
          [
            _c(
              "el-col",
              { attrs: { span: 8 } },
              [
                _c("el-input", {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "text", placeholder: "请输入PO单号" },
                  model: {
                    value: _vm.POcode,
                    callback: function ($$v) {
                      _vm.POcode = $$v
                    },
                    expression: "POcode",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "formFooter" },
          [
            _c(
              "el-button",
              {
                staticClass: "main-btn",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.handleSubmit()
                  },
                },
              },
              [_vm._v("提交")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }