"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var CONSupplyRouter = [{
  path: '/consupply',
  name: 'CONSupply',
  component: _layout.default,
  meta: {
    title: '维修供应商',
    icon: 'order',
    roles: ['adidasrole013']
  },
  redirect: '/consupply/order',
  children: [{
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/CONSupply/order/index'));
      });
    },
    name: 'CONSupplyOrder',
    redirect: '/consupply/order/index',
    meta: {
      title: '维修订单',
      roles: ['adidasrole013']
    },
    children: [{
      hidden: true,
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/CONSupply/order/list'));
        });
      },
      name: 'CONSupplyOrderList',
      meta: {
        title: '工单列表',
        activeMenu: '/consupply/order',
        roles: ['adidasrole013']
      }
    }, {
      hidden: true,
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/CONSupply/order/detail'));
        });
      },
      name: 'CONSupplyOrderDetail',
      meta: {
        title: '查看工单',
        activeMenu: '/consupply/order',
        roles: ['adidasrole013']
      }
    }]
  }]
}];
var _default = CONSupplyRouter;
exports.default = _default;