"use strict";

var _interopRequireDefault = require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zy/Desktop/topwin/apsadmin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var RXRouter = [{
  path: '/rx',
  name: 'RX',
  component: _layout.default,
  meta: {
    title: 'RX 工单管理',
    icon: 'order',
    roles: ['adidasrole001']
  },
  redirect: '/rx/order',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/RX/index'));
      });
    },
    name: 'RXDashboard',
    meta: {
      title: 'RX Dashboard',
      roles: ['adidasrole001']
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/RX/Order/index'));
      });
    },
    name: 'Order',
    meta: {
      title: '批量采购',
      activeMenu: '/rx/order',
      roles: ['adidasrole001']
    },
    redirect: '/rx/order/index',
    children: [{
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/RX/Order/list'));
        });
      },
      name: 'RXOrderList',
      hidden: true,
      meta: {
        title: '批量采购列表',
        activeMenu: '/rx/order',
        roles: ['adidasrole001']
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/RX/Order/add'));
        });
      },
      name: 'RXOrderAdd',
      hidden: true,
      meta: {
        title: '创建工单',
        activeMenu: '/rx/order',
        roles: ['adidasrole001']
      }
    }, {
      path: ':id',
      hidden: true,
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/RX/Order/detail'));
        });
      },
      name: 'RXOrderDetail',
      meta: {
        title: '工单详情',
        activeMenu: '/rx/order',
        roles: ['adidasrole001']
      }
    }]
  }, {
    path: 'notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/Notice/index'));
      });
    },
    name: 'RXNotice',
    meta: {
      title: '任务下发',
      departmentID: 4,
      roles: ['adidasrole001']
    },
    redirect: '/rx/notice/index',
    children: [{
      path: 'index',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/list'));
        });
      },
      hidden: true,
      name: 'RXNoticelist',
      meta: {
        title: '任务下发',
        parentPath: '/rx/notice',
        activeMenu: '/rx/notice',
        departmentID: 4,
        roles: ['adidasrole001']
      }
    }, {
      path: 'add',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/add'));
        });
      },
      hidden: true,
      name: 'RXNoticeAdd',
      meta: {
        title: '创建任务',
        parentPath: '/rx/notice',
        activeMenu: '/rx/notice',
        departmentID: 4,
        roles: ['adidasrole001']
      }
    }, {
      path: ':id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/Notice/detail'));
        });
      },
      hidden: true,
      meta: {
        title: '通知详情',
        parentPath: '/rx/notice',
        activeMenu: '/rx/notice',
        departmentID: 4,
        roles: ['adidasrole001']
      }
    }]
  }]
}];
var _default = RXRouter;
exports.default = _default;